import React, { Component } from 'react'
import Header from '../../part/header';
import Footer from '../../part/footer';
import Menu from '../../part/sidebar';
import Mobile from '../../part/mobile';
import FormValidator from '../admin/FormValidator'
import { getList, getPaket, addToList, deleteProduct, updateProduct } from './ProductListFunctions'


// import { NavLink, useHistory, withRouter, Link } from 'react-router-dom';


class Product extends Component {

  constructor() {
    super()


    this.validator = new FormValidator([
        {
          field: 'nm_produk',
          method: 'isEmpty',
          validWhen: false,
          message: 'Title nama produck is required'
        },
        {
          field: 'harga',
          method: 'isEmpty',
          validWhen: false,
          message: 'kode harga is required'
        },
        {
          field: 'desc',
          method: 'isEmpty',
          validWhen: false,
          message: 'deskripsi is required'
        },
        {
          field: 'paket',
          method: 'isEmpty',
          validWhen: false,
          message: 'paket is required'
        }
      ]);

    this.state = {
      id: '',
      nm_produk: '',
      harga: '',
      desc: '',
      paket: '',
      img: null,
      cdn:'',
      createdAt:'',
      isUpdate : false,
      errorMessage: '',
      paketlists: [],
      products: [],
      loading: false,
      searchString: '',
      validation: this.validator.valid(),
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onCreate = this.onCreate.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
        this.setState({
            img: event.target.files[0],
          })
    }

  componentDidMount() {
    const token = localStorage.usertoken;
      this.getAll(token)
      this.getAlls(token)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onCreate(e) {
    e.preventDefault()
    document.getElementById("img").value = "";
    const token = localStorage.usertoken;
    this.setState({
      id: '',
      nm_produk: '',
      harga: '',
      desc: '',
      paket: '',
      cdn:'',
      img: null,
      createdAt:'',
      isUpdate : false,
      errorMessage:'',
      products: [],
      validation: this.validator.valid(),
    });
    this.getAll(token)
  }




  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear();
  }


  getAll = token => {
    getList(token).then(data => {
        if(data.message === 'Token expired !'){
            localStorage.removeItem('usertoken')
            this.props.history.push(`/login`)
        }else{
            this.setState(
                {
                    product: '',
                    products: [...data]
                },
                () => {
                    // console.log(this.state.items)
                }
            )
        }
    })
}

getAlls = token => {
    getPaket(token).then(data => {
        if(data.message === 'Token expired !'){
            localStorage.removeItem('usertoken')
            this.props.history.push(`/login`)
        }else{
            this.setState(
                {

                    paketlists:[...data]
                },
                () => {
                    // console.log(this.state.items)
                }
            )
        }
    })
  }
  onSubmit = e => {
    e.preventDefault()
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (validation.isValid) {
      this.setState({ loading: true})
        const token = localStorage.usertoken;
        const data = new FormData();
        data.append("file", this.state.img);
        data.append("upload_preset", "tokytoky");
        data.append("cloud_name", "syiham-group");
        fetch("https://api.cloudinary.com/v1_1/syiham-group/image/upload", {
          method: "post",
          body: data,
        })
          .then((res) => res.json())
          .then((data) => {
            const productRequest = {
              token: token,
              nm_produk: this.state.nm_produk,
              harga: this.state.harga,
              desc: this.state.desc,
              paket: this.state.paket,
              cdn: data.secure_url.toString(),
              img: this.state.img
          }
  
          addToList(productRequest).then(() => {
              this.getAll(token)
              this.setState({ loading: false})
          }).catch(err=>{
              this.setState({ editDisabled: false, errorMessage:err.message })
          })
          this.setState({ editDisabled: false })
          })
          .catch((err) => {
            console.log(err);
          });
        
    }
  }

  onUpdate = e => {
    e.preventDefault()
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (validation.isValid) {
        this.setState({ loading: true})
        const token = localStorage.usertoken;
        const data = new FormData();
        data.append("file", this.state.img);
        data.append("upload_preset", "tokytoky");
        data.append("cloud_name", "syiham-group");
        fetch("https://api.cloudinary.com/v1_1/syiham-group/image/upload", {
          method: "post",
          body: data,
        })
          .then((res) => res.json())
          .then((data) => {
            
            const productUpdateRequest = {
              id: this.state.id,
              token: token,
              nm_produk: this.state.nm_produk,
              harga: this.state.harga,
              desc: this.state.desc,
              paket: this.state.paket,
              cdn: data.secure_url.toString(),
              img: this.state.img
          }
          updateProduct(productUpdateRequest).then(() => {
            this.getAll(token)
            this.setState({ loading: false})
          }).catch(err=>{
              this.setState({ editDisabled: false, isUpdate:false, errorMessage:err.message })
          })
          })
          .catch((err) => {
            console.log(err);
            const productUpdateRequest = {
              id: this.state.id,
              token: token,
              nm_produk: this.state.nm_produk,
              harga: this.state.harga,
              desc: this.state.desc,
              paket: this.state.paket,
              cdn: '',
              img: this.state.img
          }
          updateProduct(productUpdateRequest).then(() => {
          this.getAll(token)
          this.setState({ loading: false})

          }).catch(err=>{
              this.setState({ editDisabled: false, isUpdate:false, errorMessage:err.message })
          })
          });
        
    }
    this.setState({ editDisabled: false, isUpdate:false,status: 0 })
  }

  onEdit = (item_id, nm_produk, harga, paket, desc, e) => {
    e.preventDefault()
    document.getElementById("img").value = "";
    this.setState({
      id: item_id,
      nm_produk: nm_produk,
      harga:harga,
      paket: paket,
      desc: desc,
      img: null,
      cdn: null,
      errorMessage: '',
      isUpdate:true,
      validation: this.validator.valid(),
    })
  }

  onDelete = (val, e) => {
    e.preventDefault()
    const token = localStorage.usertoken;
    deleteProduct(val, token).then((res) => {
        if(res.data.status === 'failed'){
            this.setState({ errorMessage:res.data.message })
        }
        this.getAll(token);
    }).catch(err=>{
        this.setState({ errorMessage:err.data.message })
    })
  }
 
  render() {
    const formatRupiah = (money) => {
         return new Intl.NumberFormat('id-ID',
           { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
         ).format(money);
      }

      const filterData = this.state.searchString ? this.state.products.filter(row => row.nm_produk.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1) : this.state.products;

      return (
<div>
<div id="load" class="loader loader-default is-active" style={this.state.loading ? {} : { display: 'none' }}></div>


<Header />
<div className="osahan-profile">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Manage Produk</h4>
    </div>
  </div>
  <div className="container position-relative">
    <div className="py-5 osahan-profile row">
      <div className="col-md-12 mb-3">
        <div className="osahan-cart-item-profile">
          <div className="box bg-white mb-3 shadow-sm rounded">
            <div className="p-3 d-flex align-items-center">
              <div className="ml-3">
                <h6 className="text-dark mb-2 mt-0">Daftar Produk</h6>
                <p className="mb-0 text-muted">berikut ini list daftar Produk / promo
                </p>
              </div>
            </div>

          <div className="overflow-hidden border-top p-3 d-flex align-items-center">
              <input
                  type="text"
                  style={{width:'200px'}}
                  className="form-control"
                  value={this.state.searchString}
                  name="searchString"
                  placeholder="Cari berdasarkan Produk"
                  onChange={this.onChange}
              />
              &nbsp;&nbsp;
              <button className="btn btn-success" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}>
                        Tambah
              </button>
          </div>
          <div className=" d-flex align-items-center p-3">
          <table className="table">
          <thead>
            <tr>
              <th>Produk</th>
              <th>Harga</th>
              <th>Paket</th>
              <th>Image</th>
              <th>Created Date</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((product, index) => (
              <tr key={index}>
                <td className="text-left">{product.nm_produk}</td>
                <td className="text-left">{formatRupiah(product.harga)}</td>
                <td className="text-left">{product.pakets[0]['name']}</td>
                <td className="text-left" style={{width: "200px"}}> <img alt="#" src={product.foto} width="50%" /> </td>
                <td className="text-left">{this.formatDate(new Date(product.createdAt))}</td>
                <td className="text-right">
                  <button
                    className="btn btn-info mr-1"
                    data-toggle="modal" data-target="#paycard"
                    disabled={this.state.editDisabled}
                    onClick={this.onEdit.bind(this, product.id, product.nm_produk, product.harga, product.paket, product.desc)}
                  >
                    Edit
                  </button>
                  <button
                    href=""
                    className="btn btn-danger"
                    onClick={this.onDelete.bind(this, product.id)}
                    style={this.state.isUpdate ? {display: 'none' } : {  }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Tambah Produk</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
       <form onSubmit={this.onSubmit}>
        <div className="form-group">
            <label htmlFor="title">Nama Produk</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.nm_produk}
                  name="nm_produk"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.nm_produk.message}</span>
              </div>
            </div>
            </div>
        <div className="form-group">
            <label htmlFor="kd_promo">Harga</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.harga}
                  name="harga"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.harga.message}</span>
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="status">Paket</label>
            <div className="row">
              <div className="col-md-12">
                <select className="form-control" name="paket" value={this.state.paket} onChange={this.onChange}>
                    <option value="">Pilih</option>

                    {this.state.paketlists.map((paketlist, index) => (

                    <option value={paketlist.id}>{paketlist.name}</option>
                    ))}
                </select>
                <span className="help-block">{this.state.validation.paket.message}</span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="kd_promo">Deskripsi</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.desc}
                  name="desc"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.desc.message}</span>
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="img">Image file</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="file"
                  className="form-control"
                  id="img"
                  name="img"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
        </div>

        <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close" onClick={this.onUpdate.bind(this)}
             style={this.state.isUpdate ? {} : { display: 'none' }}
        >
            Update
        </button>
        <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
            style={this.state.isUpdate ? {display: 'none' } : {  }}
        >
            Submit
        </button>
        <button onClick={this.onCreate.bind(this)} className="btn btn-info btn-block" data-dismiss="modal" aria-label="Close"
            style={this.state.isUpdate ? {} : { display: 'none' }}
        >
            Create New
        </button>
        </form>
      </div>

    </div>
  </div>
</div>


   <Mobile />
   <Footer />
  <Menu />
 </div>
    );
  }
}

  export default Product;
