import React from 'react';
import Header from '../part/header';
import Footer from '../part/footer';
import Menu from '../part/sidebar';
import Mobile from '../part/mobile';
import Slider from 'react-slick';
// import { NavLink } from 'react-router-dom';
import ReactImageAppear from 'react-image-appear';
import { getPaket, getBanner, getProduct } from './HomeListFunctions';
import CounterInput from 'react-bootstrap-personalized-counter';
import { isMobile } from "react-device-detect";


class Home extends React.Component
{


  constructor() {
    super()
    this.state = {
      nm_produk: '',
      desc: '',
      id: '',
      harga: '',
      alert : false,
      items: [],
      banners: [],
      products: []
    }

  }
componentDidMount() {
  this.getAllPaket()
  this.getAllBanner()
  this.getAllProduct()
  }
  getAllPaket  = async () => {
    getPaket().then(data => {
            this.setState(
                {
                    items: [...data]
                },
                () => {
                    // console.log(this.state.items)
                }
            )

    })
  }
   getAllBanner  = async () => {
    getBanner().then(data => {
            this.setState(
                {
                    banners: [...data]
                },
                () => {
                    // console.log(this.state.banners)
                }
            )

    })
  }
  onCartPlus(id, values) {

      let data = JSON.parse(localStorage.getItem('recent'));
      let perbaharuitotal = [];
      data = data.map((value) => {
           // check if this is the value to be edited
           if (value.productId === id ) {
             perbaharuitotal.push(value.harga*values);
                // return the updated value
                return {
                     ...value,

                     qty: values
                }
           } else {
              perbaharuitotal.push(value.harga*value.qty);

           }
           // otherwise return the original value without editing
           return value;
      })
      let totals = perbaharuitotal.reduce((val, nilaiSekarang)=>{
        return val + nilaiSekarang
      },0)
       this.setState(
                {
                    total_product: totals,
                    total_pembayaram: totals + parseInt(this.state.delivery_fee) - parseInt(this.state.apply_promo)

                }

            )

      localStorage.setItem('recent', JSON.stringify(data));
      let dat = JSON.parse(localStorage.getItem('count'));
      if (dat === 0) {
        this.setState({
        isUpdate:false
      })
    }
      this.forceUpdate();


  }

  onDelete(id, e)
{
  let recent = [];
  if(localStorage.getItem('recent')){
      recent = JSON.parse(localStorage.getItem('recent'));
  }
    var index = recent.map(x => {
      return x.productId;
    }).indexOf(id);

    recent.splice(index, 1);
    localStorage.setItem('recent', JSON.stringify(recent));
    let count = localStorage.getItem('count');
  if (count == null)
  {
    count = 0;
  }
  var add = parseInt(count)-1
  localStorage.setItem('count', add);
    this.forceUpdate();
    document.getElementById(id).style.display = 'block';
    document.getElementById(id+'add').style.display = 'none';
    document.getElementById(id+'delete').style.display = 'none';
    let dat = JSON.parse(localStorage.getItem('count'));
    if (dat === 0) {
      this.setState({
      isUpdate:false
    })
  }
}
  onCart(id, nm_produk, desc, harga, e)
{
  document.getElementById(id).style.display = 'none';
  document.getElementById(id+'add').style.display = 'block';
  document.getElementById(id+'delete').style.display = 'block';

this.setState({alert:true})
setTimeout(() => {
      this.setState({
        alert: false
      });
    }, 5000);

   let recent = [];
    if(localStorage.getItem('recent')){
        recent = JSON.parse(localStorage.getItem('recent'));
    }

  const found = recent.some(el => el.productId === id);
    if (!found) {
      recent.push({ 'productId': id, 'nama': nm_produk, 'desc': desc, 'harga': harga, 'qty': 1 });
      localStorage.setItem('recent', JSON.stringify(recent));
      let count = localStorage.getItem('count');
    if (count == null)
    {
      count = 0;
    }
    var add = parseInt(count)+1
    localStorage.setItem('count', add);
      this.forceUpdate();

    }
  }



  getAllProduct  = async () => {
    getProduct().then(data => {
            this.setState(
                {
                    products: [...data]
                },
                () => {
                    // console.log(this.state.products)
                }
            )

    })
  }

    render()
    {
    const formatRupiah = (money) => {
         return new Intl.NumberFormat('id-ID',
           { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
         ).format(money);
      }
       const settings = {
       centerMode: false,
        centerPadding: 'none',
        autoplay: true,
        speed: 3000,
        slidesToShow: 8,
        arrows: true,
        lazyLoad: true,
        pauseOnHover: true,
        swipeToSlide: true,
        responsive: [{
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '5px',
                    slidesToShow: 3
                }
            }
        ]
      };

      const settingbanner = {

        slidesToShow: 1,
        arrows: true,
        autoplay: true,
        speed: 3000,
        lazyLoad: true,
        pauseOnHover: true,
        swipeToSlide: true,
        responsive: [{
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '10px',
            slidesToShow: 1
          }
        }
        ]

      };

      
        var device
      if (isMobile) {
         device = 'filtermobile'
      } else {
         device = 'filter'
      }

        return (
            <div>
             <Header />
<div>
  <div className="osahan-home-page">
   <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center text-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <img alt="#" className="img-fluid" src="assets/img/white.png" style={{width:"40px"}} />
      <img alt="#" className="img-fluid" src="assets/img/rasa-white.png" style={{width:"35px"}} />
      </div>
      <div className="col-md-3"></div>
    </div>
  </div>
    <div className="container">
    <div className="cat-slider">

     <Slider {...settings}>
          {this.state.items.map((item, index) => (
            <div className="cat-item py-1">
              <a className="bg-white rounded d-block p-2 text-center shadow-sm" href= {device+'?id='+item.id+'&menu='+item.name}>
                  <p className="m-0 small"><img alt="#" src={item.img} className="img-fluid mb-1" /> {item.name}</p>
              </a>
            </div>
          ))}

      </Slider>
    </div>
     </div>
                {/* <div className="bg-white"> */}
    <div >
      <div className="container">
         <Slider {...settingbanner}>
          {this.state.banners.map((banner, index) => (
          <div className="cat-item">
            <a className="d-block text-center shadow-sm" href="/#">
                <ReactImageAppear
                src={banner.img}
                className="img-fluid rounded"
                showLoader={false}
                placeholder/>
            </a>
          </div>
          ))}
        </Slider>
      </div>
    </div>
    <div className="container">

    <div style={this.state.alert ? { position: 'sticky',top: '0',left: '0',width: '100%',zIndex: '9'} : { display: 'none' }}   className="alert alert-success" id="success-alert">
      <button type="button" className="close" data-dismiss="alert" style={{fontSize:'12px'}}>x</button>
      <strong>Success! </strong> Produk berhasil di tambahkan.
    </div>

      <div className="pt-2 pb-3 title d-flex align-items-center">
        <h5 className="m-0">Menu Terbaik</h5>
        <a href={device} className="font-weight-bold ml-auto" >Lihat selengkapnya <i className="feather-chevrons-right" /></a>
      </div>
      <div className="most_sale">
        <div className="row mb-3">
          {this.state.products.map((product, index) => (
          <div className="col-md-4 mb-3">
            <div className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
              <div className="list-card-image">
                <a href="/#" onClick={this.onCart.bind(this, product.id, product.nm_produk, product.desc, product.harga)}>
                  <ReactImageAppear
                  src={product.foto}
                  className="img-fluid item-img w-100"
                  showLoader={false}
                  placeholder/>
                </a>
              </div>
              <div className="p-3 position-relative">
                <div className="list-card-body">
                  <h6 className="mb-1"><a href="/#" id="myWish" onClick={this.onCart.bind(this, product.id, product.nm_produk, product.desc, product.harga)} className="text-black">{product.nm_produk}
                    </a>
                  </h6>
                  <p className="text-gray mb-2">{product.desc}</p>
                </div>
                <div className="list-card-badge mb-2">
                    <h6>{formatRupiah(product.harga)}</h6>
                </div>

                <div className="list-card-badge row" style={{width:'200px'}}>
                    <div className="badge badge-success" id={product.id} style={{display: "block"}}><span className="badge badge-success"><a href="#0" onClick={this.onCart.bind(this, product.id, product.nm_produk, product.desc, product.harga)}><i className="feather-shopping-cart" /> Tambah Pesanan</a></span></div>
                    <div id={product.id + 'add'} className="col-md-6" style={{display: "none", width: "50%"}}>
                    <CounterInput className="count-number-input" readolny="readonly"  value={1} min={1} max={50} glyphPlus={{ glyph: 'fa fa-plus', position: 'left', 'aria-hidden':'true'}} glyphMinus={{ glyph: 'fa fa-minus', position: 'right' }}  onChange={(value) => { this.onCartPlus(product.id,  value) }}  />
                    </div>
                    <div className="col-md-2"  id={product.id + 'delete'}  style={{display: "none", width: "10%"}}>
                    <i className="feather-trash-2 h5 mb-0" onClick={this.onDelete.bind(this, product.id)} />
                    </div>
                </div>
              </div>
            </div>
          </div>
            ))}
        </div>
      </div>
    </div>
  </div>
   <Mobile />
   <Footer />
  <Menu />

</div>




            </div>
        );
    }
}
export default Home;
