import React from 'react';
import Header from '../part/header';
import Footer from '../part/footer';
import Menu from '../part/sidebar';
import Mobile from '../part/mobile';

class Terms extends React.Component
{

    render()
    {
        return (
            <div>
             <Header />
<div>
<div className="osahan-profile">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Terms of use</h4>
    </div>
  </div>



    <div className="container position-relative">
           <div className="pt-4 pb-5 title d-flex align-items-center">

      <div className="col-md-12 mb-3">
  <div className="rounded shadow-sm">
    <div className="osahan-privacy bg-white rounded shadow-sm p-4">
      <div id="intro" className="mb-4">
        <div className="mb-4">
          <center><h2 className="h5">Terms of use</h2></center>
        </div>
        <p><strong> BACALAH SELURUH KETENTUAN PENGGUNAAN INI SEBELUM MENGAKSES ATAU MENGGUNAKAN APLIKASI Tokytoky.</strong></p>

        <p>Ketentuan Penggunaan ini adalah perjanjian antara pengguna ("Anda") dan PT Surga Kuliner Nusantara ("Kami"), sebuah perseroan terbatas yang didirikan dan beroperasi secara sah berdasarkan hukum negara Republik Indonesia dan berdomisili di Kota Palu, Indonesia. Ketentuan Penggunaan ini mengatur akses dan penggunaan Anda atas aplikasi, situs web (www.tokytoky.id) dan situs web lain yang Kami kelola), konten dan produk yang disediakan oleh Kami (selanjutnya, secara bersama-sama disebut sebagai "Aplikasi"), serta pemesanan, pembayaran atau penggunaan layanan yang tersedia pada Aplikasi ("Layanan").</p>

        <p>Dengan menyetujui Ketentuan Penggunaan ini, Anda juga menyetujui Ketentuan Penggunaan tambahan, termasuk Ketentuan Penggunaan pada setiap Layanan, dan perubahannya yang merupakan bagian yang tidak terpisahkan dari Ketentuan Penggunaan ini (selanjutnya, Ketentuan Penggunaan, Ketentuan Penggunaan tambahan, dan perubahannya secara bersama-sama disebut sebagai “Ketentuan Penggunaan”). Meskipun merupakan satu kesatuan, Ketentuan Penggunaan tambahan akan berlaku dalam hal terdapat perbedaan dengan Ketentuan Penggunaan.</p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Penggunaan Aplikasi dan Layanan.</h6>
        </div>
        <p>
        Kami memiliki pedoman khusus dalam hal pembelian, pembayaran, dan pengiriman untuk memastikan jaminan layanan dan kualitas dari setiap produk kami.
        </p>

        <p>
            Metode Pembayaran yang kami gunakan saat ini adalah :
        </p>
        <ul className="text-secondary">
          <li className="pb-2">Cash, Anda dapat melakukan pembayaran dengan uang cash saat Anda menerima pesanan Anda di tempat.</li>
          <li className="pb-2">Online Payment, Pembayaran langsung dengan layanan transfer otomatis ke rekening kami di website/aplikasi kami.</li>
          <li className="pb-2">Jam Operasional : Ketetapan jam operasional Setiap Hari, mulai dari pukul 10:00  sampai dengan 22:00 WITA untuk seluruh outlet kami.</li>
          <li className="pb-2">Minimum order online via delivery adalah Rp.50.000,-.</li>
          <li className="pb-2">Setiap transaksi baik online maupun offline akan dikenakan pajak 10% dari setiap transaksi.</li>
          <li className="pb-2">Ketika melakukan pemesanan online dan pembayaran telah dilakukan / sukses, maka Anda tidak berhak untuk membatalkan pesanan Anda.</li>
          <li className="pb-2">Kami menggunakan layanan jasa pihak ke tiga untuk melakukan pengantaran pesanan Anda setelah selesai di proses.</li>
        </ul>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Informasi Pribadi.</h6>
        </div>
        <p>
          Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda, seperti nama, alamat surat elektronik, dan nomor telepon genggam Anda yang Anda berikan ketika Anda membuka Akun tunduk pada kebijakan privasi, yang merupakan bagian yang tidak terpisahkan dari Ketentuan Penggunaan ini.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Konten, Informasi dan Promosi.</h6>
        </div>
        <p>
          Kami atau pihak lain yang bekerja sama dengan Kami dapat menyediakan Konten Pihak Ketiga yang dapat Anda temukan pada Aplikasi. Dalam hal Konten Pihak Ketiga disediakan oleh pihak lain yang bekerja sama dengan Kami (“Penyedia Konten Pihak Ketiga”), Kami tidak bertanggung jawab atas bagian apapun dari isi Konten Pihak Ketiga. Akses atau penggunaan Anda terhadap Konten Pihak Ketiga tersebut merupakan bentuk persetujuan Anda untuk tunduk pada syarat dan ketentuan yang ditetapkan oleh Kami atau Penyedia Konten Pihak Ketiga, termasuk terhadap Kebijakan Privasi Kami atau Penyedia Konten Pihak Ketiga.
        </p>

        <p>
          Konten Pihak Ketiga adalah setiap dan/atau seluruh informasi dan penawaran barang dan/atau jasa, yang dibuat dan/atau disusun dan/atau dikembangkan dan/atau dikelola oleh Penyedia Konten Pihak Ketiga termasuk namun tidak terbatas pada teks atau tulisan, gambar, quotes atau kutipan, foto, ilustrasi, animasi, video, rekaman suara atau musik, judul, deskripsi dan/atau setiap data dalam bentuk apapun yang disediakan oleh Penyedia Konten Pihak Ketiga untuk ditampilkan pada Aplikasi Gojek, termasuk setiap tautan yang menghubungkan kepadanya. Untuk menghindari keraguan, Konten Pihak Ketiga mencakup pula setiap dan/atau seluruh penawaran barang dan/atau jasa, informasi, data, berita aktual, tulisan, gambar, kutipan, foto, ilustrasi, animasi, video, rekaman suara, yang diperoleh Penyedia Konten Pihak Ketiga dari pihak ketiga, dimana Penyedia Konten Pihak Ketiga telah memiliki kewenangan untuk menggunakan dan mendistribusikan konten tersebut.
        </p>

        <p>
          Kami atau pihak lain yang bekerja sama dengan Kami dapat memberikan penawaran atau promosi (“Penawaran”) yang dapat ditukar dengan barang, Layanan atau manfaat lain terkait dengan penggunaan Aplikasi. Dalam hal Penawaran disediakan oleh pihak lain yang bekerja sama dengan Kami (“Penyedia Penawaran”), Kami tidak bertanggung jawab atas bagian apapun dari isi Penawaran tersebut. Akses atau penggunaan Anda terhadap Penawaran merupakan bentuk persetujuan Anda untuk tunduk pada syarat dan ketentuan yang ditetapkan oleh Kami atau Penyedia Penawaran, termasuk terhadap Kebijakan Privasi Kami atau Penyedia Penawaran.
        </p>

        <p>
          Semua informasi, Penawaran yang terdapat pada Aplikasi hanya dimaksudkan untuk memberikan Anda pengalaman terbaik ketika menggunakan Aplikasi atau Layanan. Anda tidak boleh menyalahgunakan Penawaran yang Anda terima selama penggunaan Aplikasi atau Layanan.
        </p>

        <p>
          Anda setuju untuk menggunakan Penawaran tersebut sesuai dengan syarat, ketentuan, dan maksud dari pemberian Penawaran dan tidak akan menyalahgunakan, menggandakan, menguangkan, mengalihkan, menggunakan untuk kepentingan komersial atau mengambil keuntungan dengan tidak wajar dari promosi tersebut dengan bentuk atau cara apapun.
        </p>

        <p>
          Anda memahami bahwa Penawaran tidak dapat ditukar dengan uang tunai, memiliki masa keberlakuan yang terbatas dan tunduk pada ketentuan yang berlaku untuk setiap Penawaran tersebut.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Perangkat Lunak Aplikasi.</h6>
        </div>
        <p>
          Untuk saat ini, Kami hanya memiliki satu situs (www.tokytoky.id) yang bisa diakses melalui web browser resmi di Handphone, Tablet maupun Perangkat lain Anda.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Kekayaan Intelektual.</h6>
        </div>
        <p>
          Aplikasi dan Layanan, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia yang terdaftar baik atas nama Kami ataupun afiliasi Kami. Kami (dan pemberi lisensi Kami) memiliki seluruh hak dan kepentingan atas Aplikasi dan Layanan, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang terdapat didalamnya dan hak kekayaan intelektual terkait.
        </p>

        <p>
          Tunduk pada Ketentuan Penggunaan ini, Kami memberikan Anda lisensi terbatas yang tidak eksklusif, dapat ditarik kembali, tidak dapat dialihkan (tanpa hak sublisensi) untuk (i) mengunduh, mengakses, dan menggunakan Aplikasi, sebagaimana adanya, hanya pada perangkat berupa telepon genggam dan/atau tablet pribadi Anda dan hanya untuk keperluan terkait penggunaan Layanan, dan (ii) mengakses atau menggunakan konten, informasi dan materi terkait yang tersedia pada Aplikasi hanya untuk kepentingan pribadi dan bukan tujuan komersial. Hak dan hak istimewa lainnya yang tidak secara tegas diberikan dalam Ketentuan Penggunaan ini, adalah hak Kami atau pemberi lisensi Kami.
        </p>

        <p>
          Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan atau penawaran untuk menjual, penampilan baik sebagian atau seluruhnya, serta penggunaan Aplikasi dan/atau Layanan, baik secara digital atau lainnya atau pada perangkat selain telepon genggam atau tablet, termasuk namun tidak terbatas pada penggunaan aplikasi tambahan seperti aplikasi modifikasi, emulator, dan lain-lain, yang menyimpang dari Ketentuan Penggunaan ini, maupun tujuan penggunaan yang ditentukan oleh Kami, merupakan pelanggaran terhadap hak kekayaan intelektual Kami.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Anda tidak boleh:</h6>
        </div>
        <p>
          menghapus setiap pemberitahuan hak cipta, merek dagang atau pemberitahuan hak milik lainnya yang terkandung dalam Aplikasi;
        </p>
        <p>
          menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya turunan dari, mendistribusikan, memberikan lisensi, menjual, mengalihkan, menampilkan di muka umum baik sebagian maupun seluruhnya, merekayasa balik (reverse engineer), mentransmisikan, memindahkan, menyiarkan, menguraikan, atau membongkar bagian manapun dari atau dengan cara lain mengeksploitasi Aplikasi (termasuk perangkat lunak, fitur dan Layanan di dalamnya);
        </p>
        <p>
        memberikan lisensi, mensublisensikan, menjual, menjual kembali, memindahkan, mengalihkan, mendistribusikan atau mengeksploitasi secara komersial atau membuat tersedia kepada pihak lain Aplikasi dan/atau perangkat lunak dengan cara menciptakan tautan (link) internet ke Aplikasi atau "frame" atau "mirror" setiap perangkat lunak pada server lain atau perangkat nirkabel atau yang berbasis internet;
        </p>
        <p>
          meluncurkan program otomatis atau script, termasuk, namun tidak terbatas pada, web spiders, web crawlers, web robots, web ants, web indexers, bots, virus atau worm, atau program apapun yang mungkin membuat beberapa permintaan server per detik, menciptakan beban berat atau menghambat operasi dan/atau kinerja Aplikasi;
        </p>
        <p>
        menggunakan aplikasi pencarian atau pengambilan kembali situs, perangkat manual atau otomatis lainnya untuk mengambil (scraping), indeks (indexing), survei (surveying), tambang data (data mining), atau dengan cara apapun memperbanyak atau menghindari struktur navigasi atau presentasi dari Aplikasi atau isinya;
        </p>
        <p>
        menerbitkan, mendistribusikan atau memperbanyak dengan cara apapun materi yang dilindungi hak cipta, merek dagang, atau informasi lain yang Kami miliki tanpa persetujuan tertulis terlebih dahulu dari Kami atau pemilik hak yang melisensikan hak-nya kepada Kami, dan menggunakan dan/atau mengakses secara tidak resmi Aplikasi untuk (a) merusak, melemahkan atau membahayakan setiap aspek dari Aplikasi, Layanan atau sistem dan jaringan terkait, dan/atau (b) membuat produk atau layanan tandingan serupa menggunakan ide, fitur, fungsi atau grafik menyerupai Aplikasi.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Tanggung Jawab Anda</h6>
        </div>
        <p>
          Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran. Anda harus memperlakukan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran dengan hormat dan tidak boleh terlibat dalam perilaku atau tindakan yang tidak sah, mengancam atau melecehkan ketika menggunakan Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran.
        </p>
        <p>
          Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran melalui Akun Anda, baik oleh Anda atau pihak lain yang menggunakan Akun Anda, dengan cara yang bertentangan dengan Ketentuan Penggunaan ini, kebijakan privasit ermasukasuk syarat dan ketentuan dan kebijakan privasi yang ditentukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran, atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishingdan/atau social engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi Kami.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Batasan Tanggung Jawab Kami</h6>
        </div>
        <p>
          Kami menyediakan Layanan sebagaimana adanya dan Kami tidak menyatakan atau menjamin bahwa keandalan, ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari Layanan kami dapat memenuhi kebutuhan dan akan sesuai dengan harapan Anda, termasuk namun tidak terbatas pada Layanan, Konten Pihak Ketiga, Penawaran dan Metode Pembayaran yang sepenuhnya menjadi tanggung jawab Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran. Kami tidak bertanggung jawab atas setiap kerugian atau kerusakan yang disebabkan oleh setiap kegagalan atau kesalahan yang dilakukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran ataupun kegagalan atau kesalahan Anda dalam mematuhi Ketentuan Penggunaan Kami, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.
        </p>
        <p>
          Aplikasi dapat mengalami keterbatasan, penundaan, dan masalah-masalah lain yang terdapat dalam penggunaan internet dan komunikasi elektronik, termasuk perangkat Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran rusak, tidak terhubung dengan internet, berada di luar jangkauan, dimatikan atau tidak berfungsi. Kami tidak bertanggung jawab atas keterlambatan, kegagalan pengiriman, kerusakan atau kerugian yang diakibatkan oleh masalah-masalah tersebut.
        </p>
        <p>
          Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas situs Kami. Namun, Kami tetap melakukan pengawasan untuk tujuan memastikan kelancaran penggunaan Aplikasi dan untuk memastikan kepatuhan terhadap Ketentuan Penggunaan ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif atau badan pemerintahan lainnya.
        </p>
        <p>
          Kami tidak mempunyai kewajiban apapun, termasuk untuk mengambil tindakan lebih jauh atau tindakan hukum yang dianggap perlu oleh Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, terhadap setiap permasalahan atau perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran. Tetapi, Kami akan memfasilitasi setiap permasalahan atau perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran dengan upaya wajar yang diperlukan. Ketika Kami memfasilitasi penyelesaian permasalahan atau perselisihan antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, Kami tidak bertindak sebagai mediator dan hal tersebut tidak menimbulkan kewajiban lebih jauh apapun terhadap Kami.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Penyelesaian Masalah:</h6>
        </div>
        <p>
        Apabila Anda mengalami gangguan sistem, mengetahui atau menduga bahwa Akun Anda diretas, digunakan atau disalahgunakan oleh pihak lain, atau apabila perangkat telepon genggam atau tablet pribadi Anda hilang, dicuri, diretas atau terkena virus, segera laporkan kepada Kami sehingga Kami dapat segera mengambil tindakan yang diperlukan untuk menghindari penggunaan, penyalahgunaan, atau kerugian yang timbul atau mungkin timbul lebih lanjut.
        </p>
        <p>
        Apabila Anda mengalami kendala atau masalah terkait Layanan, Konten Pihak Ketiga, Penawaran atau pembayaran melalui Metode Pembayaran, atau perlakuan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran Anda dapat menyampaikan keluhan Anda melalui fitur yang Kami sediakan, termasuk pemberian peringkat dan komentar, atau dengan menghubungi Kami.
        </p>
        <p>
        Untuk menyampaikan keluhan, pertanyaan, sanggahan, dan lain-lain (“Laporan”), Anda perlu memberikan informasi yang cukup, termasuk namun tidak terbatas pada, ringkasan fakta yang terjadi, bukti-bukti yang Anda miliki, nomor pesanan, dan informasi pribadi, seperti alamat surat elektronik dan nomor telepon genggam terdaftar.
        </p>
        <p>
        Untuk menanggapi setiap Laporan yang Anda sampaikan, Kami akan melakukan verifikasi terlebih dahulu dengan mencocokan informasi yang Anda berikan dan informasi pribadi Anda yang terdapat dalam sistem Kami. Jika diperlukan, Kami dapat secara langsung meminta Anda memberikan informasi yang diperlukan untuk tujuan verifikasi.
        </p>
        <p>
        Kami dapat menolak untuk menindaklanjuti Laporan Anda jika informasi yang Anda berikan tidak cocok dengan informasi pribadi yang terdapat dalam sistem Kami atau apabila Laporan disampaikan terkait, terhadap, atas nama atau oleh pihak lain yang berbeda dengan pemilik Akun yang bersangkutan yang terdaftar secara resmi pada sistem Kami. Kami dapat memberhentikan tindak lanjut terhadap Laporan Anda jika Kami, dengan kebijakan Kami sepenuhnya, menganggap bahwa Laporan Anda tidak didukung oleh fakta-fakta yang cukup dan jelas, atau telah selesai. Kami juga dapat meneruskan Laporan Anda kepada Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran untuk diselesaikan secara langsung oleh Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.
        </p>
        <p>
        Terhadap Laporan tertentu terkait Layanan, Kami dapat, dengan kebijakan Kami sepenuhnya, memfasilitasi pertemuan antara Anda dan Penyedia Layanan untuk tujuan penyelesaian masalah secara damai dan kekeluargaan. Dalam hal demikian, jika Anda merasa tetap perlu mengambil tindakan lain, termasuk tindakan hukum apapun, Anda dapat melakukannya atas tanggung jawab pribadi Anda sepenuhnya.
        </p>
        <p>
        Terhadap Laporan tertentu terkait Metode Pembayaran, Kami dapat mengembalikan dana Anda melalui cara yang ditentukan oleh Kami atau bersama-sama dengan Penyedia Metode Pembayaran, berdasarkan kebijakan Kami sepenuhnya. Kami tidak akan mengembalikan dana kepada pihak lain yang berbeda dengan kredensial terdaftar pada Akun Gojek atau Penyedia Metode Pembayaran, termasuk apabila Anda menggunakan kredensial pihak lain untuk melakukan pembayaran melalui Metode Pembayaran.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Tindakan yang Kami Anggap Perlu</h6>
        </div>
        <p>
        Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah melakukan tindakan asusila, pelanggaran, kejahatan atau tindakan lain yang bertentangan dengan Ketentuan Penggunaan ini dan/atau peraturan perundang-undangan yang berlaku, baik yang dirujuk dalam Ketentuan Penggunaan ini atau tidak, maka Kami berhak untuk dan dapat membekukan Akun, baik sementara atau permanen, atau menghentikan akses Anda terhadap Aplikasi, termasuk Layanan, Konten Pihak Ketiga, Penawaran dan/atau Metode Pembayaran yang terdapat di dalamnya, melakukan pemeriksaan, menuntut ganti kerugian, melaporkan kepada pihak berwenang dan/atau mengambil tindakan lain yang kami anggap perlu, termasuk tindakan hukum pidana maupun perdata.
        </p>
        <p>
        Kami akan menindaklanjuti dengan melakukan investigasi dan/atau memfasilitasi Penyedia Layanan yang bersangkutan untuk melaporkan kepada pihak yang berwajib apabila Kami menerima Laporan adanya pelanggaran yang Anda lakukan atas Ketentuan Penggunaan ini ataupun pelanggaran terhadap peraturan perundang-undangan yang berlaku, sehubungan dengan:
        </p>
        <p>
        Pelecehan atau kekerasan verbal, termasuk namun tidak terbatas pada, atas fisik, jenis kelamin, suku, agama dan ras, yang Anda lakukan terhadap Penyedia Layanan;
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Tindakan penipuan</h6>
        </div>
        <p>
          Pengaksesan layanan pada perangkat rooted atau jail-broken;
          Pelecehan atau kekerasan fisik yang Anda lakukan terhadap Penyedia Layanan; dan/atau
          Pelecehan atau kekerasan seksual, baik secara verbal maupun fisik, yang Anda lakukan terhadap Penyedia Layanan.
          Ketentuan ini juga berlaku sebaliknya apabila Anda mengalami tindakan sebagaimana disebutkan di atas yang dilakukan oleh Penyedia Layanan terhadap Anda.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Pernyataan Anda</h6>
        </div>
        <p>
        Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18 (delapan belas) tahun atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Ketentuan Penggunaan ini. Jika Anda tidak memenuhi ketentuan tersebut namun tetap mengakses atau menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran, Anda menyatakan dan menjamin bahwa tindakan Anda membuka, mengakses atau melakukan aktivitas lain dalam Aplikasi telah disetujui oleh orang tua, wali atau pengampu Anda. Anda secara tegas mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh persetujuan yang Anda berikan berdasarkan Ketentuan Penggunaan ini pada waktu Anda dianggap telah dewasa secara hukum.
        </p>
        <p>
        Anda setuju untuk mengakses atau menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran hanya untuk tujuan sebagaimana ditentukan dalam Ketentuan Penggunaan ini dan tidak menyalahgunakan atau menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran untuk tujuan penipuan, menyebabkan ketidaknyamanan kepada orang lain, melakukan pemesanan palsu atau yang tindakan-tindakan lain yang dapat atau dianggap dapat menimbulkan kerugian dalam bentuk apapun terhadap orang lain.
        </p>
        <p>
        Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari penggunaan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran dan Metode Pembayaran sepenuhnya menjadi tanggung jawab Anda dan Anda dengan ini setuju untuk melepaskan Kami dari segala tuntutan apapun sehubungan dengan kerusakan, gangguan, atau bentuk lain dari gangguan sistem yang disebabkan oleh akses tidak resmi oleh pihak lain.
        </p>
        <p>
        Anda secara tegas membebaskan Kami, termasuk namun tidak terbatas pada, pejabat, direktur, komisaris, karyawan dan agen Kami, dari dari setiap dan semua kewajiban, konsekuensi, kerugian baik materiil atau immateriil, tuntutan, biaya-biaya (termasuk biaya advokat) atau tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat pelanggaran Anda terhadap Ketentuan Penggunaan ini maupun sehubungan dengan tindakan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.
        </p>
        <p>
        Dikecualikan dari ketentuan di atas, jika terdapat kerugian langsung akibat pelanggaran Kami terhadap Ketentuan Penggunaan ini, maka Anda setuju dan secara tegas membatasi jumlah tuntutan Anda sebesar jumlah keseluruhan yang senyatanya ditimbulkan atau telah dibayarkan sehubungan dengan peristiwa tersebut.
        </p>

        <p>
        <strong>ANDA MENGAKUI BAHWA KAMI ADALAH PERUSAHAAN F&B, BUKAN PERUSAHAAN KURIR, KEUANGAN DAN LAIN-LAIN DAN KAMI TIDAK MEMBERIKAN LAYANAN KURIR, KEUANGAN DAN LAYANAN LAIN. SEMUA LAYANAN KURIRKEUANGAN DAN LAYANAN LAIN DISEDIAKAN PIHAK KETIGA INDEPENDEN YANG TIDAK DIPEKERJAKAN OLEH KAMI.
        <br/>DENGAN MELANJUTKAN AKSES ATAU PENGGUNAAN TERHADAP TOKYTOKY.ID DAN/ATAU LAYANAN, ANDA SETUJU UNTUK TUNDUK DAN MEMATUHI SEMUA KETENTUAN PERATURAN PERUNDANG-UNDANGAN TERKAIT DAN KETENTUAN PENGGUNAAN INI, TERMASUK SEMUA PERUBAHANNYA DAN KETENTUAN PENGGUNAAN DARI SETIAP PENYEDIA LAYANAN, PENYEDIA KONTEN PIHAK KETIGA, PENYEDIA PENAWARAN ATAU PENYEDIA METODE PEMBAYARAN. SEGERA HENTIKAN AKSES ATAU PENGGUNAAN APLIKASI TOKYTOKY.ID DAN/ATAU LAYANAN JIKA ANDA TIDAK SETUJU DENGAN BAGIAN APAPUN DARI KETENTUAN PENGGUNAAN INI.</strong>
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Keadaan Kahar</h6>
        </div>
        <p>
          Layanan dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol Kami (“Keadaan Kahar”), termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda setuju untuk membebaskan Kami dari setiap tuntutan dan tanggung jawab, jika Kami tidak dapat memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan melalui Aplikasi, baik sebagian maupun seluruhnya, karena suatu Keadaan Kahar.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Lain-lain</h6>
        </div>
        <p>
        Anda mengerti dan setuju bahwa Ketentuan Penggunaan ini merupakan perjanjian dalam bentuk elektronik dan merupakan persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Ketentuan Penggunaan ini dan Kebijakan Privasi adalah sah dan mengikat secara hukum dan terus berlaku sepanjang penggunaan Aplikasi dan Layanan oleh Anda.
        </p>
        <p>
        Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Ketentuan Penggunaan kebijakan privasi yang dibuat dalam bentuk elektronik.
        </p>
        <p>Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Ketentuan Penggunaan kebijakan privasi yang dibuat dalam bentuk elektronik.</p>
        <p>Anda tidak dapat mengalihkan hak Anda berdasarkan Ketentuan Penggunaan ini tanpa persetujuan tertulis sebelumnya dari Kami. Namun, Kami dapat mengalihkan hak Kami berdasarkan Ketentuan Penggunaan ini setiap saat kepada pihak lain tanpa perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan pemberitahuan sebelumya kepada Anda.</p>
        <p>Bila Anda tidak mematuhi atau melanggar ketentuan dalam Ketentuan Penggunaan ini, dan Kami tidak mengambil tindakan secara langsung, bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan yang diperlukan di kemudian hari.</p>
        <p>Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Aplikasi atau setelah berakhirnya perjanjian ini antara Anda dan Kami.</p>
        <p>Jika salah satu dari ketentuan dalam Ketentuan Penggunaan ini tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan lainnya.</p>
        <p>Kami membuat Ketentuan Penggunaan ini dalam bahasa Indonesia dan Inggris. Setiap perbedaan akan diartikan sesuai dengan bahasa Indonesia.</p>
        <p>Ketentuan Penggunaan ini diatur berdasarkan hukum Republik Indonesia. Setiap dan seluruh perselisihan yang timbul dari penggunaan Aplikasi atau Layanan tunduk pada yurisdiksi eksklusif Pengadilan Negeri Jakarta Selatan.</p>
        <p><strong>Saya telah membaca dan mengerti seluruh Ketentuan Penggunaan ini dan konsekuensinya dan dengan ini menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya.</strong></p>
      </div>
    </div>
  </div>
</div>
</div>




         </div>
  </div>
   <Mobile />
   <Footer />
  <Menu />
</div>




            </div>
        );
    }
}
export default Terms;
