import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom';
import { getOutlet } from '../../components/pages/HomeListFunctions'


class Header extends Component {
   constructor() {
    super()
    this.state = {
      location_header: 'Palu',
      new_location: []
    }

  }
   logOut(e) {
    e.preventDefault()
   localStorage.removeItem('usertoken')
    this.props.history.push(`/`)
  }
   componentDidMount() {
    this.getAllOutlet()
  }
   getAllOutlet  = async () => {
    getOutlet().then(data => {
            this.setState(
                {
                    new_location: [...data]
                },
                () => {
                }
            )

    })
  }
  onLocation = (location, e) => {
    e.preventDefault()
    this.setState({
      location_header: location,

    })
  }
  render() {
    const loginRegLink = (
      <div></div>
    );
    const userLink = (
      <a className="toggle" href="/#">
                    <span />
                  </a>
    )
     const cart = (
      <span></span>
    );

    const total = (

      <span className='badges badge-success' style={{"font-size":"10px"}} id='lblCartCounts'>{localStorage.getItem('count')}</span>
    )


    return (
      <header className="section-header">
        <section className="header-main shadow-sm bg-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-4">
                <a href="/" className="brand-wrap mb-0" >
                  <img alt="#" className="img-fluid" src="assets/img/logo1221.png" />
                  <img alt="#" className="img-fluid" src="assets/img/rasa.png" />
                </a>
              </div>


              <div className="col-8">
                <div className="d-flex align-items-center justify-content-end pr-5">
                  {/* <a href="search.html" className="widget-header mr-4 text-dark">
                    <div className="icon d-flex align-items-center">
                      <i className="feather-search h6 mr-2 mb-0" /> <span>Search</span>
                    </div>
                  </a> */}
                  <a href="/cart" className="widget-header mr-4 text-dark">
                      <i className="feather-shopping-cart h4 mb-0" />
                      {localStorage.recent ? total : cart}
                  </a>

                  <div className="col-3 d-flex align-items-center m-none">
                    <div className="dropdown mr-3">
                      <a className="text-dark dropdown-toggle d-flex align-items-center py-3" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div><i className="feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size" /></div>
                        <div>
                        <p className="text-muted mb-0 small">Pilih Lokasi Outlet</p>
                            {this.state.location_header}
                          </div>
                      </a>
                      <div className="dropdown-menu p-0 drop-loc" aria-labelledby="navbarDropdown">
                        <div className="osahan-country">
                          <div className="filter">
                            {this.state.new_location.map((outletlist, index) => (
                            <div className="custom-control  border-bottom px-0 ">
                              <label className="dropdown-item py-3 w-100 px-3" onClick={this.onLocation.bind(this, outletlist.nama)}>{outletlist.nama}</label>
                            </div>
                            ))}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                      {localStorage.usertoken ? userLink : loginRegLink}


                </div>
              </div>
            </div>
          </div>
        </section>
      </header>

    );
  }
}

  export default Header;
