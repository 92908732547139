import React from 'react';
import Header from '../part/header';
import Footer from '../part/footer';
import Menu from '../part/sidebar';
import Mobile from '../part/mobile';

class Privacy extends React.Component
{

    render()
    {
        return (
            <div>
             <Header />
<div>
<div className="osahan-profile">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Info</h4>
    </div>
  </div>



   <div className="container position-relative">
       <div className="pt-4 pb-5 title d-flex align-items-center">
      <div className="col-md-12 mb-3">
        <div className="bg-white profile-details">

        <label data-toggle="modal" data-target="#delivery" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
            <div className="left mr-3">
            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-truck bg-danger text-white p-2 rounded-circle mr-2" /> Delivery Support</h6>
            </div>
            <div className="right ml-auto">
            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
            </div>
        </label>
        <label data-toggle="modal" data-target="#contact" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
            <div className="left mr-3">
            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-phone bg-primary text-white p-2 rounded-circle mr-2" /> Contact</h6>
            </div>
            <div className="right ml-auto">
            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
            </div>
        </label>
        <label data-toggle="modal" data-target="#term" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
            <div className="left mr-3">
            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-info bg-success text-white p-2 rounded-circle mr-2" /> Term of use</h6>
            </div>
            <div className="right ml-auto">
            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
            </div>
        </label>
        <label data-toggle="modal" data-target="#privacy" className="d-flex w-100 align-items-center px-3 py-4">
            <div className="left mr-3">
            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-lock bg-warning text-white p-2 rounded-circle mr-2" /> Privacy policy</h6>
            </div>
            <div className="right ml-auto">
            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
            </div>
        </label>
        </div>
  </div>




         </div>
    </div>
  </div>
</div>

<div className="modal fade" id="delivery" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Delivery Support</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">

      <div id="services" className="mb-4">
          <p>
              Untuk saat ini, Pihak Kami menyediakan 2 layanan utama untuk memudahkan Anda :
          </p>

          <div id="information" className="mb-3 active">
          <h6 className="h6">1. Delivery.</h6>
          </div>
          <p>
            Untuk memaksimalkan efisiensi waktu serta kualitas produk, Kami menggunakan layanan PIHAK KETIGA ("Pihak penyedia pengantaran") untuk melakukan pengantaran sebagaimana yang terjabarkan melalui ketentuan penggunaan layanan kami. Setiap biaya dalam pengantaran disesuaikan dengan titik lokasi pengantaran lokasi anda dari outlet terdekat, Jumlah, jarak yang di atur oleh pihak ketiga. Untuk menggunakan layanan ini, Anda diwajibkan untuk menyelesaikan pembayaran terlebih dahulu sesuai dengan jumlah pemesanan di laman Tokytoky.id
          </p>

          <div id="information" className="mb-3 active">
          <h6 className="h6">2. Pickup.</h6>
          </div>
          <p>
            Dengan menggunakan Layanan ini, kami dapat membantu Anda untuk bebas antri. Pihak Kami akan menyiapkan seluruh pesanan Anda sesuai dengan waktu yang Anda tentukan tanpa biaya tambahan. Selanjutnya, untuk pembayaran dan Pengambilan produk bisa dilakukan di outlet kami yang telah Anda pilih sebelumnya.
          </p>

      </div>

      </div>

    </div>
  </div>
</div>

<div className="modal fade" id="contact" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Contact Support</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Anda dapat menghubungi Kami melalui surat elektronik ke tokytokyofficial@gmail.com atau melalui telepon ke nomor (0853) 2345 1500. Semua korespondensi Anda akan dicatat, direkam dan disimpan untuk arsip Kami.</p>

      </div>

    </div>
  </div>
</div>

<div className="modal fade" id="term" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Term of Use</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div id="services" className="mb-4">

        <p><strong> BACALAH SELURUH KETENTUAN PENGGUNAAN INI SEBELUM MENGAKSES ATAU MENGGUNAKAN APLIKASI Tokytoky.</strong></p>

        <p>Ketentuan Penggunaan ini adalah perjanjian antara pengguna ("Anda") dan PT Surga Kuliner Nusantara ("Kami"), sebuah perseroan terbatas yang didirikan dan beroperasi secara sah berdasarkan hukum negara Republik Indonesia dan berdomisili di Kota Palu, Indonesia. Ketentuan Penggunaan ini mengatur akses dan penggunaan Anda atas aplikasi, situs web (www.tokytoky.id) dan situs web lain yang Kami kelola), konten dan produk yang disediakan oleh Kami (selanjutnya, secara bersama-sama disebut sebagai "Aplikasi"), serta pemesanan, pembayaran atau penggunaan layanan yang tersedia pada Aplikasi ("Layanan").</p>

        <p>Dengan menyetujui Ketentuan Penggunaan ini, Anda juga menyetujui Ketentuan Penggunaan tambahan, termasuk Ketentuan Penggunaan pada setiap Layanan, dan perubahannya yang merupakan bagian yang tidak terpisahkan dari Ketentuan Penggunaan ini (selanjutnya, Ketentuan Penggunaan, Ketentuan Penggunaan tambahan, dan perubahannya secara bersama-sama disebut sebagai “Ketentuan Penggunaan”). Meskipun merupakan satu kesatuan, Ketentuan Penggunaan tambahan akan berlaku dalam hal terdapat perbedaan dengan Ketentuan Penggunaan.</p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Penggunaan Aplikasi dan Layanan.</h6>
        </div>
        <p>
        Kami memiliki pedoman khusus dalam hal pembelian, pembayaran, dan pengiriman untuk memastikan jaminan layanan dan kualitas dari setiap produk kami.
        </p>

        <p>
            Metode Pembayaran yang kami gunakan saat ini adalah :
        </p>
        <ul className="text-secondary">
          <li className="pb-2">Cash, Anda dapat melakukan pembayaran dengan uang cash saat Anda menerima pesanan Anda di tempat.</li>
          <li className="pb-2">Online Payment, Pembayaran langsung dengan layanan transfer otomatis ke rekening kami di website/aplikasi kami.</li>
          <li className="pb-2">Jam Operasional : Ketetapan jam operasional Setiap Hari, mulai dari pukul 10:00  sampai dengan 22:00 WITA untuk seluruh outlet kami.</li>
          <li className="pb-2">Minimum order online via delivery adalah Rp.50.000,-.</li>
          <li className="pb-2">Setiap transaksi baik online maupun offline akan dikenakan pajak 10% dari setiap transaksi.</li>
          <li className="pb-2">Ketika melakukan pemesanan online dan pembayaran telah dilakukan / sukses, maka Anda tidak berhak untuk membatalkan pesanan Anda.</li>
          <li className="pb-2">Kami menggunakan layanan jasa pihak ke tiga untuk melakukan pengantaran pesanan Anda setelah selesai di proses.</li>
        </ul>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Informasi Pribadi.</h6>
        </div>
        <p>
          Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda, seperti nama, alamat surat elektronik, dan nomor telepon genggam Anda yang Anda berikan ketika Anda membuka Akun tunduk pada kebijakan privasi, yang merupakan bagian yang tidak terpisahkan dari Ketentuan Penggunaan ini.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Konten, Informasi dan Promosi.</h6>
        </div>
        <p>
          Kami atau pihak lain yang bekerja sama dengan Kami dapat menyediakan Konten Pihak Ketiga yang dapat Anda temukan pada Aplikasi. Dalam hal Konten Pihak Ketiga disediakan oleh pihak lain yang bekerja sama dengan Kami (“Penyedia Konten Pihak Ketiga”), Kami tidak bertanggung jawab atas bagian apapun dari isi Konten Pihak Ketiga. Akses atau penggunaan Anda terhadap Konten Pihak Ketiga tersebut merupakan bentuk persetujuan Anda untuk tunduk pada syarat dan ketentuan yang ditetapkan oleh Kami atau Penyedia Konten Pihak Ketiga, termasuk terhadap Kebijakan Privasi Kami atau Penyedia Konten Pihak Ketiga.
        </p>

        <p>
          Konten Pihak Ketiga adalah setiap dan/atau seluruh informasi dan penawaran barang dan/atau jasa, yang dibuat dan/atau disusun dan/atau dikembangkan dan/atau dikelola oleh Penyedia Konten Pihak Ketiga termasuk namun tidak terbatas pada teks atau tulisan, gambar, quotes atau kutipan, foto, ilustrasi, animasi, video, rekaman suara atau musik, judul, deskripsi dan/atau setiap data dalam bentuk apapun yang disediakan oleh Penyedia Konten Pihak Ketiga untuk ditampilkan pada Aplikasi Gojek, termasuk setiap tautan yang menghubungkan kepadanya. Untuk menghindari keraguan, Konten Pihak Ketiga mencakup pula setiap dan/atau seluruh penawaran barang dan/atau jasa, informasi, data, berita aktual, tulisan, gambar, kutipan, foto, ilustrasi, animasi, video, rekaman suara, yang diperoleh Penyedia Konten Pihak Ketiga dari pihak ketiga, dimana Penyedia Konten Pihak Ketiga telah memiliki kewenangan untuk menggunakan dan mendistribusikan konten tersebut.
        </p>

        <p>
          Kami atau pihak lain yang bekerja sama dengan Kami dapat memberikan penawaran atau promosi (“Penawaran”) yang dapat ditukar dengan barang, Layanan atau manfaat lain terkait dengan penggunaan Aplikasi. Dalam hal Penawaran disediakan oleh pihak lain yang bekerja sama dengan Kami (“Penyedia Penawaran”), Kami tidak bertanggung jawab atas bagian apapun dari isi Penawaran tersebut. Akses atau penggunaan Anda terhadap Penawaran merupakan bentuk persetujuan Anda untuk tunduk pada syarat dan ketentuan yang ditetapkan oleh Kami atau Penyedia Penawaran, termasuk terhadap Kebijakan Privasi Kami atau Penyedia Penawaran.
        </p>

        <p>
          Semua informasi, Penawaran yang terdapat pada Aplikasi hanya dimaksudkan untuk memberikan Anda pengalaman terbaik ketika menggunakan Aplikasi atau Layanan. Anda tidak boleh menyalahgunakan Penawaran yang Anda terima selama penggunaan Aplikasi atau Layanan.
        </p>

        <p>
          Anda setuju untuk menggunakan Penawaran tersebut sesuai dengan syarat, ketentuan, dan maksud dari pemberian Penawaran dan tidak akan menyalahgunakan, menggandakan, menguangkan, mengalihkan, menggunakan untuk kepentingan komersial atau mengambil keuntungan dengan tidak wajar dari promosi tersebut dengan bentuk atau cara apapun.
        </p>

        <p>
          Anda memahami bahwa Penawaran tidak dapat ditukar dengan uang tunai, memiliki masa keberlakuan yang terbatas dan tunduk pada ketentuan yang berlaku untuk setiap Penawaran tersebut.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Perangkat Lunak Aplikasi.</h6>
        </div>
        <p>
          Untuk saat ini, Kami hanya memiliki satu situs (www.tokytoky.id) yang bisa diakses melalui web browser resmi di Handphone, Tablet maupun Perangkat lain Anda.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Kekayaan Intelektual.</h6>
        </div>
        <p>
          Aplikasi dan Layanan, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia yang terdaftar baik atas nama Kami ataupun afiliasi Kami. Kami (dan pemberi lisensi Kami) memiliki seluruh hak dan kepentingan atas Aplikasi dan Layanan, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang terdapat didalamnya dan hak kekayaan intelektual terkait.
        </p>

        <p>
          Tunduk pada Ketentuan Penggunaan ini, Kami memberikan Anda lisensi terbatas yang tidak eksklusif, dapat ditarik kembali, tidak dapat dialihkan (tanpa hak sublisensi) untuk (i) mengunduh, mengakses, dan menggunakan Aplikasi, sebagaimana adanya, hanya pada perangkat berupa telepon genggam dan/atau tablet pribadi Anda dan hanya untuk keperluan terkait penggunaan Layanan, dan (ii) mengakses atau menggunakan konten, informasi dan materi terkait yang tersedia pada Aplikasi hanya untuk kepentingan pribadi dan bukan tujuan komersial. Hak dan hak istimewa lainnya yang tidak secara tegas diberikan dalam Ketentuan Penggunaan ini, adalah hak Kami atau pemberi lisensi Kami.
        </p>

        <p>
          Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan atau penawaran untuk menjual, penampilan baik sebagian atau seluruhnya, serta penggunaan Aplikasi dan/atau Layanan, baik secara digital atau lainnya atau pada perangkat selain telepon genggam atau tablet, termasuk namun tidak terbatas pada penggunaan aplikasi tambahan seperti aplikasi modifikasi, emulator, dan lain-lain, yang menyimpang dari Ketentuan Penggunaan ini, maupun tujuan penggunaan yang ditentukan oleh Kami, merupakan pelanggaran terhadap hak kekayaan intelektual Kami.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Anda tidak boleh:</h6>
        </div>
        <p>
          menghapus setiap pemberitahuan hak cipta, merek dagang atau pemberitahuan hak milik lainnya yang terkandung dalam Aplikasi;
        </p>
        <p>
          menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya turunan dari, mendistribusikan, memberikan lisensi, menjual, mengalihkan, menampilkan di muka umum baik sebagian maupun seluruhnya, merekayasa balik (reverse engineer), mentransmisikan, memindahkan, menyiarkan, menguraikan, atau membongkar bagian manapun dari atau dengan cara lain mengeksploitasi Aplikasi (termasuk perangkat lunak, fitur dan Layanan di dalamnya);
        </p>
        <p>
        memberikan lisensi, mensublisensikan, menjual, menjual kembali, memindahkan, mengalihkan, mendistribusikan atau mengeksploitasi secara komersial atau membuat tersedia kepada pihak lain Aplikasi dan/atau perangkat lunak dengan cara menciptakan tautan (link) internet ke Aplikasi atau "frame" atau "mirror" setiap perangkat lunak pada server lain atau perangkat nirkabel atau yang berbasis internet;
        </p>
        <p>
          meluncurkan program otomatis atau script, termasuk, namun tidak terbatas pada, web spiders, web crawlers, web robots, web ants, web indexers, bots, virus atau worm, atau program apapun yang mungkin membuat beberapa permintaan server per detik, menciptakan beban berat atau menghambat operasi dan/atau kinerja Aplikasi;
        </p>
        <p>
        menggunakan aplikasi pencarian atau pengambilan kembali situs, perangkat manual atau otomatis lainnya untuk mengambil (scraping), indeks (indexing), survei (surveying), tambang data (data mining), atau dengan cara apapun memperbanyak atau menghindari struktur navigasi atau presentasi dari Aplikasi atau isinya;
        </p>
        <p>
        menerbitkan, mendistribusikan atau memperbanyak dengan cara apapun materi yang dilindungi hak cipta, merek dagang, atau informasi lain yang Kami miliki tanpa persetujuan tertulis terlebih dahulu dari Kami atau pemilik hak yang melisensikan hak-nya kepada Kami, dan menggunakan dan/atau mengakses secara tidak resmi Aplikasi untuk (a) merusak, melemahkan atau membahayakan setiap aspek dari Aplikasi, Layanan atau sistem dan jaringan terkait, dan/atau (b) membuat produk atau layanan tandingan serupa menggunakan ide, fitur, fungsi atau grafik menyerupai Aplikasi.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Tanggung Jawab Anda</h6>
        </div>
        <p>
          Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran. Anda harus memperlakukan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran dengan hormat dan tidak boleh terlibat dalam perilaku atau tindakan yang tidak sah, mengancam atau melecehkan ketika menggunakan Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran.
        </p>
        <p>
          Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran melalui Akun Anda, baik oleh Anda atau pihak lain yang menggunakan Akun Anda, dengan cara yang bertentangan dengan Ketentuan Penggunaan ini, kebijakan privasit ermasukasuk syarat dan ketentuan dan kebijakan privasi yang ditentukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran, atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishingdan/atau social engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi Kami.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Batasan Tanggung Jawab Kami</h6>
        </div>
        <p>
          Kami menyediakan Layanan sebagaimana adanya dan Kami tidak menyatakan atau menjamin bahwa keandalan, ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari Layanan kami dapat memenuhi kebutuhan dan akan sesuai dengan harapan Anda, termasuk namun tidak terbatas pada Layanan, Konten Pihak Ketiga, Penawaran dan Metode Pembayaran yang sepenuhnya menjadi tanggung jawab Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode Pembayaran. Kami tidak bertanggung jawab atas setiap kerugian atau kerusakan yang disebabkan oleh setiap kegagalan atau kesalahan yang dilakukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran ataupun kegagalan atau kesalahan Anda dalam mematuhi Ketentuan Penggunaan Kami, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.
        </p>
        <p>
          Aplikasi dapat mengalami keterbatasan, penundaan, dan masalah-masalah lain yang terdapat dalam penggunaan internet dan komunikasi elektronik, termasuk perangkat Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran rusak, tidak terhubung dengan internet, berada di luar jangkauan, dimatikan atau tidak berfungsi. Kami tidak bertanggung jawab atas keterlambatan, kegagalan pengiriman, kerusakan atau kerugian yang diakibatkan oleh masalah-masalah tersebut.
        </p>
        <p>
          Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas situs Kami. Namun, Kami tetap melakukan pengawasan untuk tujuan memastikan kelancaran penggunaan Aplikasi dan untuk memastikan kepatuhan terhadap Ketentuan Penggunaan ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif atau badan pemerintahan lainnya.
        </p>
        <p>
          Kami tidak mempunyai kewajiban apapun, termasuk untuk mengambil tindakan lebih jauh atau tindakan hukum yang dianggap perlu oleh Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, terhadap setiap permasalahan atau perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran. Tetapi, Kami akan memfasilitasi setiap permasalahan atau perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran dengan upaya wajar yang diperlukan. Ketika Kami memfasilitasi penyelesaian permasalahan atau perselisihan antara Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, Kami tidak bertindak sebagai mediator dan hal tersebut tidak menimbulkan kewajiban lebih jauh apapun terhadap Kami.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Penyelesaian Masalah:</h6>
        </div>
        <p>
        Apabila Anda mengalami gangguan sistem, mengetahui atau menduga bahwa Akun Anda diretas, digunakan atau disalahgunakan oleh pihak lain, atau apabila perangkat telepon genggam atau tablet pribadi Anda hilang, dicuri, diretas atau terkena virus, segera laporkan kepada Kami sehingga Kami dapat segera mengambil tindakan yang diperlukan untuk menghindari penggunaan, penyalahgunaan, atau kerugian yang timbul atau mungkin timbul lebih lanjut.
        </p>
        <p>
        Apabila Anda mengalami kendala atau masalah terkait Layanan, Konten Pihak Ketiga, Penawaran atau pembayaran melalui Metode Pembayaran, atau perlakuan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran Anda dapat menyampaikan keluhan Anda melalui fitur yang Kami sediakan, termasuk pemberian peringkat dan komentar, atau dengan menghubungi Kami.
        </p>
        <p>
        Untuk menyampaikan keluhan, pertanyaan, sanggahan, dan lain-lain (“Laporan”), Anda perlu memberikan informasi yang cukup, termasuk namun tidak terbatas pada, ringkasan fakta yang terjadi, bukti-bukti yang Anda miliki, nomor pesanan, dan informasi pribadi, seperti alamat surat elektronik dan nomor telepon genggam terdaftar.
        </p>
        <p>
        Untuk menanggapi setiap Laporan yang Anda sampaikan, Kami akan melakukan verifikasi terlebih dahulu dengan mencocokan informasi yang Anda berikan dan informasi pribadi Anda yang terdapat dalam sistem Kami. Jika diperlukan, Kami dapat secara langsung meminta Anda memberikan informasi yang diperlukan untuk tujuan verifikasi.
        </p>
        <p>
        Kami dapat menolak untuk menindaklanjuti Laporan Anda jika informasi yang Anda berikan tidak cocok dengan informasi pribadi yang terdapat dalam sistem Kami atau apabila Laporan disampaikan terkait, terhadap, atas nama atau oleh pihak lain yang berbeda dengan pemilik Akun yang bersangkutan yang terdaftar secara resmi pada sistem Kami. Kami dapat memberhentikan tindak lanjut terhadap Laporan Anda jika Kami, dengan kebijakan Kami sepenuhnya, menganggap bahwa Laporan Anda tidak didukung oleh fakta-fakta yang cukup dan jelas, atau telah selesai. Kami juga dapat meneruskan Laporan Anda kepada Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran untuk diselesaikan secara langsung oleh Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.
        </p>
        <p>
        Terhadap Laporan tertentu terkait Layanan, Kami dapat, dengan kebijakan Kami sepenuhnya, memfasilitasi pertemuan antara Anda dan Penyedia Layanan untuk tujuan penyelesaian masalah secara damai dan kekeluargaan. Dalam hal demikian, jika Anda merasa tetap perlu mengambil tindakan lain, termasuk tindakan hukum apapun, Anda dapat melakukannya atas tanggung jawab pribadi Anda sepenuhnya.
        </p>
        <p>
        Terhadap Laporan tertentu terkait Metode Pembayaran, Kami dapat mengembalikan dana Anda melalui cara yang ditentukan oleh Kami atau bersama-sama dengan Penyedia Metode Pembayaran, berdasarkan kebijakan Kami sepenuhnya. Kami tidak akan mengembalikan dana kepada pihak lain yang berbeda dengan kredensial terdaftar pada Akun Gojek atau Penyedia Metode Pembayaran, termasuk apabila Anda menggunakan kredensial pihak lain untuk melakukan pembayaran melalui Metode Pembayaran.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Tindakan yang Kami Anggap Perlu</h6>
        </div>
        <p>
        Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah melakukan tindakan asusila, pelanggaran, kejahatan atau tindakan lain yang bertentangan dengan Ketentuan Penggunaan ini dan/atau peraturan perundang-undangan yang berlaku, baik yang dirujuk dalam Ketentuan Penggunaan ini atau tidak, maka Kami berhak untuk dan dapat membekukan Akun, baik sementara atau permanen, atau menghentikan akses Anda terhadap Aplikasi, termasuk Layanan, Konten Pihak Ketiga, Penawaran dan/atau Metode Pembayaran yang terdapat di dalamnya, melakukan pemeriksaan, menuntut ganti kerugian, melaporkan kepada pihak berwenang dan/atau mengambil tindakan lain yang kami anggap perlu, termasuk tindakan hukum pidana maupun perdata.
        </p>
        <p>
        Kami akan menindaklanjuti dengan melakukan investigasi dan/atau memfasilitasi Penyedia Layanan yang bersangkutan untuk melaporkan kepada pihak yang berwajib apabila Kami menerima Laporan adanya pelanggaran yang Anda lakukan atas Ketentuan Penggunaan ini ataupun pelanggaran terhadap peraturan perundang-undangan yang berlaku, sehubungan dengan:
        </p>
        <p>
        Pelecehan atau kekerasan verbal, termasuk namun tidak terbatas pada, atas fisik, jenis kelamin, suku, agama dan ras, yang Anda lakukan terhadap Penyedia Layanan;
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Tindakan penipuan</h6>
        </div>
        <p>
          Pengaksesan layanan pada perangkat rooted atau jail-broken;
          Pelecehan atau kekerasan fisik yang Anda lakukan terhadap Penyedia Layanan; dan/atau
          Pelecehan atau kekerasan seksual, baik secara verbal maupun fisik, yang Anda lakukan terhadap Penyedia Layanan.
          Ketentuan ini juga berlaku sebaliknya apabila Anda mengalami tindakan sebagaimana disebutkan di atas yang dilakukan oleh Penyedia Layanan terhadap Anda.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Pernyataan Anda</h6>
        </div>
        <p>
        Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18 (delapan belas) tahun atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Ketentuan Penggunaan ini. Jika Anda tidak memenuhi ketentuan tersebut namun tetap mengakses atau menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran, Anda menyatakan dan menjamin bahwa tindakan Anda membuka, mengakses atau melakukan aktivitas lain dalam Aplikasi telah disetujui oleh orang tua, wali atau pengampu Anda. Anda secara tegas mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh persetujuan yang Anda berikan berdasarkan Ketentuan Penggunaan ini pada waktu Anda dianggap telah dewasa secara hukum.
        </p>
        <p>
        Anda setuju untuk mengakses atau menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran hanya untuk tujuan sebagaimana ditentukan dalam Ketentuan Penggunaan ini dan tidak menyalahgunakan atau menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau Metode Pembayaran untuk tujuan penipuan, menyebabkan ketidaknyamanan kepada orang lain, melakukan pemesanan palsu atau yang tindakan-tindakan lain yang dapat atau dianggap dapat menimbulkan kerugian dalam bentuk apapun terhadap orang lain.
        </p>
        <p>
        Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari penggunaan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran dan Metode Pembayaran sepenuhnya menjadi tanggung jawab Anda dan Anda dengan ini setuju untuk melepaskan Kami dari segala tuntutan apapun sehubungan dengan kerusakan, gangguan, atau bentuk lain dari gangguan sistem yang disebabkan oleh akses tidak resmi oleh pihak lain.
        </p>
        <p>
        Anda secara tegas membebaskan Kami, termasuk namun tidak terbatas pada, pejabat, direktur, komisaris, karyawan dan agen Kami, dari dari setiap dan semua kewajiban, konsekuensi, kerugian baik materiil atau immateriil, tuntutan, biaya-biaya (termasuk biaya advokat) atau tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat pelanggaran Anda terhadap Ketentuan Penggunaan ini maupun sehubungan dengan tindakan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran.
        </p>
        <p>
        Dikecualikan dari ketentuan di atas, jika terdapat kerugian langsung akibat pelanggaran Kami terhadap Ketentuan Penggunaan ini, maka Anda setuju dan secara tegas membatasi jumlah tuntutan Anda sebesar jumlah keseluruhan yang senyatanya ditimbulkan atau telah dibayarkan sehubungan dengan peristiwa tersebut.
        </p>

        <p>
        <strong>ANDA MENGAKUI BAHWA KAMI ADALAH PERUSAHAAN F&B, BUKAN PERUSAHAAN KURIR, KEUANGAN DAN LAIN-LAIN DAN KAMI TIDAK MEMBERIKAN LAYANAN KURIR, KEUANGAN DAN LAYANAN LAIN. SEMUA LAYANAN KURIRKEUANGAN DAN LAYANAN LAIN DISEDIAKAN PIHAK KETIGA INDEPENDEN YANG TIDAK DIPEKERJAKAN OLEH KAMI.
        <br/>DENGAN MELANJUTKAN AKSES ATAU PENGGUNAAN TERHADAP TOKYTOKY.ID DAN/ATAU LAYANAN, ANDA SETUJU UNTUK TUNDUK DAN MEMATUHI SEMUA KETENTUAN PERATURAN PERUNDANG-UNDANGAN TERKAIT DAN KETENTUAN PENGGUNAAN INI, TERMASUK SEMUA PERUBAHANNYA DAN KETENTUAN PENGGUNAAN DARI SETIAP PENYEDIA LAYANAN, PENYEDIA KONTEN PIHAK KETIGA, PENYEDIA PENAWARAN ATAU PENYEDIA METODE PEMBAYARAN. SEGERA HENTIKAN AKSES ATAU PENGGUNAAN APLIKASI TOKYTOKY.ID DAN/ATAU LAYANAN JIKA ANDA TIDAK SETUJU DENGAN BAGIAN APAPUN DARI KETENTUAN PENGGUNAAN INI.</strong>
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Keadaan Kahar</h6>
        </div>
        <p>
          Layanan dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol Kami (“Keadaan Kahar”), termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda setuju untuk membebaskan Kami dari setiap tuntutan dan tanggung jawab, jika Kami tidak dapat memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan melalui Aplikasi, baik sebagian maupun seluruhnya, karena suatu Keadaan Kahar.
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">Lain-lain</h6>
        </div>
        <p>
        Anda mengerti dan setuju bahwa Ketentuan Penggunaan ini merupakan perjanjian dalam bentuk elektronik dan merupakan persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Ketentuan Penggunaan ini dan Kebijakan Privasi adalah sah dan mengikat secara hukum dan terus berlaku sepanjang penggunaan Aplikasi dan Layanan oleh Anda.
        </p>
        <p>
        Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Ketentuan Penggunaan kebijakan privasi yang dibuat dalam bentuk elektronik.
        </p>
        <p>Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Ketentuan Penggunaan kebijakan privasi yang dibuat dalam bentuk elektronik.</p>
        <p>Anda tidak dapat mengalihkan hak Anda berdasarkan Ketentuan Penggunaan ini tanpa persetujuan tertulis sebelumnya dari Kami. Namun, Kami dapat mengalihkan hak Kami berdasarkan Ketentuan Penggunaan ini setiap saat kepada pihak lain tanpa perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan pemberitahuan sebelumya kepada Anda.</p>
        <p>Bila Anda tidak mematuhi atau melanggar ketentuan dalam Ketentuan Penggunaan ini, dan Kami tidak mengambil tindakan secara langsung, bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan yang diperlukan di kemudian hari.</p>
        <p>Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Aplikasi atau setelah berakhirnya perjanjian ini antara Anda dan Kami.</p>
        <p>Jika salah satu dari ketentuan dalam Ketentuan Penggunaan ini tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan lainnya.</p>
        <p>Kami membuat Ketentuan Penggunaan ini dalam bahasa Indonesia dan Inggris. Setiap perbedaan akan diartikan sesuai dengan bahasa Indonesia.</p>
        <p>Ketentuan Penggunaan ini diatur berdasarkan hukum Republik Indonesia. Setiap dan seluruh perselisihan yang timbul dari penggunaan Aplikasi atau Layanan tunduk pada yurisdiksi eksklusif Pengadilan Negeri Jakarta Selatan.</p>
        <p><strong>Saya telah membaca dan mengerti seluruh Ketentuan Penggunaan ini dan konsekuensinya dan dengan ini menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya.</strong></p>
        </div>
      </div>

    </div>
  </div>
</div>

<div className="modal fade" id="privacy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Privacy Polic</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
            <div id="intro" className="mb-4">
                <div className="mb-4">
                <center><h2 className="h5">PRIVACY POLICY as of 14 June 2021</h2></center>
                </div>
                <p>
                    Kebijakan Privasi berikut ini menjelaskan bagaimana kami, TokyToky (PT Surga Kuliner Nusantara) dan afiliasi-afiliasi, atau "kami") mengumpulkan, menyimpan, menggunakan, mengolah, menguasai, mentransfer, mengungkapkan dan melindungi Informasi Pribadi anda. Kebijakan Privasi ini berlaku bagi seluruh pengguna aplikasi-aplikasi, situs web (www.tokytoky.id) layanan, atau produk ("Aplikasi") kami, kecuali diatur pada kebijakan privasi yang terpisah.
                </p>
                <p>
                    Mohon baca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa anda memahami bagaimana proses pengolahan data kami. Kecuali didefinisikan lain, semua istilah dengan huruf kapital yang digunakan dalam Kebijakan Privasi ini memiliki arti yang sama dengan yang tercantum dalam Ketentuan Pengunaan.
                </p>
            </div>
            <div id="services" className="mb-4">
                <p>
                    Kebijakan Privasi ini mencakup hal-hal sebagai berikut :
                </p>
                <ul className="text-secondary">
                  <li className="pb-2">Informasi Pribadi yang kami kumpulkan.</li>
                  <li className="pb-2">Penggunaan Informasi Pribadi yang kami kumpulkan.</li>
                  <li className="pb-2">Pemberian Informasi Pribadi yang kami kumpulkan.</li>
                  <li className="pb-2">Penyimpanan Informasi Pribadi.</li>
                  <li className="pb-2">Akses dan koreksi Informasi Pribadi.</li>
                  <li className="pb-2">Tempat kami menyimpan Informasi Pribadi anda.</li>
                  <li className="pb-2">Keamanan Informasi Pribadi anda.</li>
                  <li className="pb-2">Perubahan atas Kebijakan Privasi ini.</li>
                  <li className="pb-2">Pengakuan dan persetujuan.</li>
                  <li className="pb-2">Materi pemasaran dan promosi.</li>
                  <li className="pb-2">Data anonim</li>
                  <li className="pb-2">Platform pihak ketiga</li>
                  <li className="pb-2">Cara untuk menghubungi kami</li>
                </ul>

                <div id="information" className="mb-3 active">
                <h4 className="h5">1. INFORMASI PRIBADI YANG KAMI KUMPULKAN.</h4>
                </div>
                <p>
                    Kami mengumpulkan informasi yang mengidentifikasikan atau dapat digunakan untuk mengidentifikasi, menghubungi, atau menemukan orang atau perangkat yang terkait dengan informasi tersebut ("Informasi Pribadi"). Informasi Pribadi termasuk, tetapi tidak terbatas pada, nama, alamat, tanggal lahir, pekerjaan, nomor telepon, alamat e-mail, rekening bank dan detail kartu kredit, jenis kelamin, identifikasi (termasuk KTP, SIM, atau Paspor) atau tanda pengenal lainnya yang dikeluarkan oleh pemerintah, foto, kewarganegaraan, nomor telepon pengguna dan non-pengguna Aplikasi kami yang terdapat dalam daftar kontak telefon selular anda, data kesehatan, informasi keuangan terkait, serta informasi biometrik (termasuk namun tidak terbatas pengenalan wajah). Selain itu, untuk informasi lainnya, seperti profil pribadi, dan/atau nomor pengenal unik, yang dikaitkan atau digabungkan dengan Informasi Pribadi, maka informasi tersebut juga dianggap sebagai Informasi Pribadi. Informasi Pribadi yang kami kumpulkan dapat diberikan oleh anda secara langsung atau oleh pihak ketiga (misalnya: ketika anda mendaftar atau menggunakan Aplikasi, ketika anda menghubungi layanan pelanggan kami, atau sebaliknya ketika anda memberikan Informasi Pribadi kepada kami). Kami dapat mengumpulkan informasi dalam berbagai macam bentuk dan tujuan (termasuk tujuan yang diizinkan berdasarkan peraturan perundang-undangan yang berlaku).
                </p>
                <h5 className="h6">Informasi yang kami kumpulkan dari anda atau perangkat seluler anda secara langsung.</h5>
                <ul className="text-secondary">
                <li className="pb-2">Ketika anda mendaftar dan membuat akun dengan kami menggunakan Aplikasi, anda harus memberikan kepada kami Informasi Pribadi tertentu, termasuk nama, alamat fisik, alamat e-mail, dan nomor telepon anda. Jika anda menggunakan Aplikasi sebagai penyedia layanan, anda harus memberikan Informasi Pribadi tambahan kepada kami sebagai bagian dari proses pendaftaran penyedia layanan. Ini akan mencakup rincian mengenai kendaraan anda (izin, persetujuan, dan otorisasi lainnya yang anda perlukan untuk mengoperasikan kendaraan dan untuk menyediakan layanan transportasi), polis asuransi anda, dan rekening bank anda.</li>
                <li className="pb-2">
                    <p><span >Ketika anda menggunakan Aplikasi, anda harus memberikan kami informasi yang relevan yang mungkin diperlukan oleh kami agar Aplikasi dapat berfungsi, contohnya:</span></p>
                    <p style={{marginLeft: 20}}><span>a. &nbsp;</span><span >Jika anda menggunakan Aplikasi sebagai pengguna, anda harus memberikan informasi pada kami sesuai dengan jenis layanan yang anda cari, dan rincian penjemputan dan/atau pengiriman.</span></p>
                    <p style={{marginLeft: 20}}><span >b. &nbsp;</span><span>Jika anda menggunakan Aplikasi sebagai penyedia layanan, agar Aplikasi dapat berfungsi, anda harus memberikan kepada kami informasi terkait dengan layanan yang anda dapat terima pesanannya pada saat itu, dan perinciannya pada saat ini, dan setelah pesanan untuk layanan yang ditempatkan oleh pengguna diterima oleh anda sebagai penyedia layanan, anda mungkin akan perlu memberikan kepada kami data lain yang kami perlukan untuk mengelola Aplikasi dan ekosistem terkait, serta untuk memantau keseluruhan penggunaan Aplikasi.</span></p>
                    <p style={{marginLeft: 20}}><span >c.&nbsp;</span><span >Jika anda menggunakan dan/atau ketika pembayaran atau transfer dilakukan melalui fasilitas uang elektronik dan/atau dompet elektronik yang disediakan oleh kami, jika anda adalah pihak pembayar atau pengirim, anda akan menyediakan informasi kepada kami yang berkaitan dengan penggunaan, pembayaran atau transfer, termasuk tetapi tidak terbatas pada rincian transfer dan/atau penerima pembayaran, jumlah pembayaran yang dibayarkan, jenis kartu pembayaran dan rekening yang digunakan, nama penerbit kartu pembayaran atau akun tersebut, nama pemegang dan nomor kartu pembayaran atau rekening tersebut, kode verifikasi kartu pembayaran atau rekening dan tanggal kadaluarsa kartu pembayaran atau rekening, sebagaimana berlaku.</span></p>
                    <p style={{marginLeft: 20}}><span >d.&nbsp;</span><span >Jika anda bermaksud untuk mendaftar sebagai pemegang akun terdaftar atau terverifikasi dari fasilitas uang elektronik yang disediakan oleh kami, anda akan memberikan informasi kepada kami yang diperlukan termasuk tetapi tidak terbatas pada nama lengkap anda, nomor kartu identitas anda, jenis kartu identitas yang anda gunakan untuk registrasi, alamat, jenis kelamin, tempat dan tanggal lahir, pekerjaan, rincian pajak, kebangsaan dan/atau data biometrik.</span></p>
                    <p style={{marginLeft: 20}}><span >e.&nbsp;</span><span >Jika anda bermaksud untuk menambahkan kartu pembayaran atau rekening sebagai sumber dana untuk pembayaran dalam Aplikasi, anda akan memberikan kepada kami informasi terkait jenis kartu pembayaran dan rekening yang didaftarkan, penerbit kartu pembayaran atau rekening, nama pemegang kartu pembayaran dan rekening tersebut, nomor identifikasi dari kartu pembayaran atau rekening tersebut dan kode verifikasi kartu pembayaran atau rekening dan tanggal kadaluarsa kartu pembayaran dan rekening tersebut, sebagaimana berlaku.</span></p>
                </li>
                </ul>
                <h5 className="h6">Informasi yang dikumpulkan setiap kali anda menggunakan Aplikasi atau mengunjungi situs web kami.</h5>
                <ul className="text-secondary">
                <li className="pb-2">Setiap kali anda menggunakan Aplikasi atau mengunjungi situs web kami, kami dapat mengumpulkan data teknis tertentu sehubungan dengan penggunaan anda seperti, alamat protokol internet, informasi halaman web yang sebelumnya atau selanjutnya dilihat, durasi setiap kunjungan/sesi, identitas perangkat internet atau alamat kontrol akses media, dan mobile advertising ID dan informasi perangkat lainnya termasuk informasi mengenai produsen, model, dan sistem operasi dari perangkat yang anda gunakan untuk mengakses Aplikasi atau situs web kami..</li>
                <li className="pb-2">Ketika anda menggunakan Aplikasi atau mengunjungi situs web kami, informasi tertentu juga dapat dikumpulkan secara otomatis dengan menggunakan cookies. Cookies adalah berkas data kecil yang tersimpan pada komputer atau perangkat seluler anda. Kami menggunakan cookies untuk melacak aktivitas pengguna dengan tujuan untuk meningkatkan antarmuka dan pengalaman pengguna. Sebagian besar perangkat seluler dan peramban internet mendukung penggunaan cookies; namun anda dapat menyesuaikan pengaturan pada perangkat seluler atau peramban internet anda untuk menolak beberapa jenis cookies atau cookies spesifik tertentu. Perangkat seluler dan/atau peramban anda juga memungkinkan anda untuk menghapus cookies apa pun yang sebelumnya telah tersimpan. Namun demikian, hal itu dapat mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi atau situs web kami.</li>
                <li className="pb-2">Setiap kali anda menggunakan Aplikasi melalui perangkat seluler, kami akan melacak dan mengumpulkan informasi lokasi geografis anda dalam waktu sebenarnya. Dalam beberapa kasus, anda akan diminta atau diharuskan untuk mengaktifkan Global Positioning System (GPS) pada perangkat seluler anda untuk memungkinkan kami agar dapat memberikan anda pengalaman yang lebih baik dalam menggunakan Aplikasi (misalnya, untuk memberikan informasi tentang seberapa dekat penyedia layanan dengan anda). Anda dapat menonaktifkan informasi pelacakan lokasi geografis pada perangkat seluler anda untuk sementara waktu. Namun, hal ini dapat mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi.</li>
                <li className="pb-2">Jika anda menggunakan dan/atau ketika pembayaran atau transfer dilakukan melalui fasilitas uang elektronik dan/atau dompet elektronik yang disediakan oleh kami, kami dapat mengumpulkan informasi tertentu terkait dengan sumber dana yang anda gunakan untuk melakukan isi ulang (termasuk rincian rekening bank), rincian rekening penerima penarikan, riwayat transaksi (termasuk detail penerima), rincian tagihan, faktur, dan nomor telepon.</li>
                <li className="pb-2">Jika anda menggunakan akun virtual yang disediakan oleh kami untuk menerima pembayaran menggunakan uang elektronik dan/atau dompet elektronik dari pembayar, baik anda penyedia layanan atau merchant, kami dapat mengumpulkan informasi tertentu yang terkait dengan penggunaan anda termasuk tetapi tidak terbatas pada layanan dan/atau barang yang ditransaksikan, jumlah yang anda kumpulkan dari setiap transaksi, rincian akun penarikan atau penyelesaian pembayaran dan riwayat penarikan atau penyelesaian pembayaran.</li>
                <li className="pb-2">Jika anda menggunakan dan/atau pada saat suatu pembayaran dilakukan melalui kartu pembayaran atau rekening yang anda tambahkan di Aplikasi, kami dapat mengumpulkan informasi tertentu terkait catatan transaksi, termasuk rincian penerima, rincian tagihan, rincian tanda terima, dan rincian nomor telepon.</li>
                </ul>
                <h5 className="h6">Informasi yang dikumpulkan dari pihak ketiga.</h5>
                <p>
                    Kami juga dapat mengumpulkan Informasi Pribadi anda dari pihak ketiga (termasuk agen, vendor, pemasok, kontraktor, mitra, dan pihak lainnya yang memberikan layanan kepada kami, mengumpulkan Data Pribadi dan/atau melakukan tugas atas nama kami, atau dengan siapa kami melakukan kerja sama). Dalam kasus tersebut, kami hanya akan mengumpulkan Informasi Pribadi anda untuk atau sehubungan dengan tujuan yang melibatkan pihak ketiga tersebut atau tujuan kerja sama kami dengan pihak ketiga tersebut (tergantung pada situasinya). Khususnya pada saat anda mendaftarkan kartu pembayaran atau rekening melalui Aplikasi dan/atau mengakses, menambahkan dan/atau menghubungkan kartu pembayaran atau rekening anda pada Aplikasi, kami dapat mengumpulkan informasi keuangan tertentu dan catatan finansial anda (termasuk tetapi tidak terbatas pada catatan transaksi, rincian dan penempatan kartu pembayaran atau rekening, dan/atau status dan negara kartu pembayaran atau rekening anda) dari penerbit kredensial pembayaran tersebut atau dari pihak ketiga lainnya.
                </p>
                <h5 className="h6">Informasi tentang pihak ketiga yang anda berikan kepada kami.</h5>
                <ul className="text-secondary">
                <li className="pb-2">Anda dapat memberikan kepada kami Informasi Pribadi yang berkaitan dengan individu pihak ketiga lainnya (termasuk Informasi Pribadi yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau individu lain). Anda tentu saja akan memerlukan persetujuan dari mereka terlebih dahulu untuk melakukannya - lihat "Pengakuan dan Persetujuan", di bawah, untuk informasi lebih lanjut.</li>
                <li className="pb-2">Kami tidak mewajibkan atau mendukung, ataupun melarang, pemasangan atau penggunaan alat perekam dalam kendaraan di kendaraan apa pun. Jika perangkat tersebut dipasang dan digunakan di kendaraan, kami tidak mengumpulkan Informasi Pribadi apapun dari rekaman dalam kendaraan oleh perangkat tersebut, dan pengumpulan Informasi Pribadi dari rekaman di dalam kendaraan tersebut tidak dilakukan atas nama kami. Pengumpulan Informasi Pribadi dari rekaman di dalam kendaraan tersebut merupakan diskresi dari penyedia layanan. Kami tidak memiliki kendali atas pengumpulan Informasi Pribadi tersebut oleh penyedia layanan dan setiap penggunaan atau pengungkapan oleh penyedia layanan dari Informasi Pribadi yang dikumpulkan. Jika anda adalah penyedia layanan dan memilih untuk mengunduh dan menggunakan perangkat rekaman di dalam kendaraan tersebut di kendaraan anda, anda bertanggung jawab sepenuhnya untuk memberitahukan pengguna. Jika anda adalah seorang pengguna dan anda keberatan atas penggunaan alat perekam dalam kendaraan penyedia layanan, anda harus menyampaikan hal tersebut kepada penyedia layanan secara langsung dan hal tersebut menjadi tanggung jawab pribadi anda.</li>
                <li className="pb-2">Jika anda menggunakan fitur pesan di Aplikasi kami, anda akan memberikan nomor telefon pengguna kami yang tersimpan di kontak telefon selular untuk membantu anda dalam menggunakan fitur pesan kami serta tujuan internal lainnya.</li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">2. PENGGUNAAN INFORMASI PRIBADI YANG KAMI KUMPULKAN</h3>
                </div>
                <p>
                    Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan sebagai berikut maupun untuk tujuan lain yang diizinkan oleh peraturan perundang-undangan yang berlaku ("Tujuan") :
                </p>
                <ul className="text-secondary">
                <li className="pb-2">
                    <p><span >Jika anda adalah pengguna, kami dapat menggunakan Informasi Pribadi anda:</span></p>
                    <p style={{marginLeft: 20}}><span >a.&nbsp;</span><span>untuk mengidentifikasi dan mendaftarkan anda sebagai pengguna dan untuk mengadministrasikan, memverifikasi, menonaktifkan, atau mengelola akun anda;</span></p>
                    <p style={{marginLeft: 20}}><span >b.&nbsp;</span><span>untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan kami diperlukan sebelum penyedia layanan memberikan layanan kepada anda atau sebelum kami mendaftarkan anda sebagai pengguna, termasuk melakukan proses Mengenal Pelanggan (Know Your Customer - KYC) dan penentuan kredit (credit scoring);</span></p>
                    <p style={{marginLeft: 20}}><span >c.&nbsp;</span><span >untuk memungkinkan penyedia layanan untuk menyediakan layanan yang anda minta;</span></p>
                    <p style={{marginLeft: 20}}><span >d.&nbsp;</span><span >untuk mengolah dan memfasilitasi pesanan dan transaksi pembayaran yang anda lakukan, termasuk sebagaimana berlaku, transaksi yang dilakukan melalui kartu pembayaran atau rekening yang tersedia pada Aplikasi;</span></p>
                    <p style={{marginLeft: 20}}><span >e.&nbsp;</span><span >untuk memberitahu anda atas transaksi atau aktivitas yang terjadi dalam Aplikasi atau sistem lain yang terhubung dengan Aplikasi kami;</span></p>
                    <p style={{marginLeft: 20}}><span >f.&nbsp;</span><span >untuk berkomunikasi dengan anda dan mengirimkan anda informasi sehubungan dengan penggunaan Aplikasi;</span></p>
                    <p style={{marginLeft: 20}}><span >g.&nbsp;</span><span >untuk memberitahu anda mengenai segala pembaruan pada Aplikasi atau perubahan pada layanan yang disediakan;</span></p>
                    <p style={{marginLeft: 20}}><span >h.&nbsp;</span><span >untuk mengolah dan menanggapi pertanyaan dan saran yang diterima dari anda;</span></p>
                    <p style={{marginLeft: 20}}><span >i.&nbsp;</span><span >untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan preferensi anda sebagai pengguna;</span></p>
                    <p style={{marginLeft: 20}}><span >j.&nbsp;</span><span >untuk memantau dan menganalisis aktivitas, perilaku, dan data demografis pengguna termasuk kebiasaan dan penggunaan berbagai layanan yang tersedia di Aplikasi;</span></p>
                    <p style={{marginLeft: 20}}><span >k.&nbsp;</span><span >untuk memproses dan mengelola reward points anda;</span></p>
                    <p style={{marginLeft: 20}}><span >l.&nbsp;</span><span >untuk menawarkan atau menyediakan layanan dari afiliasi atau mitra kami;</span></p>
                    <p style={{marginLeft: 20}}><span >m.&nbsp;</span><span >untuk mengirimkan anda komunikasi pemasaran, iklan, voucher, survei, dan informasi secara langsung atau terfokus, dan informasi tentang penawaran atau promosi khusus.</span></p>
                </li>
                <li className="pb-2">
                    <p><span >Jika anda adalah penyedia layanan, kami dapat menggunakan Informasi Pribadi anda:</span></p>
                    <p style={{marginLeft: 20}}><span >a.&nbsp;</span><span>untuk mengidentifikasi anda dan mendaftarkan anda sebagai penyedia layanan dan untuk mengadministrasi, mengelola atau memverifikasi Akun anda;</span></p>
                    <p style={{marginLeft: 20}}><span >b.&nbsp;</span><span>untuk memfasilitasi atau memungkinkan verifikasi apapun yang menurut pertimbangan kami mungkin perlu dilakukan sebelum kami dapat mendaftarkan anda sebagai penyedia layanan, termasuk untuk KYC (Know Your Customer) dan penilaian kredit (credit scoring);</span></p>
                    <p style={{marginLeft: 20}}><span >c.&nbsp;</span><span>untuk memungkinkan anda memberikan layanan kepada pengguna;</span></p>
                    <p style={{marginLeft: 20}}><span >d.&nbsp;</span><span>untuk mengolah, memfasilitasi, dan menyelesaikan pembayaran kepada anda terkait dengan layanan yang telah anda berikan;</span></p>
                    <p style={{marginLeft: 20}}><span >e.&nbsp;</span><span>untuk berkomunikasi dengan anda dan mengirimkan anda informasi terkait dengan penyediaan layanan anda, termasuk untuk menyampaikan pesanan pengguna kepada anda dan untuk memfasilitasi penerimaan anda atas pesanan tersebut;</span></p>
                    <p style={{marginLeft: 20}}><span >f.&nbsp;</span><span>untuk memberikan anda pemberitahuan dan pembaruan pada Aplikasi atau perubahan cara penyediaan layanan;</span></p>
                    <p style={{marginLeft: 20}}><span >g.&nbsp;</span><span>untuk memberi anda laporan terkait dengan layanan yang telah anda sediakan;</span></p>
                    <p style={{marginLeft: 20}}><span >h.&nbsp;</span><span>untuk memproses dan menanggapi saran dari pengguna terhadap layanan yang telah anda berikan;</span></p>
                    <p style={{marginLeft: 20}}><span >i.&nbsp;</span><span>untuk memelihara, mengembangkan, menguji, meningkatkan, dan mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan preferensi anda sebagai penyedia layanan;</span></p>
                    <p style={{marginLeft: 20}}><span >j.&nbsp;</span><span>untuk memantau dan menganalisis aktivitas, perilaku, dan data demografis pengguna termasuk kebiasaan dan daya tanggap penyedia layanan untuk berbagai layanan yang tersedia pada Aplikasi;</span></p>
                    <p style={{marginLeft: 20}}><span >k.&nbsp;</span><span>untuk memproses dan mengelola reward points anda;</span></p>
                    <p style={{marginLeft: 20}}><span >l.&nbsp;</span><span>untuk menawarkan atau menyediakan layanan dari afiliasi atau mitra kami</span></p>
                    <p style={{marginLeft: 20}}><span >m.&nbsp;</span><span>untuk mengirimkan anda komunikasi pemasaran, iklan, promo, survei, dan penawaran khusus atau promosi secara langsung atau tertuju</span></p>
                </li>
                <li className="pb-2">
                    <p><span >Baik anda merupakan pengguna ataupun penyedia layanan atau pihak yang memberikan Informasi Pribadi kepada kami, kami juga dapat menggunakan Informasi Pribadi anda secara lebih umum untuk tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu kami akan bertindak secara wajar dan tidak menggunakan Informasi Pribadi anda lebih dari apa yang diperlukan untuk tujuan tersebut)::</span></p>
                    <p style={{marginLeft: 20}}><span >a.&nbsp;</span><span>untuk melakukan proses dan fungsi bisnis terkait;</span></p>
                    <p style={{marginLeft: 20}}><span >b.&nbsp;</span><span>untuk memantau penggunaan Aplikasi dan mengelola, mendukung serta meningkatkan efisiensi kinerja, perkembangan, pengalaman pengguna dan fungsi-fungsi Aplikasi;</span></p>
                    <p style={{marginLeft: 20}}><span >c.&nbsp;</span><span>untuk memberikan bantuan sehubungan dengan dan untuk menyelesaikan kesulitan teknis atau masalah operasional dengan Aplikasi atau layanan;</span></p>
                    <p style={{marginLeft: 20}}><span >d.&nbsp;</span><span>untuk menghasilkan informasi statistik dan data analitik untuk tujuan pengujian, penelitian, analisis, pengembangan produk, kemitraan komersial, dan kerja sama;</span></p>
                    <p style={{marginLeft: 20}}><span >e.&nbsp;</span><span>untuk mencegah, mendeteksi dan menyelidiki segala kegiatan yang dilarang, ilegal, tidak sah, atau curang;</span></p>
                    <p style={{marginLeft: 20}}><span >f.&nbsp;</span><span>untuk memfasilitasi transaksi aset bisnis (yang dapat berupa penggabungan, akuisisi, atau penjualan aset) yang melibatkan kami dan/atau afiliasi kami;</span></p>
                    <p style={{marginLeft: 20}}><span >g.&nbsp;</span><span>untuk memungkinkan kami mematuhi semua kewajiban berdasarkan peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas pada menanggapi permintaan, penyelidikan, atau arahan peraturan, mematuhi persyaratan pengarsipan, pelaporan, dan perizinan yang disyaratkan oleh peraturan yang berlaku) dan melakukan pemeriksaan audit, uji tuntas dan penyelidikan.</span></p>
                </li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">3. PEMBERIAN INFORMASI PRIBADI YANG KAMI KUMPULKAN</h3>
                </div>
                <ul className="text-secondary">
                <li className="pb-2">
                    <p><span >Kami dapat mengungkapkan atau membagikan Informasi Pribadi anda dengan afiliasi dan pihak lain untuk tujuan sebagai berikut ini serta untuk tujuan lain yang diizinkan oleh peraturan perundang-undangan yang berlaku:</span></p>
                    <p style={{marginLeft: 20}}><span >a.&nbsp;</span><span>jika anda adalah pengguna, untuk memungkinkan penyedia layanan, untuk melakukan atau memberikan layanan;</span></p>
                    <p style={{marginLeft: 20}}><span >b.&nbsp;</span><span>jika anda adalah penyedia layanan, untuk memungkinkan pengguna untuk meminta atau menerima layanan dari anda;</span></p>
                    <p style={{marginLeft: 20}}><span >c.&nbsp;</span><span>jika disyaratkan atau diotorisasikan oleh peraturan perundang-undangan yang berlaku (termasuk namun tidak terbatas pada menanggapi pertanyaan terkait regulasi, penyelidikan atau pedoman, atau mematuhi persyaratan atau ketentuan pengarsipan dan pelaporan berdasarkan undang-undang), untuk tujuan yang ditentukan dalam peraturan perundang-undangan yang berlaku;</span></p>
                    <p style={{marginLeft: 20}}><span >d.&nbsp;</span><span>jika diinstruksikan, diminta, disyaratkan atau diperbolehkan oleh pemerintah yang berwenang, untuk tujuan sebagaimana disebutkan dalam kebijakan pemerintah, peraturan atau peraturan perundang-undangan yang berlaku.</span></p>
                    <p style={{marginLeft: 20}}><span >e.&nbsp;</span><span>jika terdapat proses hukum dalam bentuk apapun antara anda dengan kami, atau antara anda dengan pihak lain, sehubungan dengan, atau terkait dengan layanan, untuk keperluan proses hukum tersebut;</span></p>
                    <p style={{marginLeft: 20}}><span >f.&nbsp;</span><span>sehubungan dengan segala proses verifikasi kami atau pihak ketiga perlukan sebelum pemberi layanan memberikan layanan kepada anda atau kami mendaftarkan anda sebagai pengguna, termasuk proses KYC (Know Your Customer) dan penentuan kredit (credit scoring);</span></p>
                    <p style={{marginLeft: 20}}><span >g.&nbsp;</span><span>dalam keadaan darurat terkait keselamatan anda (baik anda adalah pengguna atau penyedia layanan) untuk keperluan menangani keadaan darurat tersebut;</span></p>
                    <p style={{marginLeft: 20}}><span >h.&nbsp;</span><span>dalam situasi terkait kesehatan anda atau kepentingan umum (baik anda adalah pengguna atau penyedia layanan), kami dapat membagikan Informasi Pribadi anda kepada pemerintah yang berwenang dan/atau institusi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan kami, untuk tujuan pelacakan kontak, mendukung inisiatif, kebijakan atau program pemerintah, kesehatan public dan tujuan lainnya sebagaimana dibutuhkan secara wajar;</span></p>
                    <p style={{marginLeft: 20}}><span >i.&nbsp;</span><span>sehubungan dengan, penggabungan, penjualan aset perusahaan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi semua atau sebagian dari bisnis kami oleh atau ke perusahaan lain, untuk keperluan transaksi tersebut (bahkan jika kemudian transaksi tidak dilanjutkan);</span></p>
                    <p style={{marginLeft: 20}}><span >j.&nbsp;</span><span>sehubungan dengan proses klaim asuransi, kami akan membagikan Informasi Pribadi anda untuk tujuan pemrosesan klaim asuransi kepada perusahaan asuransi yang bekerja sama atau berkolaborasi dengan kami;</span></p>
                    <p style={{marginLeft: 20}}><span >k.&nbsp;</span><span>kepada pihak ketiga (termasuk agen, vendor, pemasok, kontraktor, mitra, dan pihak lain yang memberikan layanan kepada kami atau anda, melakukan tugas atas nama kami, atau pihak dengan siapa kami mengadakan kerja sama komersial), untuk atau sehubungan dengan tujuan di mana pihak ketiga tersebut terlibat, untuk melaksanakan pengungkapan kepada pihak ketiga terkait yang secara teknis diperlukan untuk memproses transaksi anda atau tujuan kerja sama kami dengan pihak ketiga tersebut (tergantung keadaannya), yang dapat mencakup diperbolehkannya pihak ketiga tersebut untuk memperkenalkan atau menawarkan produk atau layanan kepada anda, melakukan autentikasi terhadap anda atau menghubungkan anda dengan Akun atau melakukan kegiatan lain termasuk pemasaran, penelitian, analisis dan pengembangan produk;</span></p>
                    <p style={{marginLeft: 20}}><span >l.&nbsp;</span><span>dalam hal kami berbagi Informasi Pribadi dengan afiliasi, kami akan melakukannya dengan maksud agar mereka membantu kami dalam menyediakan Aplikasi, untuk mengoperasikan bisnis kami (termasuk, ketika anda berlangganan milis kami, untuk tujuan pemasaran langsung), atau untuk tujuan pengolahan data atas nama kami. Misalnya, sebuah afiliasi Gojek di negara lain dapat mengolah dan/atau menyimpan Informasi Pribadi anda atas nama perusahaan grup Gojek di negara anda. Semua afiliasi kami berkomitmen untuk mengolah Informasi Pribadi yang mereka terima dari kami sesuai dengan Kebijakan Privasi dan peraturan perundang-undangan yang berlaku ini.</span></p>
                </li>
                <li className="pb-2">Ketika Informasi Pribadi tidak perlu dikaitkan dengan anda, kami akan melakukan upaya yang wajar untuk menghapus dikaitkannya Informasi Pribadi tersebut dengan anda sebagai individu sebelum mengungkapkan atau berbagi informasi tersebut.</li>
                <li className="pb-2">Kami tidak akan menjual atau menyewakan Informasi Pribadi anda.</li>
                <li className="pb-2">Selain sebagaimana diatur dalam Kebijakan Privasi ini, kami dapat mengungkapkan dan membagikan Informasi Pribadi anda jika kami memberitahu anda dan kami telah memperoleh persetujuan anda untuk pengungkapan atau pembagian tersebut.</li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">4. PENYIMPANAN INFORMASI PRIBADI</h3>
                </div>
                <ul className="text-secondary">
                <li className="pb-2">Informasi Pribadi anda hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari pengumpulannya, atau selama penyimpanan tersebut diperlukan atau diperbolehkan oleh peraturan perundang-undangan yang berlaku. Kami akan berhenti menyimpan Informasi Pribadi, atau menghapus maksud dari dikaitkannya Informasi Pribadi tersebut dengan anda sebagai individu, segera setelah dianggap bahwa tujuan pengumpulan Informasi Pribadi tersebut tidak lagi dibutuhkan dengan menyimpan Informasi Pribadi dan penyimpanan tidak lagi diperlukan untuk tujuan bisnis atau secara hukum.</li>
                <li className="pb-2">Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Informasi Pribadi anda disimpan oleh pihak lain dengan termasuk institusi pemerintah cara tertentu. Dalam hal kami membagikan Informasi Pribadi anda kepada institusi pemerintah yang berwenang dan/atau institusi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan kami, anda menyetujui dan mengakui bahwa penyimpanan Informasi Pribadi anda oleh institusi terkait akan mengikuti kebijakan penyimpanan data masing-masing institusi tersebut. Informasi yang disampaikan melalui komunikasi antara pengguna dan penyedia layanan yang dilakukan selain melalui penggunaan Aplikasi (seperti melalui panggilan telepon, SMS, pesan seluler atau cara komunikasi lainnya dan pengumpulan atas Informasi Pribadi anda oleh agen kami) juga dapat disimpan dengan beberapa cara. Kami tidak mengizinkan penyimpanan Informasi Pribadi dengan cara demikian dan kami tidak bertanggung jawab kepada anda untuk hal tersebut. Kami tidak akan bertanggung jawab atas penyimpanan Informasi Pribadi anda. Anda setuju untuk mengganti rugi, membela, dan membebaskan kami, pejabat, direktur, karyawan, agen, mitra, pemasok, kontraktor, dan afiliasi kami dari dan terhadap setiap dan segala klaim, kerugian, kewajiban, biaya, kerusakan, dan ongkos (termasuk tetapi tidak terbatas pada biaya hukum dan pengeluaran biaya ganti rugi penuh) yang dihasilkan secara langsung atau tidak langsung dari setiap penyimpanan yang tidak sah atas Informasi Pribadi anda.</li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">5. AKSES DAN KOREKSI INFORMASI PRIBADI</h3>
                </div>
                <ul className="text-secondary">
                <li className="pb-2">Tunduk pada peraturan perundang-undangan yang berlaku, anda dapat meminta kepada kami untuk mengakses dan/atau mengoreksi Informasi Pribadi anda yang berada dalam kepemilikan dan penguasaan kami, dengan menghubungi kami di perincian yang disediakan di bawah ini.</li>
                <li className="pb-2">Kami berhak menolak permintaan anda untuk mengakses, atau untuk memperbaiki, sebagian atau semua Informasi Pribadi anda yang kami miliki atau kuasai jika diizinkan atau diperlukan berdasarkan perundang-undangan yang berlaku. Hal ini termasuk dalam keadaan di mana Informasi Pribadi tersebut dapat berisi referensi kepada orang lain atau di mana permintaan untuk akses atau permintaan untuk mengoreksi adalah untuk alasan yang kami anggap tidak relevan, tidak serius, atau menyulitkan.</li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">6. TEMPAT KAMI MENYIMPAN INFORMASI PRIBADI ANDA</h3>
                </div>
                <ul className="text-secondary">
                <li className="pb-2">Saat anda menggunakan Aplikasi dan layanan kami di negara lain dimana Aplikasi kami dapat diakses ("Negara Tujuan"), kami dapat mentransfer Informasi Pribadi anda dari negara asal ("Negara Asal") ke Negara Tujuan untuk membuat anda dapat mengakses dan memastikan pengalaman pelanggan yang lancar ketiga Anda menggunakan Aplikasi kami di luar negeri. Dalam hal tersebut, kami akan meminta persetujuan anda untuk mentransfer Data Pribadi anda dari Negara Asal ke Negara Tujuan untuk memastikan kepatuhan atas hukum dan peraturan yang berlaku.</li>
                <li className="pb-2">Informasi Pribadi dari anda yang kami kumpulkan dapat disimpan, ditransfer, atau diolah oleh penyedia layanan pihak ketiga. Kami akan menggunakan semua upaya yang wajar untuk memastikan bahwa semua penyedia layanan pihak ketiga tersebut memberikan tingkat perlindungan yang sebanding dengan komitmen kami berdasarkan Kebijakan Privasi ini.</li>
                <li className="pb-2">Kami akan mematuhi seluruh peraturan dan hukum yang berlaku dan menggunakan seluruh usaha yang terbaik untuk memastikan Afiliasi kami di negara lain dan seluruh pihak ketiga pemberi layanan memberikan tingkat perlindungan yang setara dengan komitmen kami dalam Kebijakan Privasi ini.</li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">7. TEMPAT KAMI MENYIMPAN INFORMASI PRIBADI ANDA</h3>
                </div>
                <p>
                    Kerahasiaan Informasi Pribadi anda adalah hal yang terpenting bagi kami. Kami akan memberlakukan upaya terbaik untuk melindungi dan mengamankan data dan Informasi Pribadi anda dari akses, pengumpulan, penggunaan atau pengungkapan oleh orang-orang yang tidak berwenang dan dari pengolahan yang bertentangan dengan hukum, kehilangan yang tidak disengaja, pemusnahan dan kerusakan atau risiko serupa. Namun, pengiriman informasi melalui internet tidak sepenuhnya aman. Walau kami akan berusaha sebaik mungkin untuk melindungi Informasi Pribadi anda, anda mengakui bahwa kami tidak dapat menjamin keutuhan dan keakuratan Informasi Pribadi apa pun yang anda kirimkan melalui Internet, atau menjamin bahwa Informasi Pribadi tersebut tidak akan dicegat, diakses, diungkapkan, diubah atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor-faktor di luar kendali kami. Anda bertanggung jawab untuk menjaga kerahasiaan detail Akun anda, termasuk kata sandi anda dan One Time Password (OTP) dengan siapapun dan harus selalu menjaga dan bertanggung jawab atas keamanan perangkat yang anda gunakan.
                </p>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">8. PERUBAHAN ATAS KEBIJAKAN PRIVASI INI</h3>
                </div>
                <p>
                    Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas kebijakan kami sendiri dari waktu ke waktu, untuk memastikan bahwa Kebijakan Privasi ini konsisten dengan perkembangan kami di masa depan, dan/atau perubahan persyaratan hukum atau peraturan. Jika kami memutuskan untuk mengubah Kebijakan Privasi ini, kami akan memberitahu anda tentang perubahan tersebut melalui pemberitahuan umum yang dipublikasikan pada Aplikasi dan/atau situs web, atau sebaliknya ke alamat e-mail anda yang tercantum dalam Akun anda. Anda setuju bahwa anda bertanggung jawab untuk meninjau Kebijakan Privasi ini secara teratur untuk informasi terbaru tentang pengolahan data dan praktik perlindungan data kami, dan bahwa dengan anda terus menggunakan Aplikasi atau situs web kami, berkomunikasi dengan kami, atau mengakses dan menggunakan layanan setelah adanya perubahan apapun terhadap Kebijakan Privasi ini akan dianggap sebagai persetujuan anda terhadap Kebijakan Privasi ini dan segala perubahannya.
                </p>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">9. PENGAKUAN DAN PERSETUJUAN</h3>
                </div>
                <ul className="text-secondary">
                <li className="pb-2">Dengan menyetujui Kebijakan Privasi, anda mengakui bahwa anda telah membaca dan memahami Kebijakan Privasi ini dan menyetujui segala ketentuannya. Secara khusus, anda setuju dan memberikan persetujuan kepada kami untuk mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah Informasi Pribadi anda sesuai dengan Kebijakan Privasi ini.</li>
                <li className="pb-2">Dalam keadaan di mana anda memberikan kepada kami Informasi Pribadi yang berkaitan dengan individu lain (seperti Informasi Pribadi yang berkaitan dengan pasangan anda, anggota keluarga, teman, atau pihak lain), anda menyatakan dan menjamin bahwa anda telah memperoleh persetujuan dari individu tersebut untuk, dan dengan ini menyetujui atas nama individu tersebut untuk, pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi Pribadi mereka oleh kami.</li>
                <li className="pb-2">Anda dapat menarik persetujuan anda untuk setiap atau segala pengumpulan, penggunaan atau pengungkapan Informasi Pribadi anda kapan saja dengan memberikan kepada kami pemberitahuan yang wajar secara tertulis menggunakan rincian kontak yang disebutkan di bawah ini. Anda juga dapat menarik persetujuan pengiriman komunikasi dan informasi tertentu dari kami melalui fasilitas "opt-out" atau pilihan "berhenti berlangganan" yang tersedia dalam pesan kami kepada anda. Tergantung pada keadaan dan sifat persetujuan yang anda tarik, anda harus memahami dan mengakui bahwa setelah penarikan persetujuan tersebut, anda mungkin tidak lagi dapat menggunakan Aplikasi atau layanan. Penarikan persetujuan anda dapat mengakibatkan penghentian Akun anda atau hubungan kontraktual anda dengan kami, dengan semua hak dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah menerima pemberitahuan untuk menarik persetujuan untuk pengumpulan, penggunaan atau pengungkapan Informasi Pribadi anda, kami akan menginformasikan anda tentang konsekuensi yang mungkin terjadi dari penarikan tersebut sehingga anda dapat memutuskan apakah anda tetap ingin menarik persetujuan.</li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">10. MATERI PEMASARAN DAN PROMOSI</h3>
                </div>
                <p>
                    Kami dan mitra kami dapat mengirimkan anda pemasaran langsung, iklan, dan komunikasi promosi melalui aplikasi push-notification, pesan melalui Aplikasi, pos, panggilan telepon, layanan pesan singkat (SMS), Platform Chat, Media Sosial, dan email ("Materi Pemasaran") jika anda telah setuju untuk berlangganan milis kami, dan/atau setuju untuk menerima materi pemasaran dan promosi dari kami. Anda dapat memilih untuk tidak menerima komunikasi pemasaran tersebut kapan saja dengan mengklik pilihan "berhenti berlangganan" yang ada dalam pesan yang bersangkutan, atau menghubungi kami melalui detail kontak yang tercantum di bawah ini. Mohon perhatikan bahwa jika anda memilih untuk keluar, kami masih dapat mengirimi anda pesan-pesan non-promosi, seperti tanda terima atau informasi tentang Akun anda.
                </p>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">11. DATA ANONIM</h3>
                </div>
                <p>
                    Kami dapat membuat, menggunakan, melisensikan atau mengungkapkan Informasi Pribadi, yang tersedia, dengan catatan, (i) bahwa semua hal yang dapat mengidentifikasi telah dihapus sehingga data, baik sendiri atau dikombinasi dengan data lain yang tersedia, tidak dapat dihubungkan dengan atau dikaitkan dengan atau tidak dapat mengidentifikasi suatu individu, dan (ii) data serupa telah digabungkan sehingga data asli membentuk bagian dari kumpulan data yang lebih besar.
                </p>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">12. PLATFORM PIHAK KETIGA</h3>
                </div>
                <ul className="text-secondary">
                <li className="pb-2">Aplikasi, situs web, dan Materi Pemasaran dapat berisi tautan ke situs web yang dioperasikan oleh pihak ketiga. Kami tidak mengendalikan atau menerima pertanggungjawaban atau tanggung jawab untuk situs web ini dan untuk pengumpulan, penggunaan, pemeliharaan, berbagi, atau pengungkapan data dan informasi oleh pihak ketiga tersebut. Silakan baca syarat dan ketentuan dan kebijakan privasi dari situs web pihak ketiga tersebut untuk mengetahui bagaimana mereka mengumpulkan dan menggunakan Informasi Pribadi anda.</li>
                <li className="pb-2">Saat anda menggunakan Aplikasi kami dan mengaktifkan fitur sidik jari (fingerprint)dan/atau pengenalan wajah (facial recognition) di perangkat seluler anda untuk keperluan autentikasi, harap dicatat bahwa kami tidak menyimpan data biometrik tersebut. Kecuali diberitahukan lain kepada anda, data tersebut tersimpan di perangkat seluler anda dan dapat disimpan oleh pihak ketiga, seperti pembuat perangkat anda. Anda setuju dan mengakui bahwa kami tidak bertanggung jawab atas setiap akses tidak sah atau kerugian apapun atas data biometrik yang tersimpan di perangkat seluler anda.</li>
                <li className="pb-2">Iklan yang terdapat pada Aplikasi, situs web, dan Materi Pemasaran kami berfungsi sebagai tautan ke situs web pengiklan dan dengan demikian segala informasi yang mereka kumpulkan berdasarkan klik anda pada tautan itu akan dikumpulkan dan digunakan oleh pengiklan yang relevan sesuai dengan kebijakan privasi pengiklan tersebut.</li>
                </ul>
            </div>
            <div id="privacy" className="mb-4">
                <div className="mb-3">
                <h3 className="h5 ">13. CARA UNTUK MENGHUBUNGI KAMI</h3>
                </div>
                <p>
                    Jika anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau anda ingin mendapatkan akses silahkan hubungi office.tokytoky@gmail.com
                </p>
                <p>
                    Untuk koreksi atau pembaharuan terhadap Informasi Pribadi anda, harap menghubungi tokytokyofficial@gmail.com.
                </p>
                <p>
                    Untuk pertanyaan atau keluhan lainnya, anda dapat menghubungi kami melalui email atau telefon melalui kontak berikut:
                </p>
                <ul className="text-secondary">
                <li className="pb-2"><strong>Untuk Layanan Konsumen: tokytokyofficial@gmail.com</strong></li>
                <li className="pb-2"><strong>Untuk Layanan Home Delivery: tokytokyofficial@gmail.com</strong></li>
                <li className="pb-2"><strong>Untuk Layanan Driver: office.tokytoky@gmail.com Call Center</strong></li>
                <li className="pb-2"><strong>Untuk Layanan Konsumen: (0853) 2345 1500</strong></li>
                <li className="pb-2"><strong>Untuk Layanan Driver: HOMEDELIVERY (0821) 2222 8789</strong></li>

                </ul>
            </div>

      </div>

    </div>
  </div>
</div>


   <Mobile />
   <Footer />
  <Menu />



            </div>
        );
    }
}
export default Privacy;
