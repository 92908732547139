import axios from 'axios'

export const getList = token => {
    return axios
        .get('https://api.tokytoky.id/api/products', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const getPaket = token => {
    return axios
        .get('https://api.tokytoky.id/api/pakets', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const addToList = product => {
  const data = new FormData()
        data.append('img', product.img)
        data.append('nm_produk', product.nm_produk)
        data.append('harga', product.harga)
        data.append('desc', product.desc)
        data.append('paket', product.paket)
        data.append('cdn', product.cdn)
   return axios
    .post(
      'https://api.tokytoky.id/api/product',
      data,
      {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':product.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}

export const deleteProduct = (product, token) => {
  return axios
    .delete(`https://api.tokytoky.id/api/product/${product}`, {
      headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
    .then(function(response) {
        console.log(response)
        return response;
    })
    .catch(function(error) {
      console.log(error)
      return error;
    })
}

export const updateProduct = productUpdateRequest => {
   const data = new FormData()
        data.append('img', productUpdateRequest.img)
        data.append('nm_produk', productUpdateRequest.nm_produk)
        data.append('harga', productUpdateRequest.harga)
        data.append('desc', productUpdateRequest.desc)
        data.append('paket', productUpdateRequest.paket)
        data.append('cdn', productUpdateRequest.cdn)
  return axios
    .put(
      `https://api.tokytoky.id/api/product/${productUpdateRequest.id}`,
      data,
      {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': productUpdateRequest.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}
