import React from 'react';
import Header from '../part/header';
import Footer from '../part/footer';
import Menu from '../part/sidebar';
import Mobile from '../part/mobile';

class Delivery extends React.Component
{

    render()
    {
        return (
            <div>
             <Header />
<div>
<div className="osahan-profile">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Delivery Support</h4>
    </div>
  </div>



    <div className="container position-relative">
           <div className="pt-4 pb-5 title d-flex align-items-center">

      <div className="col-md-12 mb-3">
  <div className="rounded shadow-sm">
    <div className="osahan-privacy bg-white rounded shadow-sm p-4">
      <div id="intro" className="mb-4">
        <div className="mb-4">
          <center><h2 className="h5">Delivery Support</h2></center>
        </div>
        <p>
            Untuk saat ini, Pihak Kami menyediakan 2 layanan utama untuk memudahkan Anda :
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">1. Delivery.</h6>
        </div>
        <p>
          Untuk memaksimalkan efisiensi waktu serta kualitas produk, Kami menggunakan layanan PIHAK KETIGA ("Pihak penyedia pengantaran") untuk melakukan pengantaran sebagaimana yang terjabarkan melalui ketentuan penggunaan layanan kami. Setiap biaya dalam pengantaran disesuaikan dengan titik lokasi pengantaran lokasi anda dari outlet terdekat, Jumlah, jarak yang di atur oleh pihak ketiga. Untuk menggunakan layanan ini, Anda diwajibkan untuk menyelesaikan pembayaran terlebih dahulu sesuai dengan jumlah pemesanan di laman Tokytoky.id
        </p>

        <div id="information" className="mb-3 active">
        <h6 className="h6">2. Pickup.</h6>
        </div>
        <p>
          Dengan menggunakan Layanan ini, kami dapat membantu Anda untuk bebas antri. Pihak Kami akan menyiapkan seluruh pesanan Anda sesuai dengan waktu yang Anda tentukan tanpa biaya tambahan. Selanjutnya, untuk pembayaran dan Pengambilan produk bisa dilakukan di outlet kami yang telah Anda pilih sebelumnya.
        </p>

      </div>
    </div>
  </div>
</div>
</div>




         </div>
  </div>
   <Mobile />
   <Footer />
  <Menu />
</div>




            </div>
        );
    }
}
export default Delivery;
