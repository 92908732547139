import React from 'react';
import Header from '../part/header';
import Footer from '../part/footer';
import Menu from '../part/sidebar';
import Mobile from '../part/mobile';

class Privacy extends React.Component {
  constructor() {
    super()
    this.state = {
      urlpembayaran: "",
    }
  }
 
getAllrecent = async () => {
  let recent
    if (localStorage.getItem('pembayaran')) {
      recent = localStorage.getItem('pembayaran');
      const myArr = recent.split("/web/");
      if (myArr[0] === "https://checkout-staging.xendit.co" || myArr[0] === "https://checkout.xendit.co") {
        this.setState({ urlpembayaran: recent })
      } else {
               window.location.href = "/";
      }
    } else {
       window.location.href = "/";
    }
}
  componentDidMount() {
    this.getAllrecent()
  }
  render() {
    return (
      <div>
        <Header />
        <div>
          <div className="osahan-profile">
            <div className="d-none">
              <div className="bg-primary border-bottom p-3 d-flex align-items-center">
                <a className="toggle togglew toggle-2" href="/#"><span /></a>
                <h4 className="font-weight-bold m-0 text-white">Pembayaran</h4>
              </div>
            </div>

            <div className="container position-relative">
              <div className="pt-4 pb-5 title d-flex align-items-center">
                <div className="col-md-12 mb-3">
                  <iframe src={this.state.urlpembayaran} style={{ border: "none", height: "1000px" }} width="100%" title="metode pembayaran"></iframe>
                </div>
              </div>

            </div>
          </div>
          <Mobile />
          <Footer />
          <Menu />
        </div>




      </div>
    );
  }
}
export default Privacy;