import React from 'react';
import Header from '../part/header';
import Footer from '../part/footer';
import Menu from '../part/sidebar';
import Mobile from '../part/mobile';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import CounterInput from 'react-bootstrap-personalized-counter';
import { getOutlet, findPromo, addTransaksi } from './HomeListFunctions'
import FormValidator from '../pages/admin/FormValidator'
import { isMobile } from "react-device-detect";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const mapStyles = {
  height: '300px'
};
const GOOGLE_API_KEY = 'AIzaSyBPQ7PC_2JN_9jNw4z3N-PIsOtJF6HY-Hs';


class Cart extends React.Component {
  constructor() {
    super()

    this.validator = new FormValidator([
      {
        field: 'nama_customer',
        method: 'isEmpty',
        validWhen: false,
        message: 'Nama tidak boleh kosong'
      },
      {
        field: 'no_tlp',
        method: 'isEmpty',
        validWhen: false,
        message: 'Nomor Telp/WA tidak boleh kosong'
      },
      {
        field: 'metode_pengambilan',
        method: 'isEmpty',
        validWhen: false,
        message: 'Metode pengambilan harus di pilih salah satu'
      },
      {
        field: 'outlet_terdekat',
        method: 'isEmpty',
        validWhen: false,
        message: 'Pilih outlet terdekat harus di pilih salah satu'
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email tidak boleh kosong'
      },
      // {
      //   field: 'alamat',
      //   method: 'isEmpty',
      //   validWhen: false,
      //   message: 'Alamat tidak boleh kosong'
      // }
    ]);


    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      recentproducts: [],
      outlets: [],
      kd_promo: '',
      kd_promo_check: '',
      total_product: '0',
      total_item: '0',
      delivery_fee: '0',
      nama_customer: '',
      no_tlp: '',
      metode_pengambilan: '',
      outlet_terdekat: '',
      email: '',
      alamat: '',
      noted: '',
      total_pembayaram: '0',
      apply_promo: '0',
      latitude: null,
      longitude: null,
      userAddress: null,
      isUpdate: false,
      isAlert: false,
      alertsuccess: false,
      alertfailed: false,
      isNext: true,
      isPay: false,
      isTotal: false,
      validation: this.validator.valid()
    };
    this.onSubmit = this.onSubmit.bind(this)
    this.getLocation = this.getLocation.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
    this.reverseGeocodeCoordinate = this.reverseGeocodeCoordinate.bind(this);
    this.onChange = this.onChange.bind(this)


  };


  componentDidMount() {
    this.getAllrecent()
    this.getLocation()
    this.getAllOutlet()


  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser");
    }
  }

  getCoordinates(position) {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    });
    this.reverseGeocodeCoordinate()
  }

  reverseGeocodeCoordinate() {
    const { latitude, longitude } = this.state;

    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${GOOGLE_API_KEY}`)
      .then(response => response.json())
      .then(data => this.setState({ userAddress: data.results[0].formatted_address }))
      .catch(error => alert(error))
  }

  handleLocationError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for location');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out');
        break;
      case error.UNKNOWN_ERROR:
        alert('An ubkwon error ocurred');
        break;
      default:
        alert('An unknow error ocurred');
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    if (e.target.name === 'metode_pengambilan') {
      if (document.getElementById("metode_pengambilan").value === '1') {
        this.setState({
          delivery_fee: '8000',
          total_pembayaram: parseInt(this.state.total_pembayaram) + parseInt(8000)
        })

      } else if (document.getElementById("metode_pengambilan").value === '0' && this.state.delivery_fee === '8000') {
        this.setState({
          delivery_fee: '0',
          total_pembayaram: parseInt(this.state.total_pembayaram) - parseInt(8000)
        })
      } else {
        this.setState({
          delivery_fee: '0',
          total_pembayaram: parseInt(this.state.total_pembayaram)
        })
      }
    }


    let profile = [];
    localStorage.removeItem('profile');
    if (this.state.nama_customer !== '') {
      profile.push({ 'nama_customer': document.getElementById("nama_customer").value, 'no_tlp': document.getElementById("no_tlp").value, 'email': document.getElementById("email").value, 'alamat': document.getElementById("alamat").value });
      localStorage.setItem('profile', JSON.stringify(profile));
    }

  }




  onFindpromo = async () => {
    const promo = {
      id: this.state.kd_promo_check
    }
    findPromo(promo).then((res) => {
      if (res.link !== undefined) {

        this.setState(
          {
            apply_promo: res.link,
            kd_promo: this.state.kd_promo_check,
            alertsuccess: true,
            alertfailed: false,
            total_pembayaram: parseInt(this.state.total_product) + parseInt(this.state.delivery_fee) - res.link
          }

        )

      } else {
        this.setState(
          {
            alertfailed: true,
            alertsuccess: false,
            kd_promo: '-',
            apply_promo: '0',
            total_pembayaram: parseInt(this.state.total_product) + parseInt(this.state.delivery_fee)

          }
        )
      }
    }).catch(err => {
      // this.setState({ errorMessage:err.data.message })
    })
  }

  onclosefindpromo = async () => {
    document.getElementById('gagal').style.display = 'none';
    document.getElementById('sukses').style.display = 'none';

    this.setState(
      {

        alertsuccess: false,
        alertfailed: false,
      }

    )
  }

  onclosetotal = async () => {
    document.getElementById('istotal').style.display = 'none';

    this.setState(
      {

        isTotal: false,
      }

    )
  }

  getAllOutlet = async () => {
    getOutlet().then(data => {
      this.setState(
        {
          outlets: [...data]
        },
        () => {
        }
      )

    })
  }


  onCartPlus(id, values) {
    if (values === 0) {

      const swalWithBootstrapButtons = MySwal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: 'Apakah Anda Yakin',
        text: "Apakah Anda Ingin Menghapus Pesanan Ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let recent = [];
          if (localStorage.getItem('recent')) {
            recent = JSON.parse(localStorage.getItem('recent'));
          }
          var index = recent.map(x => {
            return x.productId;
          }).indexOf(id);

          recent.splice(index, 1);
          localStorage.setItem('recent', JSON.stringify(recent));
          let count = localStorage.getItem('count');
          if (count == null) {
            count = 0;
          }
          var add = parseInt(count) - 1
          localStorage.setItem('count', add);
          this.forceUpdate();
          this.getAllrecent();
          this.forceUpdate();
          let dat = JSON.parse(localStorage.getItem('count'));
          if (dat === 0) {
            this.setState({
              isUpdate: false
            })
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // this.forceUpdate();
          // this.getAllrecent();
          let profile = [];
          localStorage.removeItem('profile');
          profile.push({ 'nama_customer': this.state.nama_customer, 'no_tlp': this.state.no_tlp, 'email': this.state.email, 'alamat': this.state.alamat });
          localStorage.setItem('profile', JSON.stringify(profile));

          window.location.reload();
        }
      })

    } else {
      let data = JSON.parse(localStorage.getItem('recent'));
      let perbaharuitotal = [];
      data = data.map((value) => {
        // check if this is the value to be edited
        if (value.productId === id) {
          perbaharuitotal.push(value.harga * values);
          // return the updated value
          return {
            ...value,

            qty: values
          }
        } else {
          perbaharuitotal.push(value.harga * value.qty);

        }
        // otherwise return the original value without editing
        return value;
      })
      let totals = perbaharuitotal.reduce((val, nilaiSekarang) => {
        return val + nilaiSekarang
      }, 0)
      this.setState(
        {
          total_product: totals,
          total_pembayaram: totals + parseInt(this.state.delivery_fee) - parseInt(this.state.apply_promo)

        }

      )

      localStorage.setItem('recent', JSON.stringify(data));
      let dat = JSON.parse(localStorage.getItem('count'));
      if (dat === 0) {
        this.setState({
          isUpdate: false
        })
      }
      this.forceUpdate();
      this.getAllrecent();

    }
  }



  getAllrecent = async () => {
    var prod = JSON.parse(localStorage.getItem('recent'));
    var profile = JSON.parse(localStorage.getItem('profile'));
    if (profile !== null) {
      this.setState(
        {
          nama_customer: profile[0]['nama_customer'],
          email: profile[0]['email'],
          no_tlp: profile[0]['no_tlp'],
          alamat: profile[0]['alamat']
        }
      )
    }
    if (prod === null) {
      prod = [];
    }
    this.setState(
      {
        recentproducts: [...prod]
      },
      () => {
        console.log(this.state.recentproducts)
      }
    )

    let data = JSON.parse(localStorage.getItem('recent'));
    let count = JSON.parse(localStorage.getItem('count'));
    let perbaharuitotal = [];
    if (data !== null && count !== 0) {
      this.setState({
        isUpdate: true
      })
      data.map((value) => {
        // check if this is the value to be edited

        perbaharuitotal.push(value.harga * value.qty);

        // otherwise return the original value without editing
        return value;
      })
    }
    let totals = perbaharuitotal.reduce((val, nilaiSekarang) => {
      return val + nilaiSekarang
    }, 0)
    this.setState(
      {
        total_product: totals,
        total_pembayaram: totals + parseInt(this.state.delivery_fee) - parseInt(this.state.apply_promo)
      }

    )

  }

  onSubmit = e => {
    e.preventDefault()
    const validation = this.validator.validate(this.state);

    this.setState({ validation });

    let recent = [];
    if (localStorage.getItem('recent')) {
      recent = JSON.parse(localStorage.getItem('recent'));
    }
    if (validation.isValid) {
      var device
      if (isMobile) {
        device = 'mobile'
      } else {
        device = 'desktop'
      }

      if (parseInt(this.state.total_pembayaram) > 8000) {

        this.setState({
          isNext: false,
          isPay: true
        })


        let data = JSON.parse(localStorage.getItem('recent'));
        var total_prod_item = 0;
        for (var i = 0; i < data.length; i++) {

          total_prod_item += data[i].qty
        }
        const transaksiRequest = {
          name: this.state.nama_customer,
          no_tlp: this.state.no_tlp,
          long: this.state.longitude,
          lat: this.state.latitude,
          email: this.state.email,
          alamat: this.state.alamat,
          outlet_terdekat: this.state.outlet_terdekat,
          metode_pengambilan: this.state.metode_pengambilan,
          total_product: this.state.total_product,
          total_pembayaran: this.state.total_pembayaram,
          apply_promo: this.state.apply_promo,
          kd_promo: this.state.kd_promo,
          device: device,
          noted: this.state.noted,
          total_item: total_prod_item,
          order: recent
        }

        // addTransaksi(transaksiRequest).then((response) => {
        //   let profile = [];
        //   localStorage.removeItem('profile');
        //   profile.push({ 'nama_customer': this.state.nama_customer, 'no_tlp': this.state.no_tlp, 'email': this.state.email });
        //   localStorage.setItem('profile', JSON.stringify(profile));
        //   localStorage.removeItem("recent");
        //   localStorage.removeItem("count");
        //   localStorage.setItem('pembayaran', response);
        //   window.location.href = "/pembayaran";
        // }).catch(err => {
        //   this.setState({ editDisabled: false, errorMessage: err.message })
        // })
        addTransaksi(transaksiRequest).then((response) => {
          localStorage.clear();
          window.location.href = response;
        }).catch(err=>{
            this.setState({ editDisabled: false, errorMessage:err.message })
        })
        this.setState({ editDisabled: false })
      } else {
        this.setState({ isTotal: true })

      }
    }
  }

  render() {
    const { latitude, longitude, userAddress } = this.state;
    const formatRupiah = (money) => {
      return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
      ).format(money);
    }

    return (
      <div>
        <Header />
        <div className="osahan-checkout">
          <div className="d-none">
            <div className="bg-primary border-bottom p-3 d-flex align-items-center">
              <a className="toggle togglew toggle-2" href="/#"><span /></a>
              <h4 className="font-weight-bold m-0 text-white">Checkout</h4>
            </div>
          </div>
          <div className="container position-relative">
            <div className="py-5 row">
              <div className="col-md-7 mb-3">
                <div>
                  <div className="accordion mb-3 rounded shadow-sm bg-white overflow-hidden" id="accordionExample">
                    <div className="osahan-card bg-white border-bottom overflow-hidden">
                      <div className="osahan-card-header" id="headingOne">
                        <h2 className="mb-0">
                          <button className="d-flex p-3 align-items-center btn btn-link w-100" type="button" data-toggle="collapse" data-target="/#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <i className="feather-user mr-3" /> Informasi Customer
                          </button>
                        </h2>
                      </div>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="/#accordionExample">
                        <div className="osahan-card-body border-top p-3">
                          <form>
                            <div className="form-row">

                              <div className="col-md-6 form-group">
                                <label className="form-label font-weight-bold small">Nama</label>
                                <input placeholder="Masukan Nama Lengkap" autoComplete="off" id="nama_customer" name="nama_customer" value={this.state.nama_customer} onChange={this.onChange} type="text" className="form-control" />
                                <span className="help-block">{this.state.validation.nama_customer.message}</span>
                              </div>
                              <div className="col-md-6 form-group">
                                <label className="form-label font-weight-bold small">No Telepon / Whatsapp</label>
                                <input placeholder="08322xxx" name="no_tlp" id="no_tlp" value={this.state.no_tlp} onChange={this.onChange} type="number" className="form-control" />
                                <span className="help-block">{this.state.validation.no_tlp.message}</span>
                              </div>
                              <div className="col-md-6 form-group">
                                <label className="form-label font-weight-bold small">Metode Pengambilan Pesanan</label>

                                <select className="form-control" name="metode_pengambilan" id="metode_pengambilan" value={this.state.metode_pengambilan} onChange={this.onChange}>
                                  <option selected={true} disabled={true} value="">Pilih</option>
                                  <option value="0"> Ambil Sendiri</option>
                                  <option disabled={true} value="">Delivery ( cooming soon ) </option>
                                  {/* <option value="1">Delivery</option> */}

                                </select>
                                <span className="help-block">{this.state.validation.metode_pengambilan.message}</span>
                              </div>
                              <div className="col-md-6 form-group">
                                <label className="form-label font-weight-bold small">Pilih Outlet Terdekat Anda</label>
                                <select className="form-control" name="outlet_terdekat" value={this.state.outlet_terdekat} onChange={this.onChange}>
                                  <option selected={true} disabled={true} value="">Pilih</option>
                                  {this.state.outlets.map((outletlist, index) => (
                                    <option value={outletlist.id}>{outletlist.nama}</option>
                                  ))}
                                </select>
                                <span className="help-block">{this.state.validation.outlet_terdekat.message}</span>
                              </div>
                              <div className="col-md-12 form-group">
                                <label className="form-label font-weight-bold small">Email</label>
                                <input type="text" autoComplete="off" name="email" id="email" value={this.state.email} onChange={this.onChange} className="form-control" />
                                <span className="help-block">{this.state.validation.email.message}</span>
                              </div>

                              {/* <div className="col-md-12 form-group">
                                <label className="form-label font-weight-bold small">Alamat</label>
                                <textarea className="form-control" name="alamat" id="alamat" value={this.state.alamat} onChange={this.onChange}></textarea>
                                <span className="help-block">{this.state.validation.alamat.message}</span>
                              </div> */}
                              {/* <div className="col-md-12 form-group" style={mapStyles} ><label className="form-label font-weight-bold small">Live Location</label>
                                <p>{userAddress}</p>
                                <Map centerAroundCurrentLocation google={this.props.google} style={{ width: '100%', height: '100%', position: 'relative' }}
                                  className={'map'} zoom={30}>
                                  <Marker position={{ lat: latitude, lng: longitude }} name={'Live Location'} />
                                </Map>
                              </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="osahan-cart-item rounded rounded shadow-sm overflow-hidden bg-white sticky_sidebar">
                  <div className="d-flex border-bottom osahan-cart-item-profile bg-white ">
                    <button className="d-flex p-3 align-items-center btn btn-link w-100" type="button" data-toggle="collapse" data-target="/#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <i className="feather-shopping-cart h6 mr-2 mb-0" /> Informasi Pesanan
                    </button>
                  </div>
                  <div className="bg-white border-bottom">

                    {this.state.recentproducts.map((recentproduct, index) => (

                      <div className="row gold-members d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
                        <div className="media align-items-center col-md-7" style={{ width: "50%" }}>
                          <div className="mr-2 text-danger">·</div>
                          <div className="media-body">
                            <p className="m-0">{recentproduct.nama}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center col-md-5" style={{ width: "50%" }}>
                          <CounterInput className="count-number-input" readonly="readonly" value={recentproduct.qty} min={0} max={50} glyphPlus={{ glyph: 'fa fa-plus', position: 'left' }} glyphMinus={{ glyph: 'fa fa-minus', position: 'right' }} onChange={(value) => { this.onCartPlus(recentproduct.productId, value) }} />
                          <p className="text-gray mb-0 float-right ml-2 text-muted small">{formatRupiah(recentproduct.harga * recentproduct.qty)} </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bg-white p-3 py-3 border-bottom clearfix">
                    <div style={this.state.alertsuccess ? {} : { display: 'none' }} id="sukses" className="alert alert-success" role="alert">
                      <button type="button" className="close" onClick={this.onclosefindpromo.bind(this)} style={{ fontSize: '12px' }}>x</button>
                      <strong>Success! </strong> Promo Sukses Terpasang.
                    </div>
                    <div style={this.state.alertfailed ? {} : { display: 'none' }} id="gagal" className="alert alert-danger" role="alert">
                      <button type="button" className="close" onClick={this.onclosefindpromo.bind(this)} style={{ fontSize: '12px' }}>x</button>
                      <strong>Gagal! </strong> Promo Tidak Ditemukan.
                    </div>

                    <div className="input-group-sm mb-2 input-group">
                      <input placeholder="Masukan Kode Promo" type="text" className="form-control"
                        value={this.state.kd_promo_check}
                        name="kd_promo_check"
                        onChange={this.onChange}
                      />
                      <div className="input-group-append"><button type="button" onClick={this.onFindpromo.bind(this)} className="btn btn-primary"><i className="feather-percent" /> APPLY</button></div>
                    </div>
                    <div className="mb-0 input-group">
                      <div className="input-group-prepend"><span className="input-group-text"><i className="feather-message-square" /></span></div>
                      <textarea placeholder="Keterangan pesanan anda" aria-label="With textarea" name="noted" value={this.state.noted} onChange={this.onChange} className="form-control" />
                    </div>
                  </div>
                  <div className="bg-white p-3 clearfix border-bottom">
                    <p className="mb-1">Item Total <span className="float-right text-dark">{formatRupiah(this.state.total_product)} </span></p>
                    <p className="mb-1">Ongkir<span className="text-info ml-1"><i className="feather-info" /></span><span className="float-right text-dark">{formatRupiah(this.state.delivery_fee)}</span></p>
                    <p className="mb-1 text-success">Total Discount<span className="float-right text-success">{this.state.kd_promo} / {formatRupiah(this.state.apply_promo)}</span></p>
                    <hr />
                    <h6 className="font-weight-bold mb-2">Total Pembayaran <span className="float-right">{formatRupiah(this.state.total_pembayaram)}</span></h6>

                    <div style={this.state.isTotal ? {} : { display: 'none' }} id="istotal" className="alert alert-danger mb-0" role="alert">
                      <button type="button" className="close" onClick={this.onclosetotal.bind(this)} style={{ fontSize: '12px' }}>x</button>
                      <strong>Gagal! </strong> Maaf total pembayaran anda dibawah 10.000
                    </div>
                  </div>
                  <div className="p-3">
                    <a className="btn btn-success btn-block btn-lg" style={this.state.isUpdate ? {} : { display: 'none' }} onClick={this.onSubmit.bind(this)} href="/#"> Selesaikan Pembayaran <i className="feather-arrow-right" style={this.state.isNext ? {} : { display: 'none' }} /> <i class="fa fa-spinner fa-spin" style={this.state.isPay ? {} : { display: 'none' }} ></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Mobile />
        <Footer />
        <Menu />


      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBPQ7PC_2JN_9jNw4z3N-PIsOtJF6HY-Hs'
})(Cart);
