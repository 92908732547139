import React, { Component } from 'react'
import Header from '../../part/header';
import Footer from '../../part/footer';
import Menu from '../../part/sidebar';
import Mobile from '../../part/mobile';
import FormValidator from '../admin/FormValidator'
import { getList, addToList, updateItem } from './UserFunctions'


// import { NavLink, useHistory, withRouter, Link } from 'react-router-dom';


class User extends Component {
  constructor() {
    super()


    this.validator = new FormValidator([
        { 
          field: 'name', 
          method: 'isEmpty', 
          validWhen: false, 
          message: 'name is required' 
        },
        { 
          field: 'password', 
          method: 'isEmpty', 
          validWhen: false, 
          message: 'Password is required' 
        },
        { 
          field: 'email',
          method: 'isEmpty', 
          validWhen: false, 
          message: 'email is required'
        }
      ]);

    this.state = {
      id: '',
      name: '',
      email: '',
      password: '',
      createdAt:'',
      isUpdate : false,
      errorMessage:'',
      items: [],
      validation: this.validator.valid(),
      }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onCreate = this.onCreate.bind(this)
   
  }

 

  componentDidMount() {
    const token = localStorage.usertoken;
    this.getAll(token)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onCreate(e) {
    e.preventDefault()
    const token = localStorage.usertoken;
    this.setState({
      id: '',
      name: '',
      email: '',
      password: '',
      createdAt:'',
      isUpdate : false,
      errorMessage:'',
      items: [],
      validation: this.validator.valid(),
    });
    this.getAll(token)
  }


  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear();
  }
  

  getAll = token => {
    getList(token).then(data => {
        if(data.message === 'Token expired !'){
            localStorage.removeItem('usertoken')
            this.props.history.push(`/login`)
        }else{
            this.setState(
                {
                    user: '',
                    items: [...data]
                },
                () => {
                    console.log(this.state.items)
                }
            )
        }
    })
    }
    
    
  onSubmit = e => {
    e.preventDefault()
    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
        const token = localStorage.usertoken;

        const userRequest = {
            token: token,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password

        }

        addToList(userRequest).then(() => {
            this.getAll(token)
        }).catch(err=>{
            this.setState({ editDisabled: false, errorMessage:err.message })
        })
        this.setState({ editDisabled: false })
    }
  }
  onUpdate = e => {
    e.preventDefault()
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (validation.isValid) {
        const token = localStorage.usertoken;
        const userUpdateRequest = {
            id: this.state.id,
            token: token,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password

        }
        updateItem(userUpdateRequest).then(() => {
        this.getAll(token)
        }).catch(err=>{
            this.setState({ editDisabled: false, isUpdate:false, errorMessage:err.message })
        })
    }
    this.setState({ editDisabled: false, isUpdate:false,status: 0 })
  }

  onEdit = (item_id, name, email, e) => {
    e.preventDefault()
    this.setState({
      id: item_id,
      name: name,
      email:email,
      errorMessage:'',
      isUpdate:true,
      validation: this.validator.valid(),
    })
  }
  



  render() {
    
  
      return (
<div>
<Header />
<div className="osahan-profile">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Pengaturan Akun</h4>
    </div>
  </div>
  <div className="container position-relative">
    <div className="py-5 osahan-profile row">
      <div className="col-md-12 mb-3">
        <div className="osahan-cart-item-profile">
          <div className="box bg-white mb-3 shadow-sm rounded">
            <div className="p-3 d-flex align-items-center">
              <div className="ml-3">
                <h6 className="text-dark mb-2 mt-0">Daftar User Manajemen Resto</h6>
                <p className="mb-0 text-muted">berikut ini list daftar User Manajemen Resto
                </p>
              </div>
            </div>
                        
          <div className="overflow-hidden border-top p-3 d-flex align-items-center">
              <button className="btn btn-success" data-toggle="modal" data-target="#paycard">
                Tambah
              </button>
          </div>
          <div className=" d-flex align-items-center p-3">
          <table className="table">
          <thead>
            <tr>
              <th>Nama</th>
              <th>Email</th>
              <th>Created Date</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.items.map((item, index) => (
              <tr key={index}>
              <td className="text-left">{item.name}</td>
                <td className="text-left">{item.email}</td>
                <td className="text-left">{this.formatDate(new Date(item.createdAt))}</td>
                <td className="text-right">
                  <button
                    className="btn btn-info mr-1"
                    data-toggle="modal" data-target="#paycard"
                    disabled={this.state.editDisabled}
                    onClick={this.onEdit.bind(this, item.id, item.name, item.email)}
                  >
                    Edit
                  </button>
                   
                </td>
              </tr>
            ))}
          </tbody>
        </table>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>

              
<div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Tambah User </h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
       <form onSubmit={this.onSubmit}>
        <div className="form-group">
            <label htmlFor="name">Nama</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.name}
                  name="name"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.name.message}</span>
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="email">Email</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.email}
                  name="email"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.email.message}</span>
              </div>
            </div>
      </div>
        <div className="form-group">
            <label htmlFor="alamat">Password</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="password"
                  className="form-control"
                  value={this.state.password}
                  name="password"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.password.message}</span>
              </div>
            </div>
        </div>                           
        
        <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close" onClick={this.onUpdate.bind(this)} 
             style={this.state.isUpdate ? {} : { display: 'none' }} 
        >
            Update
        </button>
        <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
            style={this.state.isUpdate ? {display: 'none' } : {  }}
        >
            Submit
        </button>
         
        </form>
      </div>
       
    </div>
  </div>
</div>


   <Mobile />
   <Footer />     
  <Menu /> 
 </div>
    );
  }
}

  export default User;
