import React, { Component } from 'react'
import Header from '../../part/header';
import Footer from '../../part/footer';
import Menu from '../../part/sidebar';
import Mobile from '../../part/mobile';
import FormValidator from '../admin/FormValidator'
import { getList, addToList, deleteBanner, updateBanner } from './BannerListFunctions'


class Banner extends Component {
  constructor() {
    super()


    this.validator = new FormValidator([
        { 
          field: 'title', 
          method: 'isEmpty', 
          validWhen: false, 
          message: 'Title title is required' 
        },
        { 
          field: 'kd_promo', 
          method: 'isEmpty', 
          validWhen: false, 
          message: 'kode promo title is required' 
        },
        
        { 
          field: 'link',
          method: 'isEmpty', 
          validWhen: false, 
          message: 'link is required'
        }
      ]);

    this.state = {
      id: '',
      title: '',
      link: '',
      kd_promo: '',
      img: null,
      createdAt:'',
      isUpdate : false,
      errorMessage:'',
      banners: [],
      validation: this.validator.valid(),
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onCreate = this.onCreate.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
        this.setState({
            img: event.target.files[0],
          })
    }

  componentDidMount() {
    const token = localStorage.usertoken;
    this.getAll(token)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onCreate(e) {
    e.preventDefault()
    const token = localStorage.usertoken;
    this.setState({
      id: '',
      title: '',
      link: '',
      kd_promo: '',
      img: null,
      createdAt:'',
      isUpdate : false,
      errorMessage:'',
      banners: [],
      cdn:'',
      validation: this.validator.valid(),
    });
    this.getAll(token)
  }

 


  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear();
  }
  

  getAll = token => {
    getList(token).then(data => {
        if(data.message === 'Token expired !'){
            localStorage.removeItem('usertoken')
            this.props.history.push(`/login`)
        }else{
            this.setState(
                {
                    banner: '',
                    banners: [...data]
                },
                () => {
                    // console.log(this.state.items)
                }
            )
        }
    })
  }

  onSubmit = e => {
    e.preventDefault()
    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
      const token = localStorage.usertoken;
      const data = new FormData();
      data.append("file", this.state.img);
      data.append("upload_preset", "tokytoky");
      data.append("cloud_name", "syiham-group");
      fetch("https://api.cloudinary.com/v1_1/syiham-group/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          const bannerRequest = {
            token: token,
            title: this.state.title,
            link: this.state.link,
            kd_promo: this.state.kd_promo,
            img: this.state.img,
            cdn: data.secure_url.toString()
        }

        addToList(bannerRequest).then(() => {
            this.getAll(token)
        }).catch(err=>{
            this.setState({ editDisabled: false, errorMessage:err.message })
        })
        })
        .catch((err) => {
          console.log(err);
        });
      
      this.setState({ editDisabled: false })
  }
  }

  onUpdate = e => {
    e.preventDefault()
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (validation.isValid) {
      const token = localStorage.usertoken;
      const data = new FormData();
      data.append("file", this.state.img);
      data.append("upload_preset", "tokytoky");
      data.append("cloud_name", "syiham-group");
      fetch("https://api.cloudinary.com/v1_1/syiham-group/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
            const bannerUpdateRequest = {
              id: this.state.id,
              token: token,
              title: this.state.title,
              link: this.state.link,
              kd_promo: this.state.kd_promo,
              img: this.state.img,
              cdn: data.secure_url.toString()
          }
          updateBanner(bannerUpdateRequest).then(() => {
          this.getAll(token)
          }).catch(err=>{
              this.setState({ editDisabled: false, isUpdate:false, errorMessage:err.message })
          })
        })
        .catch((err) => {
          console.log(err);
          const bannerUpdateRequest = {
            id: this.state.id,
            token: token,
            title: this.state.title,
            link: this.state.link,
            kd_promo: this.state.kd_promo,
            img: this.state.img,
            cdn: ''
        }
        updateBanner(bannerUpdateRequest).then(() => {
        this.getAll(token)
        }).catch(err=>{
            this.setState({ editDisabled: false, isUpdate:false, errorMessage:err.message })
        })
        });

  }
    this.setState({ editDisabled: false, isUpdate:false,status: 0 })
  }

  onEdit = (item_id, title, link, kd_promo, e) => {
    e.preventDefault()
    this.setState({
      id: item_id,
      title: title,
      link:link,
      kd_promo:kd_promo,
      errorMessage: '',
      isUpdate:true,
      validation: this.validator.valid(),
    })
  }

  onDelete = (val, e) => {
    e.preventDefault()
    const token = localStorage.usertoken;
    deleteBanner(val, token).then((res) => {
        if(res.data.status === 'failed'){
            this.setState({ errorMessage:res.data.message })
        }
        this.getAll(token);
    }).catch(err=>{
        this.setState({ errorMessage:err.data.message })
    })
  }
  render() {
    
  
      return (
<div>
<Header />
<div className="osahan-profile">
 <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Manage Banner</h4>
    </div>
  </div>
  <div className="container position-relative">
    <div className="py-5 osahan-profile row">
      <div className="col-md-12 mb-3">
        <div className="osahan-cart-item-profile">
          <div className="box bg-white mb-3 shadow-sm rounded">
            <div className="p-3 d-flex align-items-center">
              <div className="ml-3">
                <h6 className="text-dark mb-2 mt-0">Daftar Banner</h6>
                <p className="mb-0 text-muted">berikut ini list daftar banner / promo
                </p>
              </div>
            </div>
                        
          <div className="overflow-hidden border-top p-3 d-flex align-items-center">
              <button className="btn btn-success" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}>
                        Tambah
              </button>
          </div>
          <div className=" d-flex align-items-center p-3">
          <table className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Kode Promo</th>
              <th>Diskon</th>
              <th>Foto</th>
              <th>Created Date</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.banners.map((banner, index) => (
              <tr key={index}>
                <td className="text-left">{banner.title}</td>
                <td className="text-left">{banner.kd_promo}</td>
                <td className="text-left">{banner.link}</td>
                <td className="text-left" style={{width: "200px"}}> <img alt="#" src={banner.img} width="50%" /> </td>
                <td className="text-left">{this.formatDate(new Date(banner.createdAt))}</td>
                <td className="text-right">
                  <button
                    className="btn btn-info mr-1"
                    data-toggle="modal" data-target="#paycard"
                    disabled={this.state.editDisabled}
                    onClick={this.onEdit.bind(this, banner.id, banner.title, banner.link, banner.kd_promo)}
                  >
                    Edit
                  </button>
                  <button
                    href=""
                    className="btn btn-danger"
                    onClick={this.onDelete.bind(this, banner.id)}
                    style={this.state.isUpdate ? {display: 'none' } : {  }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Tambah Banner</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
       <form onSubmit={this.onSubmit}>
        <div className="form-group">
            <label htmlFor="title">Title</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.title}
                  name="title"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.title.message}</span>
              </div>
            </div>
            </div>
        <div className="form-group">
            <label htmlFor="kd_promo">Kode Promo</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.kd_promo}
                  name="kd_promo"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.kd_promo.message}</span>
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="link">Diskon</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.link}
                  name="link"
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.link.message}</span>
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="img">Image file</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="file"
                  className="form-control"
                  name="img"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
        </div>
       
        <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close" onClick={this.onUpdate.bind(this)} 
             style={this.state.isUpdate ? {} : { display: 'none' }} 
        >
            Update
        </button>
        <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
            style={this.state.isUpdate ? {display: 'none' } : {  }}
        >
            Submit
        </button>
        <button onClick={this.onCreate.bind(this)} className="btn btn-info btn-block" data-dismiss="modal" aria-label="Close"
            style={this.state.isUpdate ? {} : { display: 'none' }} 
        >
            Create New
        </button>
        </form>
      </div>
       
    </div>
  </div>
</div>


   <Mobile />
   <Footer />     
  <Menu /> 
 </div>
    );
  }
}

  export default Banner;
