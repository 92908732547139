import axios from 'axios'

export const getPaket = async () => {
    return axios
        .get('https://api.tokytoky.id/api/pakets/customer', {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const findPromo = promo => {
    return axios
        .get(`https://api.tokytoky.id/api/promo/${promo.id}`, {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const addTransaksi = transaksi => {
  return axios
    .post(
      'https://api.tokytoky.id/api/proses/transaksi',
      {
        nama: transaksi.name,
        no_tlp: transaksi.no_tlp,
        long: transaksi.long,
        lat: transaksi.lat,
        email: transaksi.email,
        alamat: transaksi.alamat,
        outlet_terdekat: transaksi.outlet_terdekat,
        metode_pengambilan: transaksi.metode_pengambilan,
        total_product: transaksi.total_product,
        total_pembayaran: transaksi.total_pembayaran,
        apply_promo: transaksi.apply_promo,
        kd_promo: transaksi.kd_promo,
        order: transaksi.order,
        total_item: transaksi.total_item,
        noted: transaksi.noted,
        device: transaksi.device

      },
      {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}

export const getBanner = async () => {
    return axios
        .get('https://api.tokytoky.id/api/banners/customer', {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const getOutlet = async () => {
    return axios
        .get('https://api.tokytoky.id/api/outlets/customer', {
        headers: {
            'Content-Type': 'application/json'
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const getProduct = async () => {
    return axios
        .get('https://api.tokytoky.id/api/products/customer', {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}


export const getFilter = (cari)  => {
    return axios
        .get(`https://api.tokytoky.id/api/products/customer/paket/${cari}`, {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}
