import axios from 'axios'

export const getList = token => {
    return axios
        .get('https://api.tokytoky.id/api/order/laporan', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const getLaporan = token => {
    return axios
        .get('https://api.tokytoky.id/api/order/export', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

