import React, { Component } from 'react'
import { login } from '../admin/UserFunctions'
import FormValidator from '../admin/FormValidator'

class Login extends Component {
    constructor() {
    super()

    this.validator = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email is required.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'That is not a valid email.'
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is required.'
      }
    ]);


    this.state = {
      email: '',
      password: '',
      errors: {},
      validation: this.validator.valid(),
    }

    this.onChange = this.onChange.bind(this)
    this.submitted = false;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  componentDidMount() {
      localStorage.clear();
  }

  handleFormSubmit = event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      // handle actual form submission here
      const user = {
        email: this.state.email,
        password: this.state.password
      }

      login(user).then(res => {
        if (res) {
          // this.props.history.push(`/profile`)
          // this.history.pushState(null, '/');
          // this.props.history.push(`/`)
          window.location.href = '/';

        }
      })
    }
  }
  render() {
    return (
<div>
  <div className="login-page vh-100">
    <video loop autoPlay muted id="vid">
      <source src="assets/img/bg.mp4" type="video/mp4" />
      <source src="assets/img/bg.mp4" type="video/ogg" />
      Your browser does not support the video tag.
    </video>
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="px-5 col-md-6 ml-auto">
        <div className="px-5 col-10 mx-auto">
          <h2 className="text-dark my-0">Welcome Back</h2>
          <p className="text-50">Sign in to continue</p>
          <form className="mt-5 mb-4" action="https://askbootstrap.com/preview/swiggiweb/verification.html">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="text-dark">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1" aria-describedby="emailHelp"
                  name="email"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.email.message}</span>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1" className="text-dark">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <span className="help-block">{this.state.validation.password.message}</span>
            </div>
             <button
                type="submit"
                onClick={this.handleFormSubmit}
                className="btn btn-primary btn-lg btn-block"
              >SIGN IN</button>

          </form>

        </div>
      </div>
    </div>
  </div>

</div>

        );
    }
}
export default Login;
