import React, { Component } from 'react'
import Header from '../../part/header';
import Footer from '../../part/footer';
import Menu from '../../part/sidebar';
import Mobile from '../../part/mobile';
import { getList } from './CustomerListFunctions'


// import { NavLink, useHistory, withRouter, Link } from 'react-router-dom';


class Outlet extends Component {
  constructor() {
    super()

    this.state = {
      id: '',
      nama: '',
      alamat: '',
      nomor: '',
      email: '',
      status_pembayaran: '',
      status_order: '',
      long: '',
      lat: '',
      createdAt:'',
      isUpdate : false,
      errorMessage:'',
      items: [],
      searchString: ''
    }
    this.onChange = this.onChange.bind(this)
  }



  componentDidMount() {
    const token = localStorage.usertoken;
    this.getAll(token)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }



  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear();
  }


  getAll = token => {
    getList(token).then(data => {
        if(data.message === 'Token expired !'){
            localStorage.removeItem('usertoken')
            this.props.history.push(`/login`)
        }else{
            this.setState(
                {
                    outlet: '',
                    items: [...data]
                },
                () => {
                    console.log(this.state.items)
                }
            )
        }
    })
  }


  onEdit = (item_id, nama, alamat, nomor, email,  long, lat, e) => {
    e.preventDefault()
    this.setState({
      id: item_id,
      nama: nama,
      alamat: alamat,
      nomor: item_id,
      email: email,
      long: long,
      lat: lat,
      errorMessage:'',
      isUpdate:true,
    })
  }

  render() {
    const filterData = this.state.searchString ? this.state.items.filter(row => row.id.indexOf(this.state.searchString) > -1) : this.state.items;


      return (
<div>
<Header />
<div className="osahan-profile">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Laporan Customer</h4>
    </div>
  </div>
  <div className="container position-relative">
    <div className="py-5 osahan-profile row">
      <div className="col-md-12 mb-3">
        <div className="osahan-cart-item-profile">
          <div className="box bg-white mb-3 shadow-sm rounded">
            <div className="p-3 d-flex align-items-center">
              <div className="ml-3">
                <h6 className="text-dark mb-2 mt-0">Daftar Laporan Customer Resto</h6>
                <p className="mb-0 text-muted">berikut ini list daftar customer Resto
                </p>
               <br>
               </br>
                <input
                  type="text"
                  style={{width:'200px'}}
                  className="form-control"
                  value={this.state.searchString}
                  name="searchString"
                  placeholder="Cari berdasarkan Nomor "
                  onChange={this.onChange}
              />
              </div>
            </div>


          <div className=" d-flex align-items-center p-3">
          <table className="table">
          <thead>
            <tr>
              <th>Nama</th>
              <th>Nomor</th>
              <th>Alamat</th>
              <th>Created Date</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((item, index) => (
              <tr key={index}>
              <td className="text-left">{item.nama}</td>
                <td className="text-left">{item.id}</td>
                <td className="text-left">{item.alamat}</td>
                <td className="text-left">{this.formatDate(new Date(item.createdAt))}</td>
                <td className="text-right">
                  <button
                    className="btn btn-info mr-1"
                    data-toggle="modal" data-target="#paycard"
                    disabled={this.state.editDisabled}
                    onClick={this.onEdit.bind(this, item.id, item.nama, item.alamat, item.nomor, item.email, item.long, item.lat)}
                  >
                    Detail
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Detail Customer</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
       <form onSubmit={this.onSubmit}>
        <div className="form-group">
            <label htmlFor="nama">Nama</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  readonly
                  disabled
                  value={this.state.nama}
                  name="nama"
                  onChange={this.onChange}
                />
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="nomor">Nomor Tlp</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  readonly
                  disabled
                  value={this.state.nomor}
                  name="nomor"
                  onChange={this.onChange}
                />
              </div>
            </div>
        </div>

        <div className="form-group">
            <label htmlFor="email">Email</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  readonly
                  disabled
                  value={this.state.email}
                  name="email"
                  onChange={this.onChange}
                />
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="alamat">Alamat</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  readonly
                  disabled
                  value={this.state.alamat}
                  name="alamat"
                  onChange={this.onChange}
                />
              </div>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="long">Longitude</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  readonly
                  disabled
                  value={this.state.long}
                  name="long"
                  onChange={this.onChange}
                />
              </div>
            </div>
        </div>
         <div className="form-group">
            <label htmlFor="lat">Latitude</label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  readonly
                  disabled
                  value={this.state.lat}
                  name="lat"
                  onChange={this.onChange}
                />
              </div>
            </div>
        </div>

        </form>
      </div>

    </div>
  </div>
</div>


   <Mobile />
   <Footer />
  <Menu />
 </div>
    );
  }
}

  export default Outlet;
