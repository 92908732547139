import axios from 'axios'

export const getList = token => {
    return axios
        .get('https://api.tokytoky.id/api/banners', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const addToList = banner => {
  const data = new FormData()
        data.append('img', banner.img)
        data.append('title', banner.title)
        data.append('link', banner.link)
        data.append('kd_promo', banner.kd_promo)
        data.append('cdn', banner.cdn)

   return axios
    .post(
      'https://api.tokytoky.id/api/banner',
      data,
      {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':banner.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}

export const deleteBanner = (banner, token) => {
  return axios
    .delete(`https://api.tokytoky.id/api/banner/${banner}`, {
      headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
    .then(function(response) {
        console.log(response)
        return response;
    })
    .catch(function(error) {
      console.log(error)
      return error;
    })
}

export const updateBanner = bannerUpdateRequest => {
   const data = new FormData()
         data.append('img', bannerUpdateRequest.img)
         data.append('title', bannerUpdateRequest.title)
         data.append('link', bannerUpdateRequest.link)
         data.append('kd_promo', bannerUpdateRequest.kd_promo)
         data.append('cdn', bannerUpdateRequest.cdn)

   return axios
    .put(
      `https://api.tokytoky.id/api/banner/${bannerUpdateRequest.id}`,
      data,
      {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': bannerUpdateRequest.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}
