import React, { Component } from 'react'
import Header from '../../part/header';
import Footer from '../../part/footer';
import Menu from '../../part/sidebar';
import Mobile from '../../part/mobile';
import { getList, getLaporan } from './LaporanListFunctions'
import * as XLSX from 'xlsx';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

// import Pdf from "react-to-pdf";

// import { NavLink, useHistory, withRouter, Link } from 'react-router-dom';
// const ref = React.createRef();
// const options = {
//   orientation: 'landscape',
//   unit: 'in',
//   format: [10,10]
// };

class Laporan extends Component {
  constructor() {
    super()

    this.state = {
      id: '',
      status_pembayaran: '',
      status_order: '',
      pengambilan: '',
      total_product: '',
      total_pembayaran: '',
      total_diskon: '',
      kode_promo: '',
      nama_outlet: '',
      nama_customer: '',
      no_customer: '',
      createdAt:'',
      isUpdate : false,
      errorMessage:'',
      items: [],
      orders: [],
      searchString: '',
      export:[],
      searchDate: '',

    }
    this.onChange = this.onChange.bind(this)
  }



  componentDidMount() {
    const token = localStorage.usertoken;
    this.getAll(token)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onXlsx()
  {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(this.state.items);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "laporanpenjualan.xlsx");
  }
 
  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear();
  }

  getStatuspembayaran(statusCode) {
    const status = ['Pending','Success','Gagal'];
    return status[statusCode];
  }

  getStatusorder(statusCode) {
    const status = ['Pending','Masuk','Diproses','Siap','Cancel'];
    return status[statusCode];
  }
  getStatuspengambilan(statusCode) {
    const status = ['Ambil Sendiri','Delivery'];
    return status[statusCode];
  }

  getAll = token => {
    getList(token).then(data => {
        if(data.message === 'Token expired !'){
            localStorage.removeItem('usertoken')
            this.props.history.push(`/login`)
        }else{
            this.setState(
                {
                    items: [...data]
                },
                () => {
                    console.log(this.state.items)
                }
            )
        }
    })
    getLaporan(token).then(lap => {
      if(lap.message === 'Token expired !'){
          localStorage.removeItem('usertoken')
          this.props.history.push(`/login`)
      }else{
          this.setState(
              {
                export: [...lap]
              },
              () => {
                  console.log(this.state.export)
              }
          )
      }
  })
  }

   filtertrans = (id_trx) =>
  {
  return this.state.export.filter(two => two.id_trx === id_trx ).length
  }
  filterdiskon = (dis, id_trx) =>
  {
  return this.state.export.filter(two => two.total_diskon === dis && two.id_trx === id_trx ).length
  }

  filterresto = (resto, id_trx) =>
  {
  return this.state.export.filter(two => two.nama === resto && two.id_trx === id_trx ).length
  }
  filterjumlah = (total_pembayaran, id_trx) =>
  {
  return this.state.export.filter(two => two.total_pembayaran === total_pembayaran && two.id_trx === id_trx ).length
  }

  onEdit = (item_id, status_pembayaran, status_order, pengambilan, total_product,  total_pembayaran, total_diskon, kode_promo, nama_outlet, nama_customer, no_customer, data, noted,   e) => {
    e.preventDefault()
    this.setState({
      id: item_id,
      status_pembayaran: status_pembayaran,
      status_order: status_order,
      pengambilan: pengambilan,
      total_product: total_product,
      total_pembayaran: total_pembayaran,
      total_diskon: total_diskon,
      kode_promo: kode_promo,
      nama_outlet: nama_outlet,
      nama_customer: nama_customer,
      no_customer: no_customer,
      errorMessage:'',
      orders: [...data],
      noted: noted,
      isUpdate:true,
    })
  }
  render() {
    var x = ''
    var diskon = ''
    var resto = ''
    var jumlah = ''
    const formatRupiah = (money) => {
         return new Intl.NumberFormat('id-ID',
           { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
         ).format(money);
      }
    const filterData = this.state.searchString ? this.state.items.filter(row => row.id.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1) : this.state.items
                      && this.state.searchDate ? this.state.items.filter(row => moment(row.createdAt).utc().format('DD-MM-YYYY') === moment(this.state.searchDate).utc().add(1, 'd').format('DD-MM-YYYY') ) : this.state.items;

      return (
<div>
<Header />
<div className="osahan-profile">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="/#"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Laporan Penjualan</h4>
    </div>
  </div>
  <div className="container position-relative">
    <div className="py-5 osahan-profile row">
      <div className="col-md-12 mb-3">
        <div className="osahan-cart-item-profile">
          <div className="box bg-white mb-3 shadow-sm rounded">
            <div className="p-3 d-flex align-items-center">
              <div className="ml-3">
                <h6 className="text-dark mb-2 mt-0">Daftar Laporan Transaksi Resto</h6>
                <p className="mb-0 text-muted">berikut ini list daftar Transaksi Resto
                </p>
                <br></br>
                <div className="row">
                <input
                  type="text"
                  style={{width:'200px'}}
                  className="form-control col-md-4"
                  value={this.state.searchString}
                  name="searchString"
                  placeholder="Cari berdasarkan Kode Trx"
                  onChange={this.onChange}
              />&nbsp;&nbsp;
               <input
                  type="date"
                  style={{width:'200px'}}
                  className="form-control col-md-4"
                  value={this.state.searchDate}
                  name="searchDate"
                  placeholder="Cari berdasarkan Kode Trx"
                  onChange={this.onChange}
              />&nbsp;&nbsp;
              {/* <button type="button col-md-2" onClick={() => this.onXlsx()} class="btn btn-primary btn-sm">Export excel</button> */}
              {/* <Pdf targetRef={ref} options={options} filename="laporanpenjualan.pdf">
        {({ toPdf }) => <button class="btn btn-primary btn-sm col-md-" onClick={toPdf}>Export Pdf</button>}
      </Pdf> */}

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="btn btn-primary btn-sm"
        table="table-to-xls"
        filename="Laporan Penjualan"
        sheet="tablexls"
        buttonText="Export excel"
      />
              </div>
              </div>
            </div>
            

          <div className=" d-flex align-items-center p-3">
          {/* <table className="table" ref={ref}> */}
          <table className="table" >
          <thead>
            <tr>
              <th>Kode Trx</th>
              <th>Nama Resto</th>
              <th>No Customer</th>
              <th>Status Pembayaran</th>
              <th>Status Order</th>
              <th>Total Pembayaran</th>
              <th>Created Date</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((item, index) => (
              <tr key={index}>
                <td className="text-left">{item.id}</td>
                <td className="text-left">{item.outlets[0]['nama']}</td>
                <td className="text-left">{item.customers[0]['id']}</td>
                <td className="text-left">{this.getStatuspembayaran(item.status_pembayaran)}</td>
                <td className="text-left">{this.getStatusorder(item.status_order)}</td>
                <td className="text-left">{formatRupiah(item.total_pembayaran)}</td>
                <td className="text-left">{this.formatDate(new Date(item.createdAt))}</td>
                <td className="text-right">
                  <button
                    className="btn btn-info mr-1"
                    data-toggle="modal" data-target="#paycard"
                    disabled={this.state.editDisabled}
                    onClick={this.onEdit.bind(this, item.id, item.status_pembayaran, item.status_order, item.pengambilan, item.total_product, item.total_pembayaran, item.total_diskon, item.kode_promo, item.outlets[0]['nama'], item.customers[0]['nama'], item.customers[0]['id'], item.orders, item.noted)}
                  >
                    Detail
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>


<div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Detail Order</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
      <form>
        <div className="form-group">
            <div className="row">
              <div className="col-md-6">
               <label htmlFor="task">Kode Transaksi</label>
                <input
                  type="text"
                  className="form-control"
                  readOnly="readonly"
                  value={this.state.id}
                  name="kode_trx"
                  onChange={this.onChange}
                />
                <input
                  type="hidden"
                  className="form-control"
                  value={this.state.status_order}
                  name="status_order"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Metode Pengambilan </label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.getStatuspengambilan(this.state.pengambilan)}
                  name="pengambilan"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="task">Nama Customer</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.nama_customer}
                  name="nm_customer"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Nomor Telp Customer</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.no_customer}
                  name="nomor"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <table className="table">
          <thead>
            <tr>
              <th>Nama Produk</th>
              <th>Harga</th>
              <th>QTY</th>
              <th>total</th>
            </tr>
          </thead>
          <tbody>
              {this.state.orders.map((order, index) => (
                <tr key={index}>
                  <td className="text-left">{order.nm_product}</td>
                  <td className="text-left">{formatRupiah(order.harga)}</td>
                  <td className="text-left">{order.qty}</td>
                  <td className="text-left">{formatRupiah(order.total)}</td>
                </tr>
            ))}
          </tbody>
          </table>
          <div className="form-group">
            <div className="row">
            <div className="col-md-12">
              <label htmlFor="task">Noted</label>
              <input
                type="text"
                className="form-control"
                 readOnly="readonly"
                value={this.state.noted}
                name="noted"
                onChange={this.onChange}
              />
            </div>
              <div className="col-md-6">
                <label htmlFor="task">Kode Promo</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.kode_promo}
                  name="kode_promo"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Total Diskon</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.total_diskon}
                  name="total_diskon"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="task">Total Produk</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.total_product}
                  name="total_product"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Total Pembayaran</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={formatRupiah(this.state.total_pembayaran)}
                  name="total_pembayaran"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
            

        </form>
      </div>


      <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
          <table id="table-to-xls">
            <center><strong>LAPORAN PENJUALAN TOKYTOKY</strong></center>
            <th></th>
        <thead>
          <tr>
            <th>NO.TRANS</th>
            <th>TGL.TRANS</th>
            <th>NAMA PRODUK</th>
            <th>QTY</th>
            <th>HARGA</th>
            <th>DISKON</th>
            <th>RESTO</th>
            <th>JUMLAH</th>
          </tr>
        </thead>
        <tbody>
          
                                                                    
          {this.state.export
          .map(function (item) {            
              return <>
            <tr>
              
              {(() => { 
              if(x !== item.id_trx){
                x = item.id_trx
                  if(this.filtertrans(item.id_trx) > 1){
                    return (
                    <>
                  <td rowSpan={this.filtertrans(item.id_trx)}>{item.id_trx}</td>
                    </> )
                  }else{
                    return (
                    <td >{item.id_trx}</td>
                    )
                  }
               
            }
            })()}
            <td>{item.createdAt} </td>
            <td>{item.nm_product} </td>
            <td>{item.qty} </td>
            <td>{item.total} </td>
            {(() => { 
              if(diskon !== item.total_diskon+item.id_trx){
                diskon = item.total_diskon+item.id_trx
                  if(this.filterdiskon(item.total_diskon, item.id_trx) > 1){
                    return (
                    <>
                  <td rowSpan={this.filterdiskon(item.total_diskon, item.id_trx)}>{item.total_diskon}</td>
                    </> )
                  }else{
                    return (
                    <td >{item.total_diskon}</td>
                    )
                  }
            }
            })()}
            {(() => { 
              if(resto !== item.nama+item.id_trx){
                resto = item.nama+item.id_trx
                  if(this.filterresto(item.nama, item.id_trx) > 1){
                    return (
                    <>
                  <td rowSpan={this.filterresto(item.nama, item.id_trx)}>{item.nama}</td>
                    </> )
                  }else{
                    return (
                    <td >{item.nama}</td>
                    )
                  }
            }
            })()}
            {(() => { 
              if(jumlah !== item.total_pembayaran+item.id_trx){
                jumlah = item.total_pembayaran+item.id_trx
                  if(this.filterjumlah(item.total_pembayaran, item.id_trx) > 1){
                    return (
                    <>
                  <td rowSpan={this.filterjumlah(item.total_pembayaran, item.id_trx)}>{item.total_pembayaran}</td>
                    </> )
                  }else{
                    return (
                    <td >{item.total_pembayaran}</td>
                    )
                  }
            }
            })()}
            </tr>
            </>
          }.bind(this))}
          
        </tbody>
      </table>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>


   <Mobile />
   <Footer />
  <Menu />
 </div>
    );
  }
}

  export default Laporan;
