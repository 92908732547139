import axios from 'axios'

export const getList = token => {
    return axios
        .get('https://api.tokytoky.id/api/outlets', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const addToList = outlet => {
  return axios
    .post(
      'https://api.tokytoky.id/api/outlet',
      {
        nama: outlet.nama,
        alamat: outlet.alamat
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':outlet.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}

export const deleteItem = (outlet, token) => {
  return axios
    .delete(`https://api.tokytoky.id/api/outlet/${outlet}`, {
      headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
    .then(function(response) {
        console.log(response)
        return response;
    })
    .catch(function(error) {
      console.log(error)
      return error;
    })
}

export const updateItem = outletUpdateRequest => {
  return axios
    .put(
      `https://api.tokytoky.id/api/outlet/${outletUpdateRequest.id}`,
      {
        nama: outletUpdateRequest.nama,
        alamat: outletUpdateRequest.alamat
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': outletUpdateRequest.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}
