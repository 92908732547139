import axios from 'axios'


export const getList = (task, token) => {

  return axios
    .get(`https://api.tokytoky.id/api/order/status/${task}`, {
      headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
   .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const updateItem = userUpdateRequest => {
  return axios
    .put(
      `https://api.tokytoky.id/api/order/update/${userUpdateRequest.id}`,
      {
        status: userUpdateRequest.status,
        nomor: userUpdateRequest.nomor
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userUpdateRequest.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}
