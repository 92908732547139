import React from 'react';
import Header from '../part/header';
import Footer from '../part/footer';
import Menu from '../part/sidebar';
import Mobile from '../part/mobile';
import { getPaket, getFilter, getProduct } from './HomeListFunctions'
import ReactImageAppear from 'react-image-appear';
import CounterInput from 'react-bootstrap-personalized-counter';

class Home extends React.Component
{
  constructor() {
    super()
    this.state = {
      nm_produk: '',
      desc: '',
      id: '',
      harga: '',
      nm_label: '',
      isUpdate : false,
      items: [],
      banners: [],
      products: []
    }

  }
componentDidMount() {
  this.getAllPaket()
  const query = new URLSearchParams(this.props.location.search);
    var id_menu
    var label
    if (query.get('id') !== null && query.get('menu') !== null)
    {
        id_menu = query.get('id')
        label = query.get('menu')
        this.getAllFilter(id_menu,label )
    } else {
          id_menu = 1
          label = 'Menu terbaik'
          this.getAllProduct(id_menu,label )

    }
  }
  getAllPaket  = async () => {
    getPaket().then(data => {
            this.setState(
                {
                    items: [...data]
                },
                () => {
                    // console.log(this.state.items)
                }
            )

    })
  }
  onCartPlus(id, values) {

      let data = JSON.parse(localStorage.getItem('recent'));
      let perbaharuitotal = [];
      data = data.map((value) => {
           // check if this is the value to be edited
           if (value.productId === id ) {
             perbaharuitotal.push(value.harga*values);
                // return the updated value
                return {
                     ...value,

                     qty: values
                }
           } else {
              perbaharuitotal.push(value.harga*value.qty);

           }
           // otherwise return the original value without editing
           return value;
      })
      let totals = perbaharuitotal.reduce((val, nilaiSekarang)=>{
        return val + nilaiSekarang
      },0)
       this.setState(
                {
                    total_product: totals,
                    total_pembayaram: totals + parseInt(this.state.delivery_fee) - parseInt(this.state.apply_promo)

                }

            )

      localStorage.setItem('recent', JSON.stringify(data));
      let dat = JSON.parse(localStorage.getItem('count'));
      if (dat === 0) {
        this.setState({
        isUpdate:false
      })
    }
      this.forceUpdate();


  }

  onDelete(id, e)
{
  let recent = [];
  if(localStorage.getItem('recent')){
      recent = JSON.parse(localStorage.getItem('recent'));
  }
    var index = recent.map(x => {
      return x.productId;
    }).indexOf(id);

    recent.splice(index, 1);
    localStorage.setItem('recent', JSON.stringify(recent));
    let count = localStorage.getItem('count');
  if (count == null)
  {
    count = 0;
  }
  var add = parseInt(count)-1
  localStorage.setItem('count', add);
    this.forceUpdate();
    document.getElementById(id).style.display = 'block';
    document.getElementById(id+'add').style.display = 'none';
    document.getElementById(id+'delete').style.display = 'none';
    let dat = JSON.parse(localStorage.getItem('count'));
    if (dat === 0) {
      this.setState({
      isUpdate:false
    })
  }
}

  onCart(id, nm_produk, desc, harga, e) {
    document.getElementById(id).style.display = 'none';
    document.getElementById(id+'add').style.display = 'block';
    document.getElementById(id+'delete').style.display = 'block';
    this.setState({alert:true})
    setTimeout(() => {
          this.setState({
            alert: false
          });
        }, 5000);
    e.preventDefault()
   let recent = [];
    if(localStorage.getItem('recent')){
        recent = JSON.parse(localStorage.getItem('recent'));
    }

  const found = recent.some(el => el.productId === id);
    if (!found) {
      recent.push({ 'productId': id, 'nama': nm_produk, 'desc': desc, 'harga': harga, 'qty': 1 });
      localStorage.setItem('recent', JSON.stringify(recent));
      let count = localStorage.getItem('count');
    if (count == null)
    {
      count = 0;
    }
    var add = parseInt(count)+1
    localStorage.setItem('count', add);
      this.forceUpdate();

    }
  }


getAllFilter  = (val, label) => {
    getFilter(val).then(data => {
        if (data.length === 0) {

                 this.setState(
                {
                    nm_label: '',
                    products: [],
                    isUpdate : true,
                },

            )
        } else {
           this.setState(
                {
                    nm_label: label,
                    isUpdate : false,
                    products: [...data]
                },

            )
        }

    })
  }

  getAllProduct  = (val, label) => {
    getProduct().then(data => {
      if (data.length === 0) {

              this.setState(
            {
                nm_label: '',
                products: [],
                isUpdate : true,
            },

        )
      } else {
        this.setState(
            {
                nm_label: label,
                isUpdate : false,
                products: [...data]
            },

        )
      }

    })
  }



    render()
    {
      const formatRupiah = (money) => {
           return new Intl.NumberFormat('id-ID',
             { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
           ).format(money);
        }

        return (
            <div>
             <Header />
<div>
  <div className="osahan-home-page">
  <div className="d-none">
    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
      <a className="toggle togglew toggle-2" href="#0"><span /></a>
      <h4 className="font-weight-bold m-0 text-white">Kategori</h4>
    </div>
  </div>
    <div >
       <br></br>
      <div className="row justify-content-center " style={{width:"100%"}}>
      {this.state.items.map((item, index) => (
              <div className="col-md-1 py-1" style={{width:"85px"}}>
               <a href= {'/filtermobile?id='+item.id+'&menu='+item.name}  className="bg-white rounded d-block p-1 text-center shadow-sm" style={{width:"80px"}}>
                  <p className="m-0 small"><img alt="#" src={item.img} className="img-fluid mb-1" style={{width:"50px"}} /> {item.name}</p>
              </a>
            </div>
          ))}

        

      </div>
    </div>

    <div className="container">
    <br></br>
    <div style={this.state.alert ? { position: 'sticky',top: '0',left: '0',width: '100%',zIndex: '9'} : { display: 'none' }}   className="alert alert-success" id="success-alert">
    <button type="button" className="close" data-dismiss="alert" style={{fontSize:'12px'}}>x</button>
    <strong>Success! </strong> Produk berhasil di tambahkan.
    </div>

      <div className="pt-2 pb-3 title d-flex align-items-center">
        <h5 className="m-0">{this.state.nm_label}</h5>
      </div>
      <div className="most_sale">
        <div className="row mb-3">
          {this.state.products.map((product, index) => (
          <div className="col-md-4 mb-3">
            <div className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
              <div className="list-card-image">
                <a href="#0" onClick={this.onCart.bind(this, product.id, product.nm_produk, product.desc, product.harga)}>
                  <ReactImageAppear
                  src={product.foto}
                   className="img-fluid item-img w-100"
                   showLoader={false}
                   placeholder/>
                </a>
              </div>
              <div className="p-3 position-relative">
                <div className="list-card-body">
                  <h6 className="mb-1"><a href="#0" onClick={this.onCart.bind(this, product.id, product.nm_produk, product.desc, product.harga)} className="text-black">{product.nm_produk}
                    </a>
                  </h6>
                  <p className="text-gray mb-2">{product.desc}</p>
                </div>
                <div className="list-card-badge mb-2">
                    <h6>{formatRupiah(product.harga)}</h6>
                </div>

                <div className="list-card-badge row" style={{width:'200px'}}>
                    <div className="badge badge-success" id={product.id} style={{display: "block"}}><span className="badge badge-success"><a href="#0" onClick={this.onCart.bind(this, product.id, product.nm_produk, product.desc, product.harga)}><i className="feather-shopping-cart" /> Tambah Pesanan</a></span></div>
                    <div id={product.id + 'add'} className="col-md-6" style={{display: "none", width: "50%"}}>
                    <CounterInput className="count-number-input" readonly="readonly" value={1} min={1} max={50} glyphPlus={{ glyph: 'fa fa-plus', position: 'left'}} glyphMinus={{ glyph: 'fa fa-minus', position: 'right' }}  onChange={(value) => { this.onCartPlus(product.id,  value) }}  />
                    </div>
                    <div className="col-md-2"  id={product.id + 'delete'}  style={{display: "none", width: "10%"}}>
                    <i className="feather-trash-2 h5 mb-0" onClick={this.onDelete.bind(this, product.id)} />
                    </div>
                </div>
              </div>
            </div>
          </div>
          ))}
<div className="container osahan-coming-soon py-5 d-flex justify-content-center align-items-center"   style={this.state.isUpdate ? {} : { display: 'none' }} >
 <div className="osahan-text text-center"  style={this.state.isUpdate ? {} : { display: 'none' }}>
  <div className="osahan-img px-5 pb-5"  style={this.state.isUpdate ? {} : { display: 'none' }}>
    <svg  style={this.state.isUpdate ? {height: "200px"} : { display: 'none' }} xmlns="http://www.w3.org/2000/svg" id="bbc88faa-5a3b-49cf-bdbb-6c9ab11be594" data-name="Layer 1" width="w-100" viewBox="0 0 728 754.88525" className="injected-svg modal__media modal__mobile__media" data-src="https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/cooking_lyxy.svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
      <rect x="514.67011" y="302.6311" width={33} height={524} transform="translate(-458.65432 311.24592) rotate(-33.25976)" fill="#e6e6e6" />
      <path d="M335.58256,171.60615l63.84438,97.34271a8.5,8.5,0,0,1-14.21528,9.32341L311.81484,166.36508a60.62682,60.62682,0,0,0-29.14936,4.78729L362.63446,293.08a8.5,8.5,0,0,1-14.21528,9.3234l-79.969-121.92766A60.62685,60.62685,0,0,0,252.44516,205.304L325.842,317.2112a8.5,8.5,0,0,1-14.21528,9.3234l-63.84438-97.3427c-1.6398,27.14157,7.20944,59.3114,26.60329,88.881,36.04421,54.95614,94.83957,80.109,131.32307,56.18052s36.8396-87.87721.79539-142.83336C387.11022,201.85046,361.13005,180.91634,335.58256,171.60615Z" transform="translate(-236 -72.55738)" fill="#e6e6e6" />
      <rect x={256} y={204} width={33} height={524} fill="#e6e6e6" />
      <ellipse cx={272} cy={119} rx={79} ry={119} fill="#e6e6e6" />
      <ellipse cx={272} cy={119} rx={65} ry="97.91139" fill="#ccc" />
      <ellipse cx={364} cy={734} rx={364} ry="20.88525" fill="#e6e6e6" />
      <path d="M815.26782,806.25045a1162.796,1162.796,0,0,0-412.53564,0A15.04906,15.04906,0,0,1,385,791.45826V604.55738H833V791.45826A15.04906,15.04906,0,0,1,815.26782,806.25045Z" transform="translate(-236 -72.55738)" fill="#e23744" />
      <path d="M792,466.55738a92.85808,92.85808,0,0,0-30.39526,5.0863,179.055,179.055,0,0,0-324.4441-1.63928,93.00486,93.00486,0,1,0,12.16987,174.74988,179.02647,179.02647,0,0,0,300.7481-2.16382A93.00664,93.00664,0,1,0,792,466.55738Z" transform="translate(-236 -72.55738)" fill="#e23744" />
      <path d="M421,546.55738h-2A178.40222,178.40222,0,0,1,436.24707,469.572l1.80762.85644A176.41047,176.41047,0,0,0,421,546.55738Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M779,546.55738h-2a176.52632,176.52632,0,0,0-16.29395-74.501l1.81641-.83789A178.51046,178.51046,0,0,1,779,546.55738Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M385.24121,691.52808l-.48242-1.94141c.56445-.13964,57.40332-14.09961,140.70019-21.02636,76.88086-6.39258,192.68653-7.93457,307.78516,21.02734l-.48828,1.93945C717.93945,662.63746,602.38672,664.17261,525.667,670.55054,442.519,677.46167,385.8042,691.38843,385.24121,691.52808Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M385.24121,718.52808l-.48242-1.94141c.56445-.13964,57.40332-14.09961,140.70019-21.02636,76.88086-6.39258,192.68653-7.93457,307.78516,21.02734l-.48828,1.93945C717.93945,689.63746,602.38672,691.17456,525.667,697.55054,442.519,704.46167,385.8042,718.38843,385.24121,718.52808Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M385.24121,745.52808l-.48242-1.94141c.56445-.13964,57.40332-14.09961,140.70019-21.02636,76.88086-6.39258,192.68653-7.93457,307.78516,21.02734l-.48828,1.93945C717.93945,716.63746,602.38672,718.17456,525.667,724.55054,442.519,731.46167,385.8042,745.38843,385.24121,745.52808Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M753.26693,598.71334,729.03658,590.475l23.26113-118.72871-15.992-1.45382c-15.594,11.96435-36.35984,16.65481-62.99891,13.08438l42.64542,64.45274-21.745,15.34942-69.368-83.20523A20.866,20.866,0,0,1,620,466.61227v0a20.866,20.866,0,0,1,15.0905-20.05076L709.16769,425.224l86.74466,9.69214c13.11467,19.99417,13.62744,33.89954-6.33645,37.911Z" transform="translate(-236 -72.55738)" fill="#2f2e41" />
      <path d="M728.46691,644.90106h0a15.95869,15.95869,0,0,1-13.86555-21.711l12.046-30.97551c6.11869-11.59112,14.5164-10.14011,24.43261,0l4.84611,14.21526a9.17534,9.17534,0,0,1-.53485,7.176L743.64973,636.306A15.95871,15.95871,0,0,1,728.46691,644.90106Z" transform="translate(-236 -72.55738)" fill="#2f2e41" />
      <path d="M697.15218,604.33834h0a15.95869,15.95869,0,0,1-13.86555-21.711l12.046-30.97551c6.11869-11.59113,14.51641-10.14012,24.43261,0l4.84611,14.21525a9.17537,9.17537,0,0,1-.53485,7.176L712.335,595.74331A15.9587,15.9587,0,0,1,697.15218,604.33834Z" transform="translate(-236 -72.55738)" fill="#2f2e41" />
      <circle cx="476.55994" cy="212.13062" r="27.13799" fill="#ffb9b9" />
      <polygon points="518.721 250.415 481.406 269.799 473.652 234.907 499.336 218.915 518.721 250.415" fill="#ffb9b9" />
      <path d="M799.7892,439.76224c-37.23393-11.24605-71.01788-17.07317-95.46758-8.23832,8.42738-23.70818-7.12737-59.91146-24.23035-96.9214,7.37949-9.64677,19.14576-13.38347,32.46867-15.02282,14.5769,10.5844,24.74122,3.79091,32.46867-12.59978,16.85358-.67652,33.095,5.29186,48.94531,15.50743C781.58355,362.17339,783.814,401.25293,799.7892,439.76224Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M703.837,437.33921c-5.87952,3.4656-11.3058,9.30325-16.47664,16.47664-8.73817-5.349-16.42816-11.439-22.48592-18.68294a40.01122,40.01122,0,0,1-7.33032-37.42892l16.56053-53.82173a23.60967,23.60967,0,0,1,7.67755-11.38054l2.18592-1.776,21.80731,41.19159-21.80731,40.707C686.73356,420.03892,694.88267,428.6031,703.837,437.33921Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M711.343,478.37478h0a14.00455,14.00455,0,0,1-19.66674-10.71872L688.072,442.98155l12.59979-6.7845,15.9909,20.93355A14.00455,14.00455,0,0,1,711.343,478.37478Z" transform="translate(-236 -72.55738)" fill="#ffb9b9" />
      <path d="M739.94024,283.50047l-4.63369.13763-12.853-18.20724c-16.46951,1.70257-29.96494,8.858-41.38524,19.81828l-1.15795-7.71966a29.10153,29.10153,0,0,1,22.90286-32.81892h.00006a29.10153,29.10153,0,0,1,34.57213,23.6573Z" transform="translate(-236 -72.55738)" fill="#2f2e41" />
      <path d="M687.82806,453.82563v0a14.00456,14.00456,0,0,1,10.71872-19.66675l24.67452-3.60414,6.7845,12.59978L709.07224,459.1454A14.00455,14.00455,0,0,1,687.82806,453.82563Z" transform="translate(-236 -72.55738)" fill="#ffb9b9" />
      <path d="M804.49034,431.38118c-23.4754,1.82279-49.10633,9.14326-75.93837,19.527a37.12074,37.12074,0,0,0-8.23832-21.80731c24.37008-6.41874,46.48406-13.95144,60.09127-25.68417L772.1666,341.387l17.93046-20.35349,3.09274,1.6136a20.65228,20.65228,0,0,1,10.4691,13.14326c7.57071,29.449,10.93351,57.66486,8.62195,84.21782A10.47079,10.47079,0,0,1,804.49034,431.38118Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <path d="M331.88594,800.6692q-32.74851,20.483-65.49722-.01716a4.441,4.441,0,0,1-2.10125-4.0963l6.81241-88.56136h55.10049l7.78288,88.5302A4.44,4.44,0,0,1,331.88594,800.6692Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <ellipse cx="62.39599" cy="636.43883" rx="27.80438" ry="10.01827" fill="#3f3d56" />
      <path d="M320.18941,705.61437q-21.73251,15.28772-42.07674,0V689.58514h42.07674Z" transform="translate(-236 -72.55738)" fill="#e23744" />
      <ellipse cx="63.15104" cy="617.02776" rx="21.03837" ry="8.01462" fill="#e23744" />
      <ellipse cx="64.15287" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="73.61397" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="68.88342" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="49.96121" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="54.69176" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="59.42232" cy="619.57385" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <path d="M936.88594,800.6692q-32.74851,20.483-65.49722-.01716a4.441,4.441,0,0,1-2.10125-4.0963l6.81241-88.56136h55.10049l7.78288,88.5302A4.44,4.44,0,0,1,936.88594,800.6692Z" transform="translate(-236 -72.55738)" fill="#3f3d56" />
      <ellipse cx="667.39599" cy="636.43883" rx="27.80438" ry="10.01827" fill="#3f3d56" />
      <path d="M925.18941,705.61437q-21.73251,15.28772-42.07674,0V689.58514h42.07674Z" transform="translate(-236 -72.55738)" fill="#e23744" />
      <ellipse cx="668.15104" cy="617.02776" rx="21.03837" ry="8.01462" fill="#e23744" />
      <ellipse cx="669.15287" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="678.61397" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="673.88342" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="654.96121" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="659.69176" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
      <ellipse cx="664.42232" cy="619.57385" rx="2.00365" ry="1.00183" fill="#e6e6e6" />
    </svg>
  </div>
  <h2 className="text-primary mb-3 font-weight-light">Mohon Maaf Paket <b>Belum Tersedia</b></h2>

 </div>
</div>
        </div>
      </div>
    </div>
  </div>
   <Mobile />
   <Footer />
  <Menu />
   </div>
            </div>
        );
    }
}
export default Home;
