import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'

import Home from './components/pages/home.jsx';
import Cart from './components/pages/cart';
import Order from './components/pages/admin/order';
import Login from './components/pages/admin/login';
import Paket from './components/pages/admin/paket';
import Banner from './components/pages/admin/Banner';
import Product from './components/pages/admin/product';
import Outlet from './components/pages/admin/outlet';
import User from './components/pages/admin/user';
import Customer from './components/pages/admin/customer';
import Laporan from './components/pages/admin/laporanpenjualan';
import Filter from './components/pages/filter';
import Filtermobile from './components/pages/filtermobile';
import Privacy from './components/pages/privacy-policy';
import Sidebar from './components/part/sidebar';
import Info from './components/pages/info';
import Delivery from './components/pages/delivery';
import Terms from './components/pages/terms';
import Payment from './components/pages/payment';


function App() {
  return (
    <Router>
      <div className="App">
          <Route exact path="/" component={Home} />

            <Route exact path="/home" component={Home} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/order" component={Order} />
            <Route exact path="/paket" component={Paket} />
            <Route exact path="/banner" component={Banner} />
            <Route exact path="/product" component={Product} />
            <Route exact path="/outlet" component={Outlet} />
            <Route exact path="/user" component={User} />
            <Route exact path="/customer" component={Customer} />
            <Route exact path="/laporan" component={Laporan} />
            <Route exact path="/filter" component={Filter} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/sidebar" component={Sidebar} />
            <Route exact path="/info" component={Info} />
            <Route exact path="/delivery" component={Delivery} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/filtermobile" component={Filtermobile} />
            <Route exact path="/pembayaran" component={Payment} />

      </div>
    </Router>
  );
}

export default App;
