import axios from 'axios'


export const getList = token => {
    return axios
        .get('https://api.tokytoky.id/users/list', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const addToList = user => {
  return axios
    .post(
      'https://api.tokytoky.id/users/register',
      {
        name: user.name,
        email: user.email,
        password: user.password
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':user.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}


export const updateItem = userUpdateRequest => {
  return axios
    .put(
      `https://api.tokytoky.id/users/update/${userUpdateRequest.id}`,
      {
        name: userUpdateRequest.name,
        email: userUpdateRequest.email,
        password: userUpdateRequest.password
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userUpdateRequest.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}


export const register = newUser => {
  return axios
    .post('https://api.tokytoky.id/users/register', {
      name: newUser.name,
      email: newUser.email,
      password: newUser.password
    })
    .then(response => {
      console.log('Registered')
    })
}

export const login = user => {
  return axios
    .post('https://api.tokytoky.id/users/login', {
      email: user.email,
      password: user.password
    })
    .then(response => {
      localStorage.setItem('usertoken', response.data.token)
      localStorage.setItem('tipe', response.data.detail.tipe)
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
}

export const getProfile = token => {
  return axios
    .get('https://api.tokytoky.id/users/profile', {
      headers: { Authorization: token }
    })
    .then(response => {
      response.data.status = 'success';
      return response.data
    })
    .catch(err => {
      console.dir(err)
      return {'error':err.message,'status':'failed'};
    })
}



export const updatePassword = updatePasswordRequest => {
  return axios
    .put(
      `https://api.tokytoky.id/users/password/`,
      {
        email:updatePasswordRequest.email,
        password: updatePasswordRequest.password,
        new_password: updatePasswordRequest.new_password
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': updatePasswordRequest.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err=>{
      console.dir("err",err);
      return err.message;
    })
}
