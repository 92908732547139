import axios from 'axios'

export const getList = token => {
    return axios
        .get('https://api.tokytoky.id/api/pakets', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':token
        }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

export const addToList = task => {
  const data = new FormData()
        data.append('img', task.img)
        data.append('name', task.name)
        data.append('status', task.status)
  return axios
    .post(
      'https://api.tokytoky.id/api/paket',
      data,
      {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':task.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}

export const deleteItem = (task, token) => {
  return axios
    .delete(`https://api.tokytoky.id/api/paket/${task}`, {
      headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
    .then(function(response) {
        console.log(response)
        return response;
    })
    .catch(function(error) {
      console.log(error)
      return error;
    })
}

export const updateItem = taskUpdateRequest => {
   const data = new FormData()
        data.append('img', taskUpdateRequest.img)
        data.append('name', taskUpdateRequest.name)
        data.append('status', taskUpdateRequest.status)
  return axios
    .put(
      `https://api.tokytoky.id/api/paket/${taskUpdateRequest.id}`,
      data,
      {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': taskUpdateRequest.token
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}
