import React, { Component } from 'react'
import Header from '../../part/header';
import Footer from '../../part/footer';
import Menu from '../../part/sidebar';
import Mobile from '../../part/mobile';
import { getList, updateItem} from './OrderListFunctions'

class Order extends Component {

   constructor() {
    super()

    this.state = {
      id: '',
      nomor: 0,
      kode_trx: 0,
      total_pembayaran: 0,
      total_product: 0,
      total_diskon: 0,
      nm_customer: '',
      kode_promo: '',
      status_order: '',
      pengambilan: '',
      createdAt: '',
      status_req: 1,
      noted: '',
      isUpdate: false,
      isProses: false,
      items: [],
      orders: [],
     }
         this.onChange = this.onChange.bind(this)

     }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  interval
  componentDidMount() {
     this.interval = setInterval(() => {
     this.getAll(this.state.status_req)
   }, 500);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear();
  }

  getStatus(statusCode) {
    const status = ['Pickup (Ambil Sendiri)','Delivery (Diantar)'];
    return status[statusCode];
  }
  setAll = (val) => {

 this.setState(
    {
          status_req: val,
          items: []
   },
    )
    this.getAll(val)
  }
  getAll = (val) => {
    console.log(val);
    const token = localStorage.usertoken;


    getList(val, token).then(data => {
      if(data.message === 'Token expired !'){
            localStorage.removeItem('usertoken')
            this.props.history.push(`/login`)
        }else{
            this.setState(
              {
                    task: '',
                    items: [...data]
                },
                () => {
                    console.log(this.state.items)
                }
            )
        }
    })
  }

   onEdit = (item_id, kode_trx, nomor, nm_customer, total_pembayaran, total_product, total_diskon, kode_promo, status_order, pengambilan, tolak, proses, data, noted, e) => {
    e.preventDefault()
    this.setState({
      id: item_id,
      kode_trx: kode_trx,
      nomor: nomor,
      nm_customer: nm_customer,
      total_pembayaran: total_pembayaran,
      total_product: total_product,
      total_diskon: total_diskon,
      kode_promo: kode_promo,
      status_order: status_order,
      pengambilan: pengambilan,
      orders: [...data],
      isUpdate: tolak,
      isProses: proses,
      noted: noted

    })
  }

    onUpdate = e => {
    e.preventDefault()

        const token = localStorage.usertoken;
        const taskUpdateRequest = {
            id: this.state.id,
            token: token,
            nomor: this.state.nomor,
            status: this.state.status_order,

        }
      updateItem(taskUpdateRequest).then(() => {
        var status_order = this.state.status_order;
        var new_status = parseInt(status_order) - 1;
        this.getAll(new_status)
        }).catch(err=>{
            this.setState({ editDisabled: false, isUpdate:false, errorMessage:err.message })
        })


  }

    render()
    {
      const formatRupiah = (money) => {
           return new Intl.NumberFormat('id-ID',
             { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
           ).format(money);
        }
        return (
            <div>
<Header />
  <section className="py-4 osahan-main-body">
    <div className="container">
      <div className="row">
        <div className="col-md-3 mb-3">
          <ul className="nav nav-tabsa custom-tabsa border-0 flex-column bg-white rounded overflow-hidden shadow-sm p-2 c-t-order" id="myTab" role="tablist">
             <li className="nav-item border-top" role="presentation">
              <a onClick={this.setAll.bind(this, 1)} className="nav-link border-0 text-dark py-3 active" id="new-tab" data-toggle="tab" href="#new" role="tab" aria-controls="new" aria-selected="false">
                <i className="feather-shopping-cart mr-2 text-info mb-0" />New Order</a>
            </li>
            <li className="nav-item border-top" role="presentation">
              <a onClick={this.setAll.bind(this, 2)} className="nav-link border-0 text-dark py-3" id="progress-tab" data-toggle="tab" href="#progress" role="tab" aria-controls="progress" aria-selected="false">
                <i className="feather-clock mr-2 text-warning mb-0" /> On Progress</a>
            </li>
            <li className="nav-item" role="presentation">
              <a onClick={this.setAll.bind(this, 3)} className="nav-link border-0 text-dark py-3" id="completed-tab" data-toggle="tab" href="#completed" role="tab" aria-controls="completed" aria-selected="true">
                <i className="feather-check mr-2 text-success mb-0" /> Completed</a>
            </li>
            <li className="nav-item border-top" role="presentation">
              <a onClick={this.setAll.bind(this, 4)} className="nav-link border-0 text-dark py-3" id="canceled-tab" data-toggle="tab" href="#canceled" role="tab" aria-controls="canceled" aria-selected="false">
                <i className="feather-x-circle mr-2 text-danger mb-0" /> Canceled</a>
            </li>
          </ul>
        </div>
        <div className="tab-content col-md-9" id="myTabContent">
          <div className="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
            <div className="order-body">
          {this.state.items.map((item, index) => (

              <div className="pb-3">
                <div className="p-3 rounded shadow-sm bg-white">
                  <div className="d-flex border-bottom pb-3">
                    <div className="text-muted mr-3">
                      <img alt="/#" src="assets/img/popular5.png" className="img-fluid order_img rounded" />
                    </div>
                    <div>
                      <p className="mb-0 font-weight-bold"><a href="/#" className="text-dark">{item.customers[0]['nama']}</a></p>
                      <p className="mb-0">Telphone {item.customers[0]['id']}</p>
                    <p>ORDER #{item.id}</p>
                      <p className="mb-0 small"><a href="/#"  data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, item.id, item.kode_trx, item.customers[0]['id'], item.customers[0]['nama'], item.total_pembayaran, item.total_product, item.total_diskon, item.kode_promo, 2, item.pengambilan, false, false, item.orders, item.noted )}>View Details</a></p>
                    </div>
                    <div className="ml-auto">
                      <p className="bg-success text-white py-1 px-2 rounded small mb-1">{this.getStatus(item.pengambilan)}</p>
                      <p className="small font-weight-bold text-center"><i className="feather-clock" /> {this.formatDate(new Date(item.createdAt))}</p>
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className="small">
                      <p className="text- font-weight-bold mb-0">Total Pembelian {item.total_product}</p>
                    </div>
                    <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                      <span className="text-dark font-weight-bold"> {formatRupiah(item.total_pembayaran)}</span>
                  </div>
                    <div className="text-right">
                      {/* <a href="/#"   data-toggle="modal" data-target="#paycard" className="btn btn-primary px-3" onClick={this.onEdit.bind(this, item.id, item.kode_trx, item.customers[0]['nomor'], item.customers[0]['nama'], item.total_pembayaran, item.total_product, item.total_diskon, item.kode_promo, 4, item.pengambilan, true, false, item.orders )}>Tolak</a> */}
                      <a href="/#"   data-toggle="modal" data-target="#paycard" className="btn btn-info px-3"  onClick={this.onEdit.bind(this, item.id, item.kode_trx, item.customers[0]['id'], item.customers[0]['nama'], item.total_pembayaran, item.total_product, item.total_diskon, item.kode_promo, 2, item.pengambilan, false, true, item.orders, item.noted )}>Terima</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>
          <div className="tab-pane fade" id="progress" role="tabpanel" aria-labelledby="progress-tab">
            <div className="order-body">
              {this.state.items.map((item, index) => (

              <div className="pb-3">
                <div className="p-3 rounded shadow-sm bg-white">
                  <div className="d-flex border-bottom pb-3">
                    <div className="text-muted mr-3">
                      <img alt="/#" src="assets/img/popular5.png" className="img-fluid order_img rounded" />
                    </div>
                    <div>
                      <p className="mb-0 font-weight-bold"><a href="/#" className="text-dark">{item.customers[0]['nama']}</a></p>
                      <p className="mb-0">Telphone {item.customers[0]['id']}</p>
                    <p>ORDER #{item.id}</p>
                      <p className="mb-0 small"><a href="/#"  data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, item.id, item.kode_trx, item.customers[0]['id'], item.customers[0]['nama'], item.total_pembayaran, item.total_product, item.total_diskon, item.kode_promo, 2, item.pengambilan, false, false, item.orders, item.noted )}>View Details</a></p>
                    </div>
                    <div className="ml-auto">
                      <p className="bg-success text-white py-1 px-2 rounded small mb-1">{this.getStatus(item.pengambilan)}</p>
                      <p className="small font-weight-bold text-center"><i className="feather-clock" /> {this.formatDate(new Date(item.createdAt))}</p>
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className="small">
                      <p className="text- font-weight-bold mb-0">Total Pembelian {item.total_product}</p>
                    </div>
                    <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                      <span className="text-dark font-weight-bold">{formatRupiah(item.total_pembayaran)}</span>
                    </div>
                    <div className="text-right">
                      <a href="/#"  data-toggle="modal" data-target="#paycard" className="btn btn-info px-3" onClick={this.onEdit.bind(this, item.id, item.kode_trx, item.customers[0]['id'], item.customers[0]['nama'], item.total_pembayaran, item.total_product, item.total_diskon, item.kode_promo, 3, item.pengambilan, false, true, item.orders, item.noted )}>Pesanan Sudah Siap</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>

          <div className="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="completed-tab">
            <div className="order-body">
              {this.state.items.map((item, index) => (

              <div className="pb-3">
                <div className="p-3 rounded shadow-sm bg-white">
                  <div className="d-flex border-bottom pb-3">
                    <div className="text-muted mr-3">
                      <img alt="/#" src="assets/img/popular5.png" className="img-fluid order_img rounded" />
                    </div>
                    <div>
                      <p className="mb-0 font-weight-bold"><a href="/#" className="text-dark">{item.customers[0]['nama']}</a></p>
                      <p className="mb-0">Telphone {item.customers[0]['id']}</p>
                    <p>ORDER #{item.id}</p>
                      <p className="mb-0 small"><a href="/#"  data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, item.id, item.kode_trx, item.customers[0]['id'], item.customers[0]['nama'], item.total_pembayaran, item.total_product, item.total_diskon, item.kode_promo, 2, item.pengambilan, false, false, item.orders, item.noted )}>View Details</a></p>
                    </div>
                    <div className="ml-auto">
                      <p className="bg-success text-white py-1 px-2 rounded small mb-1">{this.getStatus(item.pengambilan)}</p>
                      <p className="small font-weight-bold text-center"><i className="feather-clock" /> {this.formatDate(new Date(item.createdAt))}</p>
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className="small">
                      <p className="text- font-weight-bold mb-0">Total Pembelian {item.total_product}</p>
                    </div>
                    <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                      <span className="text-dark font-weight-bold">{formatRupiah(item.total_pembayaran)}</span>
                    </div>

                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>

          <div className="tab-pane fade" id="canceled" role="tabpanel" aria-labelledby="canceled-tab">
            <div className="order-body">
              {this.state.items.map((item, index) => (

              <div className="pb-3">
                <div className="p-3 rounded shadow-sm bg-white">
                  <div className="d-flex border-bottom pb-3">
                    <div className="text-muted mr-3">
                      <img alt="/#" src="assets/img/popular5.png" className="img-fluid order_img rounded" />
                    </div>
                    <div>
                      <p className="mb-0 font-weight-bold"><a href="/#" className="text-dark">{item.customers[0]['nama']}</a></p>
                      <p className="mb-0">Telphone {item.customers[0]['id']}</p>
                    <p>ORDER #{item.id}</p>
                      <p className="mb-0 small"><a href="/#"  data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, item.id, item.kode_trx, item.customers[0]['id'], item.customers[0]['nama'], item.total_pembayaran, item.total_product, item.total_diskon, item.kode_promo, 2, item.pengambilan, false, false, item.orders, item.noted )}>View Details</a></p>
                    </div>
                    <div className="ml-auto">
                      <p className="bg-success text-white py-1 px-2 rounded small mb-1">{this.getStatus(item.pengambilan)}</p>
                      <p className="small font-weight-bold text-center"><i className="feather-clock" /> {this.formatDate(new Date(item.createdAt))}</p>
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className="small">
                      <p className="text- font-weight-bold mb-0">Total Pembelian {item.total_product}</p>
                    </div>
                    <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                      <span className="text-dark font-weight-bold">{formatRupiah(item.total_pembayaran)}</span>
                    </div>

                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


<div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Detail Order</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
            <div className="row">
              <div className="col-md-6">
               <label htmlFor="task">Kode Transaksi</label>
                <input
                  type="text"
                  className="form-control"
                  readOnly="readonly"
                  value={this.state.id}
                  name="kode_trx"
                  onChange={this.onChange}
                />
                <input
                  type="hidden"
                  className="form-control"
                  value={this.state.status_order}
                  name="status_order"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Metode Pengambilan </label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.getStatus(this.state.pengambilan)}
                  name="pengambilan"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="task">Nama Customer</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.nm_customer}
                  name="nm_customer"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Nomor Telp Customer</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.nomor}
                  name="nomor"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <table className="table">
          <thead>
            <tr>
              <th>Nama Produk</th>
              <th>Harga</th>
              <th>QTY</th>
              <th>total</th>
            </tr>
          </thead>
          <tbody>
              {this.state.orders.map((order, index) => (
                <tr key={index}>
                  <td className="text-left">{order.nm_product}</td>
                  <td className="text-left">{formatRupiah(order.harga)}</td>
                  <td className="text-left">{order.qty}</td>
                  <td className="text-left">{formatRupiah(order.total)}</td>
                </tr>
            ))}
          </tbody>
          </table>
          <div className="form-group">
            <div className="row">
            <div className="col-md-12">
              <label htmlFor="task">Noted</label>
              <input
                type="text"
                className="form-control"
                 readOnly="readonly"
                value={this.state.noted}
                name="noted"
                onChange={this.onChange}
              />
            </div>
              <div className="col-md-6">
                <label htmlFor="task">Kode Promo</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.kode_promo}
                  name="kode_promo"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Total Diskon</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.total_diskon}
                  name="total_diskon"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="task">Total Produk</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={this.state.total_product}
                  name="total_product"
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task">Total Pembayaran</label>
                <input
                  type="text"
                  className="form-control"
                   readOnly="readonly"
                  value={formatRupiah(this.state.total_pembayaran)}
                  name="total_pembayaran"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
             <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close" onClick={this.onUpdate.bind(this)}
         style={this.state.isUpdate ? {} : { display: 'none' }} >
                        Tolak
        </button>
        <button className="btn btn-info btn-block" data-dismiss="modal" aria-label="Close" onClick={this.onUpdate.bind(this)}
         style={this.state.isProses ? {} : { display: 'none' }} >
                        Proses
        </button>

        </form>
      </div>

    </div>
  </div>
</div>






<Mobile />
<Footer />
<Menu />
          </div>
        );
    }
}
export default Order;
